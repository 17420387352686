import styled from 'styled-components';

const ContainerWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: 100%;
  max-width: 1620px;
  margin: 0 auto;
  padding-top:  ${(p) => (p.isTablet ? '30px' : '70px')};
  padding-bottom: ${(p) => (p.isMobile ? '420px' : p.isTablet ? '300px' : '50px')};
  position: relative;
`;

const Info = styled.div<{ lang: string; isTablet: boolean; isMobile: boolean }>`
  position: absolute;
  left: 0;
  top: ${(p) => (p.isTablet ? 'unset' : '170px')};
  bottom: ${(p) => (p.isMobile ? '25px' : p.isTablet ? '-20px' : 'unset')}; 
  max-width: ${(p) => (p.isTablet ? '100%' : '755px')};
  padding: ${(p) => (p.isMobile ? '60px 20px' : '50px 80px')};
  background: linear-gradient(90deg, #0f319d 0%, #4ebbc6 100%);
  border-radius: 40px 40px 40px 0px;
  filter: drop-shadow(7px 7px 15px rgba(0, 0, 0, 0.2));

  > div:nth-child(1) {
    font-family: 'Roboto', sans-serif;
    font-size: ${(p) => p.isMobile ? p.theme.fontSizes.lg : p.theme.fontSizes.xl};
    font-weight: ${(p) => (p.isMobile ? '400' : '700')};
    line-height: 70px;
    color: ${(p) => p.theme.colors.light};
    margin-bottom: 25px;
    position: relative;

    ::after {
      content: '${(p) => (p.lang === 'sc' ? '欢迎' : p.lang === 'tc' ? '歡迎' : 'Welcome')}';
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-120%);
      color: ${(p) => p.theme.colors.light};
      background-color: ${(p) => p.theme.colors.secondary400};
      font-family: 'Roboto', sans-serif;
      font-size: ${(p) => p.isMobile ? p.theme.fontSizes.md : p.theme.fontSizes.lg};
      font-weight: 700;
      line-height: ${(p) => p.isMobile ? p.theme.fontSizes.md : p.theme.fontSizes.lg};
      border-radius: 15px 15px 15px 0px;
      padding: ${(p) => (p.isMobile ? '20px 30px' : '30px 45px')}};
    }
  }

  > div:nth-child(2) {
    font-family: 'Roboto', sans-serif;
    font-size: ${(p) => p.theme.fontSizes.md};
    font-weight: 400;
    line-height: 30px;
    color: ${(p) => p.theme.colors.light};
    margin-bottom: 50px;

    > a {
      color: ${(p) => p.theme.colors.secondary400};
      text-decoration: underline;
    }
  }

  > div:nth-child(3) {
    display: flex;
    gap: 15px;

    > a {
      width: 150px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Roboto', sans-serif;
      font-size: ${(p) => p.theme.fontSizes.md};
      font-weight: 700;
      line-height: 23px;
      color: ${(p) => p.theme.colors.light};
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      :hover {
        filter: brightness(1.1);
      }
      :nth-child(1) {
        background-color: ${(p) => p.theme.colors.secondary400};
      }
      :nth-child(2) {
        background-color: ${(p) => p.theme.colors.primary100};
      }
    }
  }
`;

const RegistInfo = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  position: absolute;
  left: 0;
  bottom: ${(p) => (p.isMobile ? '-80px' : p.isTablet ? '-160px' : '100px')};
  width: 100%;
  max-width: ${(p) => (p.isTablet ? '100%' : '560px')};
  padding: ${(p) => (p.isMobile ? '20px 30px' : '40px 50px')};
  background: linear-gradient(90deg, #0f319d 0%, #4ebbc6 100%);
  border-radius: 20px 20px 20px 0px;
  filter: drop-shadow(7px 7px 15px rgba(0, 0, 0, 0.2));
  color: ${(p) => p.theme.colors.light};
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.md};

  a {
    color: ${(p) => p.theme.colors.secondary400};
    text-decoration: underline;
  }
`;

const Image = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  margin-left: auto;
  width: 100%;
  max-width: ${(p) => (p.isTablet ? '100%' : '1200px')}};
  height: ${(p) => (p.isMobile ? '235px' : p.isTablet ? '480px' : '750px')}};
  background-image: url('/images/index/section1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px 20px 20px 0;
`;

const SigninButton = styled.div`
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  line-height: 23px;
  color: ${(p) => p.theme.colors.light};
  background: ${(p) => p.theme.colors.lightPurple};
  cursor: pointer;
`;

export { ContainerWrapper, Info, Image, SigninButton, RegistInfo };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, FormControlLabel, Modal } from '@mui/material';
import { H2 } from '@/components/UI/Typography/Heading';
import { Text } from '@/components/UI/Typography/Text';
import { useForm } from 'react-hook-form';
import Loading from '@/components/UI/Loading';
import { successHandler } from '@/utils/toastHandler';

interface VerifyModalProps {
  type: 'sentMail' | 'login';
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  failedCounter: number;
  formSubmit?: () => void;
}

const VerifyModal = ({ type, showModal, setShowModal, failedCounter, formSubmit = () => {} }: VerifyModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const { handleSubmit } = useForm<{ checkBox: boolean }>();

  const [checkBox, setCheckBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const onSubmit = handleSubmit(async (data) => {
    setCheckBox(true);
    setIsLoading(true);

    setTimeout(() => {
      setShowModal(false);
      successHandler(t('success.verification'));
      setIsLoading(false);
      setCheckBox(false);

      if (type === 'sentMail') {
        formSubmit();
      }
    }, 3000);
  });

  const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    setX(getRandomInt(20, 780));
    setY(getRandomInt(20, 200));
  }, [failedCounter]);

  return (
    <Modal
      open={showModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <div
        style={{
          position: 'absolute',
          top: '35%',
          left: 'calc(50% - 465px)',
          width: '100%',
          maxWidth: '930px',
          backgroundColor: '#fff',
          padding: '30px 50px',
          borderRadius: '40px 40px 40px 0px',
        }}
      >
        {type === 'login' && (
          <>
            <H2>{t('pages.auth.signIn.modal.title')}</H2>
            <Text marginBottom={'5px'}>
              {`${t('pages.auth.signIn.modal.content-prev')} ${failedCounter} ${t(
                'pages.auth.signIn.modal.content-last'
              )}`}
            </Text>
            <Text marginBottom={'15px'}>{t('pages.auth.signIn.modal.content-system')}</Text>
          </>
        )}
        {isLoading && <Loading />}
        {!isLoading && (
          <div style={{ width: '100%', height: '300px', position: 'relative', background: '#E9E9E9' }}>
            <div style={{ position: 'absolute', top: y, left: x }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#999999',
                      '&.Mui-checked': {
                        color: '#999999',
                      },
                    }}
                    checked={checkBox}
                    onChange={onSubmit}
                    name="terms"
                  />
                }
                label={<label>{t('form.notARobot.label')}</label>}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default VerifyModal;

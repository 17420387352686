import { Paper } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 60px;
`;

const StepWrapper = styled.div`
  > img {
    width: 150px;
  }
`;

const IdentificationCheck = ({ nextHandler = () => {}, ButtonGroup, ReturnLink = '' }: ProfileProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<{
    businessRegistrationCode: string;
    businessRegistrationCertificate: File;
    legalEntityIdentifierCode: string;
    dID: string;
    website: string;
  }>();

  const onSubmit = handleSubmit(async (data) => {
    nextHandler()
  });

  return (
    <Paper square elevation={0}>
      <div style={{ maxWidth: '930px', padding: '30px 50px' }}>
        <Wrapper>
          <img width={350} src="/images/Identification/qrcode.png" alt="" />
        </Wrapper>
        <Wrapper>
          <StepWrapper style={{ marginRight: 70 }}>
            <img src="/images/Identification/1.svg" alt="" />
            <div>1.{t('pages.auth.identificationCheck.illustrate.step1')}</div>
          </StepWrapper>
          <StepWrapper style={{ marginRight: 70 }}>
            <img src="/images/Identification/2.svg" alt="" />
            <div>2.{t('pages.auth.identificationCheck.illustrate.step2')}</div>
          </StepWrapper>
          <StepWrapper>
            <img src="/images/Identification/3.svg" alt="" />
            <div>3.{t('pages.auth.identificationCheck.illustrate.step3')}</div>
          </StepWrapper>
        </Wrapper>
        {ButtonGroup}
      </div>
    </Paper>
  );
};

export default IdentificationCheck;

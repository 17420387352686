import { useEffect, useState } from 'react';

const useResize = () => {
  // 偵測目前螢幕大小
  const [size, setSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const breakpoint = {
    lg: 1310,
    md: 640,
  };

  // 偵測螢幕大小改變, 若螢幕小於1024則為平板版, 若螢幕小於640則為手機版
  useEffect(() => {
    const handleResize = () => {
      setSize(window.innerWidth);
      setIsMobile(window.innerWidth < breakpoint.md);
      setIsTablet(window.innerWidth < breakpoint.lg);
    };
    // 初始化
    handleResize();
    // 掛載事件
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  return { size, breakpoint, isMobile, isTablet };
};

export default useResize;

import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StepContent, StepLabel, Step, Stepper, Modal } from '@mui/material';
import { getUserDetail } from '@/api/auth.api';
import { getDealDetail, getDealFileList } from '@/api/deal.api';

import { TCTermsMarkdown } from '@/components/UI/Terms';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import PageContainer from '@/components/Container/PageContainer';
import DetailTitle from '@/components/DealDetail/Title';
import ContractSign from '@/components/ManageFiles/ContractSign';
import { Flex, Box } from '@/layout';
import { Person } from '@/components/Icon';

import { theme } from '@/theme/Theme';

// import dealFakeData from '@/components/DealList/DealListItem/fakeData';
// import fileFakeData from '@/components/ManageFiles/Files/fakeData';
import { convertToData, convertToFileSize } from '@/utils/convert';
import Loading from '@/components/UI/Loading';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { useAppSelector } from '@/redux/store.hook';
import { notInCNRegion } from '@/data/china-region';
import { errorHandler } from '@/utils/toastHandler';
import { Button } from '@/components/UI/Button';
import { H2 } from '@/components/UI/Typography/Heading';
import useResize from '@/hooks/useResize';

type DataRow = {
  id: string;
  file_id: string;
  isOfficialDoc: boolean;
  name: string;
  type: string;
  size: string;
  signPDFBase64A: boolean;
  signPDFBase64B: boolean;
  updated: string;
  sign: {
    need: boolean;
    isSign: boolean;
    signStatus: number;
    needSign: number;
    firstSigner: number;
  };
  action: string;
};

type Participants = {
  name: string;
  id: number;
  sign: boolean;
  deal_role_id: number;
};

const ContractSigningPage = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, fileId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [dealRole, setDealRole] = useState('0');
  const [participants, setParticipants] = useState<Participants[]>([]);
  const [hasChina, setHasChina] = useState(false);
  const { isMobile } = useResize();

  const [TC, setTC] = useState('');
  const [showTCModal, setShowTCModal] = useState(false);
  const [termsInfo, setTermsInfo] = useState(true);
  const auth = useAppSelector(getAuthSelector);
  const [eSignatureDigitalCertTimes, setESignatureDigitalCertTimes] = useState(1);

  const [dealData, setDealData] = useState<DealListItem>({
    id: '0',
    tag: 'inviting',
    tradeName: '-',
    count: {
      document: 0,
      participants: 0,
      events: 0,
    },
    participants: [],
    dealInitiation: [false, false, false],
    negotiation: [false],
    deal_close: [false],
    contractExecution: [false],
    executions: [],
    progress: '0',
    execution_file_complete: false,
    deal_type: '',
    category: '',
    deal_type_id: 0,
  });
  const [fileData, setFileData] = useState<DataRow>({
    id: '0',
    file_id: '0',
    isOfficialDoc: false,
    name: '-',
    type: '-',
    signPDFBase64A: false,
    signPDFBase64B: false,
    size: convertToFileSize(Number('0')),
    updated: convertToData('1911/01/01  00:00:00'),
    sign: {
      need: true,
      isSign: false,
      signStatus: 0,
      needSign: 0,
      firstSigner: 0,
    },
    action: '',
  });

  const [activeStep, setActiveStep] = useState(0);

  const timesHandler = () => {
    if (!hasChina) return;
    setESignatureDigitalCertTimes(eSignatureDigitalCertTimes - 1);
  };

  const getDetail = useCallback(async () => {
    try {
      if (typeof id === 'string' && typeof fileId === 'string') {
        const res = (await getDealFileList(id)).data;
        const doc: DataRow[] = res.data.map(
          (data): DataRow => ({
            id: data.id,
            file_id: data.file_id,
            isOfficialDoc: false,
            name: data.file.name,
            type: data.file.extension,
            size: convertToFileSize(Number(data.file.size)),
            updated: convertToData(data.file.updated_at),
            sign: {
              need: data.needSign === 1 ? true : false,
              isSign: data.signed === 1 ? true : false,
              signStatus: data.signStatus,
              needSign: data.needSign,
              firstSigner: data.firstSigner,
            },
            action: '',
            signPDFBase64A: data.signPDFBase64A ? true : false,
            signPDFBase64B: data.signPDFBase64B ? true : false,
          })
        );

        const file = doc.filter((item: DataRow) => {
          return item.id.toString() === fileId;
        });
        // console.log(file);
        setFileData(file[0]);
      }

      if (typeof id === 'string') {
        const res = (await getDealDetail(id)).data;
        const data: DealListItem = {
          id: res.data.id,
          tag: 'inviting',
          tradeName: res.data.name,
          count: {
            document: 0,
            participants: res.data.participants.length,
            events: 0,
          },
          participants: res.data.participants,
          dealInitiation: [res.data.progress === '0' ? false : true],
          negotiation: res.data.negotiation,
          deal_close: res.data.deal_close,
          contractExecution: [res.data.excution_file_complete],
          executions: res.data.executions,
          progress: res.data.progress,
          execution_file_complete: res.data.excution_file_complete,
          deal_type: res.data.deal_type.name,
          category: res.data.category,
          deal_type_id: Number(res.data.deal_type_id),
        };
        setDealData(data);

        const participantsAry = [] as Participants[];
        let haveNoneDMPUser = false;
        // 比對auth.userData.id和data.participants.acc_id, 相同的話則將data.paricipants.deal_role_id存入dealRole
        data.participants.forEach((item) => {
          if (item.acc_id === auth.userData.id) {
            setDealRole(item.deal_role_id.toString());
          }
          if (item.user.is_none_DMP_user) {
            haveNoneDMPUser = true;
          }

          participantsAry.push({
            name: `${item.user.acc_firstName} ${item.user.acc_lastName}`,
            id: item.user.id,
            sign: false,
            deal_role_id: item.deal_role_id,
          });
        });

        setParticipants(participantsAry);
        // 若有noneDMPUser則不進行下列判斷
        if (haveNoneDMPUser) return;
        if (checkCNAccount(data.participants)) {
          setHasChina(true);
          setShowTCModal(true);

          fetch(TCTermsMarkdown)
            .then((res) => res.text())
            .then((md) => {
              setTC(md);
            });
        }
      }
    } catch (error) {
      console.log(error);
      errorHandler(t('error.server'));
      setIsLoading(false);
      // 若出現錯誤則返回deal頁
      navigate(`/deal/${id}`, { replace: true });
    } finally {
      setIsLoading(false);
      checkESignTimes();
    }
  }, []);

  // 透過getUserDetail api取得userData中的eSignatureDigitalCertTimes, 並將值存入ESignatureDigitalCertTimes
  const checkESignTimes = useCallback(async () => {
    const isCNUser = auth.userData.acc_countryCode === '+86' ? true : false;
    try {
      const res = (await getUserDetail()).data;
      if (res.data.user_feature && !isCNUser) {
        setESignatureDigitalCertTimes(res.data.user_feature.ESignatureHKTimes);
        return;
      }

      if (res.data.user_feature && isCNUser) {
        setESignatureDigitalCertTimes(res.data.user_feature.ESignaturePRCTimes);
        return;
      }
    } catch (error) {}
  }, [hasChina]);

  useEffect(() => {
    if (auth.userData.plan.level === null) navigate(-1);
    getDetail();
  }, [getDetail]);

  const termHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { currentTarget } = e;

    if (currentTarget.scrollHeight - currentTarget.scrollTop <= currentTarget.clientHeight + 2) {
      setTermsInfo(false);
    }
  };

  const checkCNAccount = (participants: DealParticipants[]) => {
    // 檢查陣列中若僅出現1次CN則回傳true, 若出現2次或沒出現CN則回傳false
    const CNUser = participants.filter((item) => item.user.acc_country === 'China');
    if (CNUser.length === 1) return true;
    return false;
  };

  return isLoading ? (
    <Loading />
  ) : (
    <PageContainer
      title={
        <DetailTitle data={dealData} innerTitle={t('contractSigning')} fileId={fileId} redir={``} hideTag={true} />
      }
    >
      <Flex gridGap={'15px'}>
        <Box maxWidth={300}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {hasChina && (
              <Step>
                <StepLabel>
                  {t('pages.dealDetail.manageFiles.files.contractSigning.steps.crossBorderESigning')}
                </StepLabel>
                <StepContent>
                  {participants.map((p) => (
                    <Flex gridGap={'5px'} alignItems={'center'} key={`prc-${p.name}`} marginTop={'15px'}>
                      <Person.PersonSVG fill={theme.colors.secondary500} width={'24px'} />
                      {p.name}
                    </Flex>
                  ))}
                </StepContent>
              </Step>
            )}
            {!hasChina && (
              <Step>
                <StepLabel>{t('pages.dealDetail.manageFiles.files.contractSigning.steps.prcSigning')}</StepLabel>
                <StepContent>
                  {participants.map((p) => (
                    <Flex gridGap={'5px'} alignItems={'center'} key={`prc-${p.name}`} marginTop={'15px'}>
                      <Person.PersonSVG fill={theme.colors.secondary500} width={'24px'} />
                      {p.name}
                    </Flex>
                  ))}
                </StepContent>
              </Step>
            )}
          </Stepper>
        </Box>
        <Box width={'930px'}>
          {!showTCModal && (
            <ContractSign
              timesHandler={timesHandler}
              fileId={fileData.file_id}
              dealRole={dealRole}
              eSignTimes={eSignatureDigitalCertTimes}
              hasChina={hasChina}
              CNuser={hasChina && auth.userData.acc_countryCode === '+86' ? true : false}
            />
          )}
        </Box>
      </Flex>

      <Modal
        open={showTCModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: 'flex' }}
      >
        <div
          style={{
            margin: 'auto',
            width: '100%',
            maxWidth: '930px',
            backgroundColor: '#fff',
            padding: isMobile ? '15px 25px' : '30px 50px',
            borderRadius: '40px 40px 40px 0px',
          }}
        >
          <H2>{t('pages.auth.signUp.modal.terms.t&c.title')}</H2>
          <span style={{ font: 'Roboto 400 16px', lineHeight: '30px', color: 'red' }}>
            {t('pages.auth.signUp.modal.terms.t&c.title')}
          </span>
          <div
            className="content"
            style={{
              marginTop: '25px',
              height: '40vh',
              overflowY: 'auto',
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: isMobile ? '16px' : '20px',
              color: '#616161',
              lineHeight: '30px',
            }}
            onScroll={termHandler}
          >
            <ReactMarkdown children={TC} remarkPlugins={[remarkGfm]} />
          </div>
          <Flex
            gridGap={isMobile ? 20 : 40}
            marginTop={'28px'}
            alignItems={'center'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Button
              width={isMobile ? '100%' : 'unset'}
              onClick={() => setShowTCModal(false)}
              size={'lg'}
              variant={'primary'}
              rounded={false}
              disabled={termsInfo}
            >
              {t('buttons.agree')}
            </Button>
            <Button
              width={isMobile ? '100%' : 'unset'}
              color="primary"
              onClick={() => navigate(-1)}
              style={{ textDecoration: 'underline' }}
            >
              {t('buttons.back')}
            </Button>
          </Flex>
        </div>
      </Modal>
    </PageContainer>
  );
};

export default ContractSigningPage;

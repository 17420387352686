import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material';

import { Check, CloudUpload } from '@/components/Icon';
import { Text } from '@/components/UI/Typography/Text';

import { theme } from '@/theme/Theme';

const Completed = ({ nextHandler = () => {}, ButtonGroup, ReturnLink = '', userDetail }: ProfileProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Paper square elevation={0} sx={{ width: '100%', maxWidth: '930px' }}>
      <div style={{ width: '100%', padding: '80px 50px' }}>
        <Text textAlign={'center'} marginBottom={60}>
          <Check.CheckSVG width={80} height={80} fill={theme.colors.secondary400} />
          <Text fontSize={20} marginBottom={20}>
            {t('pages.auth.completed.title')}
          </Text>
          <Text textAlign={'center'} lineHeight={'20px'}>
            {t('pages.auth.completed.text')}
          </Text>
        </Text>
        {ButtonGroup}
      </div>
    </Paper>
  );
};

export default Completed;

import { useTranslation } from 'react-i18next';
import Footer from '../Footer';
import { ContainerWrapper, Title, CardWrapper, CardsList } from './style';
import { Text } from '@/components/UI/Typography/Text';
import { Flex } from '@/layout';
import { H2, H4 } from '@/components/UI/Typography/Heading';
import useCurrentLang from '@/hooks/useCurrentLang';
import { Link } from 'react-router-dom';
import useResize from '@/hooks/useResize';

const Features = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const items = t('pages.index.Features.items', { returnObjects: true });
  const { isTablet, isMobile } = useResize();

  // 將指定文字部分轉成超連結
  const convertTextToLink = (text: string) => {
    // 查看目前語系, 若為en則轉換"subscription package"為超連結, 若為tc則轉換"服務及定價"為超連結, 若為sc則轉換"服务及定价"為超連結
    const link = '/Subscription';
    const linkText = lang === 'en' ? 'subscription package' : lang === 'tc' ? '服務及定價' : '服务及定价';
    const linkTextLength = linkText.length;
    const linkTextIndex = text.indexOf(linkText);
    const linkTextEndIndex = linkTextIndex + linkTextLength;
    const linkTextBefore = text.slice(0, linkTextIndex);
    const linkTextAfter = text.slice(linkTextEndIndex);
    const linkTextMiddle = text.slice(linkTextIndex, linkTextEndIndex);
    const linkTextMiddleWithLink = (
      <Link style={{ textDecoration: 'underline' }} to={link}>
        {linkTextMiddle}
      </Link>
    );
    const linkTextWithLink = (
      <>
        {linkTextBefore}
        {linkTextMiddleWithLink}
        {linkTextAfter}
      </>
    );
    return linkTextIndex === -1 ? text : linkTextWithLink;
  };

  return (
    <>
      <ContainerWrapper>
        <Title>{t('pages.index.Features.title')}</Title>
        <CardsList isTablet={isTablet} isMobile={isMobile}>
          {items.map((item: any, index: number) => (
            <CardWrapper key={'features' + index} isTablet={isTablet} isMobile={isMobile}>
              <div className="icon">
                <img src={'/images/index' + item.icon} alt="" />
              </div>
              <div className="content">
                <div className="itemTitle">{item.name}</div>
                <H4 className="itemDesc">{convertTextToLink(item.content)}</H4>
              </div>
            </CardWrapper>
          ))}
        </CardsList>
      </ContainerWrapper>
      <Footer />
    </>
  );
};

export default Features;

import { useEffect } from 'react';
import { convertOptionCode } from '@/utils/convert';
import { useTranslation } from 'react-i18next';
import { Row } from '../styles';
import useCurrentLang from '@/hooks/useCurrentLang';

interface ConvertOptionsData {
  data: LEIRequestData;
  options: LEIResponse;
  type?: string;
}

interface NormalOptionsData {
  data: LEIRequestData;
}

interface ParentOptionsData {
  data: LEIRequestData;
  reasonOptions: NoRelationshipReasonOptions[];
}

const InitBasicInformation = ({ data, options }: ConvertOptionsData) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  useEffect(() => {}, [t]);

  const items = [
    {
      name: t('LEIForm.basicInfo.legalNameLang.label'),
      value: data.basicInformation
        ? convertOptionCode(lang, data.basicInformation.legalNameLanguage, 'Language', options)
        : '',
      type: 'text',
      key: 'legalName',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.legalName.label'),
      value: data.basicInformation ? data.basicInformation.legalName : '',
      type: 'text',
      key: 'legalName',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.transliteratedNameLang.label'),
      value: data.basicInformation
        ? convertOptionCode(lang, data.basicInformation.transliteratedOtherNameLanguage, 'Language', options)
        : '',
      type: 'text',
      key: 'transliteratedOtherNameLanguage',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.transliteratedName.label'),
      value: data.basicInformation ? data.basicInformation.transliteratedOtherName : '',
      type: 'text',
      key: 'transliteratedOtherName',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.entityLegalCategory.label'),
      value: data.basicInformation
        ? convertOptionCode(lang, data.basicInformation.entityLegalCategory, 'EntityLegalCategory', options)
        : '',
      type: 'text',
      key: 'entityLegalCategory',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.email.label'),
      value: data.basicInformation ? data.basicInformation.email : '',
      type: 'text',
      key: 'email',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.entityCreationDate.label'),
      value: data.basicInformation ? data.basicInformation.entityCreationDate : '',
      type: 'text',
      key: 'entityCreationDate',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.legalUseCategory.label'),
      value: data.basicInformation
        ? convertOptionCode(lang, data.basicInformation.usePurpCode, 'LEIPurpose', options)
        : '',
      type: 'text',
      key: 'usePurpCode',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.usePurpose.label'),
      value: data.basicInformation ? data.basicInformation.usePurpose : '',
      type: 'text',
      key: 'usePurpose',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.corporation.label'),
      value: data.basicInformation ? data.basicInformation.corporation : '',
      type: 'text',
      key: 'corporation',
      file: '',
    },
    {
      name: t('LEIForm.contactInfo.contactName.label'),
      value: data.basicInformation ? data.basicInformation.contactName : '',
      type: 'text',
      key: 'contactName',
      file: '',
    },
    {
      name: t('LEIForm.contactInfo.contactPhone.label'),
      value: data.basicInformation ? data.basicInformation.contactPhone : '',
      type: 'text',
      key: 'contactPhone',
      file: '',
    },
    {
      name: t('LEIForm.contactInfo.contactMail.label'),
      value: data.basicInformation ? data.basicInformation.contactMail : '',
      type: 'text',
      key: 'contactMail',
      file: '',
    },
    {
      name: t('LEIForm.contactInfo.contactDepartment.label'),
      value: data.basicInformation ? data.basicInformation.contactDepartment : '',
      type: 'text',
      key: 'contactDepartment',
      file: '',
    },
    {
      name: t('LEIForm.contactInfo.contactPosition.label'),
      value: data.basicInformation ? data.basicInformation.contactPosition : '',
      type: 'text',
      key: 'contactPosition',
      file: '',
    },
    {
      name: t('LEIForm.contactInfo.contactFix.label'),
      value: data.basicInformation ? data.basicInformation.contactFix : '',
      type: 'text',
      key: 'contactFix',
      file: '',
    },
    {
      name: t('LEIForm.contactInfo.contactDocumentCategory.label'),
      value: data.basicInformation
        ? convertOptionCode(lang, data.basicInformation.contactDocumentCategory, 'EntityLegalCategory', options)
        : '',
      type: 'text',
      key: 'contactDocumentCategory',
      file: '',
    },
    {
      name: t('LEIForm.contactInfo.contactDocumentNumber.label'),
      value: data.basicInformation ? data.basicInformation.contactDocumentNumber : '',
      type: 'text',
      key: 'contactDocumentNumber',
      file: '',
    },
    {
      name: t('LEIForm.contactInfo.contactIdentificationDocument.label'),
      value:
        data.basicInformation &&
        data.basicInformation &&
        data.basicInformation.contactIdentificationDocument &&
        data.basicInformation.contactIdentificationDocument instanceof File
          ? data.basicInformation.contactIdentificationDocument.name
          : data.basicInformation &&
            data.basicInformation.contactIdentificationDocument &&
            data.basicInformation.contactIdentificationDocument instanceof String
          ? data.basicInformation.contactIdentificationDocument
          : '',
      type: 'link',
      key: 'contactIdentificationDocument',
      file: data.basicInformation ? data.basicInformation.contactIdentificationDocument : '',
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <Row key={index}>
          <div className="title">{item.name}</div>
          <div className="content">
            {item.type === 'link' ? (
              <a
                target={'_blank'}
                rel="noreferrer"
                href={item.file instanceof File ? URL.createObjectURL(item.file as Blob) : '/#'}
              >
                {item.value}
              </a>
            ) : (
              <div className="content">{item.value}</div>
            )}
          </div>
        </Row>
      ))}
    </>
  );
};

const InitLegalAddress = ({ data, options }: ConvertOptionsData) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();

  useEffect(() => {}, [t]);

  const items = [
    {
      name: t('LEIForm.Address.language.label'),
      value: data.address ? convertOptionCode(lang, data.address.registerAddress.Language, 'Language', options) : '',
      type: 'text',
      key: 'language',
      file: '',
    },
    {
      name: t('LEIForm.Address.postCode.label'),
      value: data.address ? data.address.registerAddress.PostCode : '',
      type: 'text',
      key: 'postCode',
      file: '',
    },
    {
      name: t('LEIForm.Address.country.label'),
      value: data.address ? convertOptionCode(lang, data.address.registerAddress.Country, 'Country', options) : '',
      type: 'text',
      key: 'country',
      file: '',
    },
    {
      name: t('LEIForm.Address.province.label'),
      value:
        data.address && data.address.registerAddress.Country === 'CN'
          ? convertOptionCode(lang, data.address.registerAddress.Province, 'ChinaArea', options)
          : data.address
          ? data.address.registerAddress.Province
          : '',
      type: 'text',
      key: 'province',
      file: '',
    },
    {
      name: t('LEIForm.Address.city.label'),
      value:
        data.address && data.address.registerAddress.Country === 'CN'
          ? convertOptionCode(lang, data.address.registerAddress.City, 'ChinaArea', options)
          : data.address
          ? data.address.registerAddress.City
          : '',
      type: 'text',
      key: 'city',
      file: '',
    },
    {
      name: t('LEIForm.Address.address.label'),
      value: data.address ? data.address.registerAddress.Address : '',
      type: 'text',
      key: 'address',
      file: '',
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <Row key={index}>
          <div className="title">{item.name}</div>
          <div className="content">{item.value}</div>
        </Row>
      ))}
    </>
  );
};

const InitLegalOtherAddress = ({ data, options }: ConvertOptionsData) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  useEffect(() => {}, [t]);

  const items = [
    {
      name: t('LEIForm.Address.language.label'),
      value: data.address
        ? convertOptionCode(lang, data.address.otherRegisterAddress.Language, 'Language', options)
        : '',
      type: 'text',
      key: 'language',
      file: '',
    },
    {
      name: t('LEIForm.Address.postCode.label'),
      value: data.address ? data.address.otherRegisterAddress.PostCode : '',
      type: 'text',
      key: 'postCode',
      file: '',
    },
    {
      name: t('LEIForm.Address.country.label'),
      value: data.address ? convertOptionCode(lang, data.address.otherRegisterAddress.Country, 'Country', options) : '',
      type: 'text',
      key: 'country',
      file: '',
    },
    {
      name: t('LEIForm.Address.province.label'),
      value:
        data.address && data.address.otherRegisterAddress.Country === 'CN'
          ? convertOptionCode(lang, data.address.otherRegisterAddress.Province, 'ChinaArea', options)
          : data.address
          ? data.address.otherRegisterAddress.Province
          : '',
      type: 'text',
      key: 'province',
      file: '',
    },
    {
      name: t('LEIForm.Address.city.label'),
      value:
        data.address && data.address.otherRegisterAddress.Country === 'CN'
          ? convertOptionCode(lang, data.address.otherRegisterAddress.City, 'ChinaArea', options)
          : data.address
          ? data.address.otherRegisterAddress.City
          : '',
      type: 'text',
      key: 'city',
      file: '',
    },
    {
      name: t('LEIForm.Address.address.label'),
      value: data.address ? data.address.otherRegisterAddress.Address : '',
      type: 'text',
      key: 'address',
      file: '',
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <Row key={index}>
          <div className="title">{item.name}</div>
          <div className="content">{item.value}</div>
        </Row>
      ))}
    </>
  );
};

const InitLegalOfficeAddress = ({ data, options }: ConvertOptionsData) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  useEffect(() => {}, [t]);

  const items = [
    {
      name: t('LEIForm.Address.language.label'),
      value: data.address ? convertOptionCode(lang, data.address.officeAddress.Language, 'Language', options) : '',
      type: 'text',
      key: 'language',
      file: '',
    },
    {
      name: t('LEIForm.Address.postCode.label'),
      value: data.address ? data.address.officeAddress.PostCode : '',
      type: 'text',
      key: 'postCode',
      file: '',
    },
    {
      name: t('LEIForm.Address.country.label'),
      value: data.address ? convertOptionCode(lang, data.address.officeAddress.Country, 'Country', options) : '',
      type: 'text',
      key: 'country',
      file: '',
    },
    {
      name: t('LEIForm.Address.province.label'),
      value:
        data.address && data.address.officeAddress.Country === 'CN'
          ? convertOptionCode(lang, data.address.officeAddress.Province, 'ChinaArea', options)
          : data.address
          ? data.address.officeAddress.Province
          : '',
      type: 'text',
      key: 'province',
      file: '',
    },
    {
      name: t('LEIForm.Address.city.label'),
      value:
        data.address && data.address.officeAddress.Country === 'CN'
          ? convertOptionCode(lang, data.address.officeAddress.City, 'ChinaArea', options)
          : data.address
          ? data.address.officeAddress.City
          : '',
      type: 'text',
      key: 'city',
      file: '',
    },
    {
      name: t('LEIForm.Address.address.label'),
      value: data.address ? data.address.officeAddress.Address : '',
      type: 'text',
      key: 'address',
      file: '',
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <Row key={index}>
          <div className="title">{item.name}</div>
          <div className="content">{item.value}</div>
        </Row>
      ))}
    </>
  );
};

const InitLegalOtherOfficeAddress = ({ data, options }: ConvertOptionsData) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  useEffect(() => {}, [t]);

  const items = [
    {
      name: t('LEIForm.Address.language.label'),
      value: data.address ? convertOptionCode(lang, data.address.otherOfficeAddress.Language, 'Language', options) : '',
      type: 'text',
      key: 'language',
      file: '',
    },
    {
      name: t('LEIForm.Address.postCode.label'),
      value: data.address ? data.address.otherOfficeAddress.PostCode : '',
      type: 'text',
      key: 'postCode',
      file: '',
    },
    {
      name: t('LEIForm.Address.country.label'),
      value: data.address ? convertOptionCode(lang, data.address.otherOfficeAddress.Country, 'Country', options) : '',
      type: 'text',
      key: 'country',
      file: '',
    },
    {
      name: t('LEIForm.Address.province.label'),
      value:
        data.address && data.address.otherOfficeAddress.Country === 'CN'
          ? convertOptionCode(lang, data.address.otherOfficeAddress.Province, 'ChinaArea', options)
          : data.address
          ? data.address.otherOfficeAddress.Province
          : '',
      type: 'text',
      key: 'province',
      file: '',
    },
    {
      name: t('LEIForm.Address.city.label'),
      value:
        data.address && data.address.otherOfficeAddress.Country === 'CN'
          ? convertOptionCode(lang, data.address.otherOfficeAddress.City, 'ChinaArea', options)
          : data.address
          ? data.address.otherOfficeAddress.City
          : '',
      type: 'text',
      key: 'city',
      file: '',
    },
    {
      name: t('LEIForm.Address.address.label'),
      value: data.address ? data.address.otherOfficeAddress.Address : '',
      type: 'text',
      key: 'address',
      file: '',
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <Row key={index}>
          <div className="title">{item.name}</div>
          <div className="content">{item.value}</div>
        </Row>
      ))}
    </>
  );
};

const InitLegalForm = ({ data, options }: ConvertOptionsData) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();

  useEffect(() => {}, [t]);

  const items = [
    {
      name: t('LEIForm.legalForm.legalFormCountry.label'),
      value: data.legalBusiness ? convertOptionCode(lang, data.legalBusiness.legalFormCountry, 'Country', options) : '',
      type: 'text',
      key: 'legalFormCountry',
      file: '',
    },
    {
      name: t('LEIForm.legalForm.legalFormLanguage.label'),
      value:
        data.legalBusiness && data.legalBusiness.legalFormLanguage
          ? convertOptionCode(lang, data.legalBusiness.legalFormLanguage, 'Language', options)
          : '',
      type: 'text',
      key: 'legalFormLanguage',
      file: '',
    },
    {
      name: t('LEIForm.legalForm.legalFormCode.label'),
      value:
        data.legalBusiness && data.legalBusiness.legalFormCode !== '8888'
          ? convertOptionCode(lang, data.legalBusiness.legalFormCode, 'LegalForm', options)
          : data.legalBusiness
          ? data.legalBusiness.otherLegalFormCode
          : '',
      type: 'text',
      key: 'legalFormCode',
      file: '',
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <Row key={index}>
          <div className="title">{item.name}</div>
          <div className="content">{item.value}</div>
        </Row>
      ))}
    </>
  );
};

const InitSupportingMaterials = ({ data, options }: ConvertOptionsData) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  useEffect(() => {}, [t]);

  const items = [
    {
      name: t('LEIForm.businessInfo.registrationAuthorityCode.label'),
      value: data.legalBusiness
        ? convertOptionCode(lang, data.legalBusiness.registrationAuthorityCode, 'RAInformation', options)
        : '',
      type: 'text',
      key: 'registrationAuthorityCode',
      file: '',
    },
    {
      name: t('LEIForm.businessInfo.businessRegistrationCertificateNumber.label'),
      value: data.legalBusiness ? data.legalBusiness.businessRegistrationCertificateNumber : '',
      type: 'text',
      key: 'businessRegistrationCertificateNumber',
      file: '',
    },
    {
      name: t('LEIForm.businessInfo.businessRegistrationOffice.label'),
      value: data.legalBusiness ? data.legalBusiness.businessRegistrationOffice : '',
      type: 'text',
      key: 'businessRegistrationOffice',
      file: '',
    },
    {
      name: t('LEIForm.businessInfo.effectiveDateOfBusinessRegistrationCertificate.label'),
      value: data.legalBusiness ? data.legalBusiness.effectiveDateOfBusinessRegistrationCertificate : '',
      type: 'text',
      key: 'effectiveDateOfBusinessRegistrationCertificate',
      file: '',
    },
    {
      name: t('LEIForm.businessInfo.annualReturn.label'),
      value:
        data.legalBusiness && data.legalBusiness.annualReturn instanceof File
          ? data.legalBusiness.annualReturn.name
          : data.legalBusiness && data.legalBusiness.annualReturn instanceof String
          ? data.legalBusiness.annualReturn
          : '',
      type: 'link',
      key: 'annualReturn',
      file:
        data.legalBusiness && data.legalBusiness.annualReturn instanceof File ? data.legalBusiness.annualReturn : '',
    },
    {
      name: t('LEIForm.businessInfo.businessRegistrationCertificateDocument.label'),
      value:
        data.legalBusiness && data.legalBusiness.businessRegistrationCertificateDocument instanceof File
          ? data.legalBusiness.businessRegistrationCertificateDocument.name
          : data.legalBusiness && data.legalBusiness.businessRegistrationCertificateDocument instanceof String
          ? data.legalBusiness.businessRegistrationCertificateDocument
          : '',
      type: 'link',
      key: 'businessRegistrationCertificateDocument',
      file:
        data.legalBusiness && data.legalBusiness.businessRegistrationCertificateDocument instanceof File
          ? data.legalBusiness.businessRegistrationCertificateDocument
          : '',
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <Row key={index}>
          <div className="title">{item.name}</div>
          {item.type === 'link' ? (
            <a
              target={'_blank'}
              rel="noreferrer"
              href={item.file instanceof File ? URL.createObjectURL(item.file as Blob) : '/#'}
            >
              {item.value}
            </a>
          ) : (
            <div className="content">{item.value}</div>
          )}
        </Row>
      ))}
    </>
  );
};

const InitDirectParent = ({ data, options }: ConvertOptionsData) => {
  // @ts-ignore
  const { t } = useTranslation();
  useEffect(() => {}, [t]);

  if (data.directParent && !data.directParent.provideDirectParentCompanyInfo) {
    const items = [
      {
        name: '-',
        value: t('pages.eTrust.LEI.steps.step3.unableProvideUltimateComapny'),
        type: 'text',
        key: 'ultimateParent',
        file: '',
      },
      {
        name: t('pages.eTrust.LEI.steps.step3.reasonsNotAvailable'),
        value: options.NoRelationshipReason?.length
          ? options.NoRelationshipReason[parseInt(data.directParent.reason)]?.textOri
          : '',
        type: 'text',
        key: 'ultimateParent',
        file: '',
      },
    ];

    return (
      <>
        {items.map((item, index) => (
          <Row key={index}>
            <div className="title">{item.name}</div>
            <div className="content">{item.value}</div>
          </Row>
        ))}
      </>
    );
  }

  if (data.directParent && data.directParent.haveLEICode) {
    const items = [
      {
        name: t('LEIForm.parent.ultimateParentLeiCode.label'),
        value: data.directParent ? data.directParent.directParentLeiCode : '',
        type: 'text',
        key: 'ultimateParentLEICode',
        file: '',
      },
      {
        name: t('LEIForm.parent.checkFileType.label'),
        value: data.directParent ? data.directParent.checkFileType : '',
        type: 'text',
        key: 'ultimateParentLEICode',
        file: '',
      },
      {
        name: t('LEIForm.parent.criterionType.label'),
        value: data.directParent ? data.directParent.criterionType : '',
        type: 'text',
        key: 'criterionType',
        file: '',
      },
      {
        name: t('LEIForm.parent.relationShipStartDate.label'),
        value: data.directParent ? data.directParent.relationShipStartDate : '',
        type: 'text',
        key: 'relationShipStartDate',
        file: '',
      },
      {
        name: t('LEIForm.parent.relationShipEndDate.label'),
        value: data.directParent ? data.directParent.relationShipEndDate : '',
        type: 'text',
        key: 'relationShipEndDate',
        file: '',
      },
      {
        name: t('LEIForm.parent.accountStartTime.label'),
        value: data.directParent ? data.directParent.accountStartTime : '',
        type: 'text',
        key: 'accountStartTime',
        file: '',
      },
      {
        name: t('LEIForm.parent.accountEndTime.label'),
        value: data.directParent ? data.directParent.accountEndTime : '',
        type: 'text',
        key: 'accountEndTime',
        file: '',
      },
      {
        name: t('LEIForm.parent.docStartTime.label'),
        value: data.directParent ? data.directParent.docStartTime : '',
        type: 'text',
        key: 'docStartTime',
        file: '',
      },
      {
        name: t('LEIForm.parent.docEndTime.label'),
        value: data.directParent ? data.directParent.docEndTime : '',
        type: 'text',
        key: 'docEndTime',
        file: '',
      },
      {
        name: t('LEIForm.parent.holdingPercent.label'),
        value: data.directParent ? data.directParent.directHoldingPercent : '',
        type: 'text',
        key: 'directHoldingPercent',
        file: '',
      },
      {
        name: t('LEIForm.parent.checkFileType.label'),
        value:
          data.directParent && data.directParent.cerifiedDocuments instanceof File
            ? data.directParent.cerifiedDocuments.name
            : data.directParent && data.directParent.cerifiedDocuments instanceof String
            ? data.directParent.cerifiedDocuments
            : '',
        type: 'link',
        key: 'cerifiedDocuments',
        file:
          data.directParent && data.directParent.cerifiedDocuments instanceof File
            ? data.directParent.cerifiedDocuments
            : '',
      },
    ];

    return (
      <>
        {items.map((item, index) => (
          <Row key={index}>
            <div className="title">{item.name}</div>
            {item.type === 'link' ? (
              <a
                target={'_blank'}
                rel="noreferrer"
                href={item.file instanceof File ? URL.createObjectURL(item.file as Blob) : '/#'}
              >
                {item.value}
              </a>
            ) : (
              <div className="content">{item.value}</div>
            )}
          </Row>
        ))}
      </>
    );
  }

  // const items = [
  //   {
  //     name: '-',
  //     value: t('pages.eTrust.LEI.steps.step3.unableProvideUltimateComapny'),
  //     type: 'text',
  //     key: 'ultimateParent',
  //     file: '',
  //   },
  // ];

  return <ConvertParentData data={data} options={options} type={'directParent'} />;
};

const InitUltimateParent = ({ data, options }: ConvertOptionsData) => {
  // @ts-ignore
  const { t } = useTranslation();
  useEffect(() => {}, [t]);

  if (data.ultimateParent && !data.ultimateParent.provideDirectParentCompanyInfo) {
    const items = [
      {
        name: '-',
        value: t('pages.eTrust.LEI.steps.step3.unableProvideUltimateComapny'),
        type: 'text',
        key: 'ultimateParent',
        file: '',
      },
      {
        name: t('pages.eTrust.LEI.steps.step3.reasonsNotAvailable'),
        value: options.NoRelationshipReason?.length
          ? options.NoRelationshipReason[parseInt(data.ultimateParent.reason)]?.textOri
          : '',
        type: 'text',
        key: 'ultimateParent',
        file: '',
      },
    ];

    return (
      <>
        {items.map((item, index) => (
          <Row key={index}>
            <div className="title">{item.name}</div>
            <div className="content">{item.value}</div>
          </Row>
        ))}
      </>
    );
  }

  if (data.ultimateParent && data.ultimateParent.haveLEICode) {
    const items = [
      {
        name: t('LEIForm.parent.ultimateParentLeiCode.label'),
        value: data.ultimateParent ? data.ultimateParent.ultimateParentLeiCode : '',
        type: 'text',
        key: 'ultimateParentLEICode',
        file: '',
      },
      {
        name: t('LEIForm.parent.checkFileType.label'),
        value: data.ultimateParent ? data.ultimateParent.checkFileType : '',
        type: 'text',
        key: 'ultimateParentLEICode',
        file: '',
      },
      {
        name: t('LEIForm.parent.criterionType.label'),
        value: data.ultimateParent ? data.ultimateParent.criterionType : '',
        type: 'text',
        key: 'criterionType',
        file: '',
      },
      {
        name: t('LEIForm.parent.relationShipStartDate.label'),
        value: data.ultimateParent ? data.ultimateParent.relationShipStartDate : '',
        type: 'text',
        key: 'relationShipStartDate',
        file: '',
      },
      {
        name: t('LEIForm.parent.relationShipEndDate.label'),
        value: data.ultimateParent ? data.ultimateParent.relationShipEndDate : '',
        type: 'text',
        key: 'relationShipEndDate',
        file: '',
      },
      {
        name: t('LEIForm.parent.accountStartTime.label'),
        value: data.ultimateParent ? data.ultimateParent.accountStartTime : '',
        type: 'text',
        key: 'accountStartTime',
        file: '',
      },
      {
        name: t('LEIForm.parent.accountEndTime.label'),
        value: data.ultimateParent ? data.ultimateParent.accountEndTime : '',
        type: 'text',
        key: 'accountEndTime',
        file: '',
      },
      {
        name: t('LEIForm.parent.docStartTime.label'),
        value: data.ultimateParent ? data.ultimateParent.docStartTime : '',
        type: 'text',
        key: 'docStartTime',
        file: '',
      },
      {
        name: t('LEIForm.parent.docEndTime.label'),
        value: data.ultimateParent ? data.ultimateParent.docEndTime : '',
        type: 'text',
        key: 'docEndTime',
        file: '',
      },
      {
        name: t('LEIForm.parent.holdingPercent.label'),
        value: data.ultimateParent ? data.ultimateParent.ultimateHoldingPercent : '',
        type: 'text',
        key: 'ultimateHoldingPercent',
        file: '',
      },
      {
        name: t('LEIForm.parent.checkFileType.label'),
        value:
          data.ultimateParent && data.ultimateParent.cerifiedDocuments instanceof File
            ? data.ultimateParent.cerifiedDocuments.name
            : data.ultimateParent && data.ultimateParent.cerifiedDocuments instanceof String
            ? data.ultimateParent.cerifiedDocuments
            : '',
        type: 'link',
        key: 'cerifiedDocuments',
        file:
          data.ultimateParent && data.ultimateParent.cerifiedDocuments instanceof File
            ? data.ultimateParent.cerifiedDocuments
            : '',
      },
    ];

    return (
      <>
        {items.map((item, index) => (
          <Row key={index}>
            <div className="title">{item.name}</div>
            {item.type === 'link' ? (
              <a
                target={'_blank'}
                rel="noreferrer"
                href={item.file instanceof File ? URL.createObjectURL(item.file as Blob) : '/#'}
              >
                {item.value}
              </a>
            ) : (
              <div className="content">{item.value}</div>
            )}
          </Row>
        ))}
      </>
    );
  }

  // const items = [
  //   {
  //     name: '-',
  //     value: t('pages.eTrust.LEI.steps.step3.unableProvideUltimateComapny'),
  //     type: 'text',
  //     key: 'ultimateParent',
  //     file: '',
  //   },
  // ];

  return <ConvertParentData data={data} options={options} type={'ultimateParent'} />;
};

const ConvertParentData = ({ data, options, type }: ConvertOptionsData) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  useEffect(() => {}, [t]);

  let parentData = null;

  if (type === 'directParent') {
    parentData = data.directParent;
  } else if (type === 'ultimateParent') {
    parentData = data.ultimateParent;
  } else {
    return <></>;
  }

  const items = [
    {
      name: t('LEIForm.basicInfo.legalName.label'),
      value: parentData ? parentData.legalName : '',
      type: 'text',
      key: 'legalName',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.otherLegalName.label'),
      value: parentData ? parentData.otherLegalName : '',
      type: 'text',
      key: 'otherLegalName',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.entityLegalCategory.label'),
      value: parentData ? convertOptionCode(lang, parentData.entityLegalCategory, 'EntityLegalCategory', options) : '',
      type: 'text',
      key: 'entityLegalCategory',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.entityCreationDate.label'),
      value: parentData ? parentData.entityCreationDate : '',
      type: 'text',
      key: 'entityCreationDate',
      file: '',
    },
    {
      name: t('LEIForm.basicInfo.corporation.label'),
      value: parentData ? parentData.corporation : '',
      type: 'text',
      key: 'corporation',
      file: '',
    },
    {
      name: `${t('info.registerAddress')} ${t('LEIForm.Address.language.label')}`,
      value: parentData ? convertOptionCode(lang, parentData.registerAddress.Language, 'Language', options) : '',
      type: 'text',
      key: 'language',
      file: '',
    },
    {
      name: `${t('info.registerAddress')} ${t('LEIForm.Address.postCode.label')}`,
      value: parentData ? parentData.registerAddress.PostCode : '',
      type: 'text',
      key: 'postCode',
      file: '',
    },
    {
      name: `${t('info.registerAddress')} ${t('LEIForm.Address.country.label')}`,
      value: parentData ? convertOptionCode(lang, parentData.registerAddress.Country, 'Country', options) : '',
      type: 'text',
      key: 'country',
      file: '',
    },
    {
      name: `${t('info.registerAddress')} ${t('LEIForm.Address.province.label')}`,
      value:
        parentData && parentData.registerAddress.Country === 'CN'
          ? convertOptionCode(lang, parentData.registerAddress.Province, 'ChinaArea', options)
          : parentData
          ? parentData.registerAddress.Province
          : '',
      type: 'text',
      key: 'province',
      file: '',
    },
    {
      name: `${t('info.registerAddress')} ${t('LEIForm.Address.city.label')}`,
      value:
        parentData && parentData.registerAddress.Country === 'CN'
          ? convertOptionCode(lang, parentData.registerAddress.City, 'ChinaArea', options)
          : parentData
          ? parentData.registerAddress.City
          : '',
      type: 'text',
      key: 'city',
      file: '',
    },
    {
      name: `${t('info.registerAddress')} ${t('LEIForm.Address.address.label')}`,
      value: parentData ? parentData.registerAddress.Address : '',
      type: 'text',
      key: 'address',
      file: '',
    },
    {
      name: `${t('info.otherRegisterAddress')} ${t('LEIForm.Address.language.label')}`,
      value:
        parentData && parentData.otherRegisterAddressAvaliavle
          ? convertOptionCode(lang, parentData.otherRegisterAddress.Language, 'Language', options)
          : '',
      type: 'text',
      key: 'language',
      file: '',
    },
    {
      name: `${t('info.otherRegisterAddress')} ${t('LEIForm.Address.postCode.label')}`,
      value: parentData && parentData.otherRegisterAddressAvaliavle ? parentData.otherRegisterAddress.PostCode : '',
      type: 'text',
      key: 'postCode',
      file: '',
    },
    {
      name: `${t('info.otherRegisterAddress')} ${t('LEIForm.Address.country.label')}`,
      value:
        parentData && parentData.otherRegisterAddressAvaliavle
          ? convertOptionCode(lang, parentData.otherRegisterAddress.Country, 'Country', options)
          : '',
      type: 'text',
      key: 'country',
      file: '',
    },
    {
      name: `${t('info.otherRegisterAddress')} ${t('LEIForm.Address.province.label')}`,
      value:
        parentData && parentData.otherRegisterAddressAvaliavle && parentData.otherRegisterAddress.Country === 'CN'
          ? convertOptionCode(lang, parentData.otherRegisterAddress.Province, 'ChinaArea', options)
          : parentData && parentData.otherRegisterAddressAvaliavle
          ? parentData.otherRegisterAddress.Province
          : '',
      type: 'text',
      key: 'province',
      file: '',
    },
    {
      name: `${t('info.otherRegisterAddress')} ${t('LEIForm.Address.city.label')}`,
      value:
        parentData && parentData.otherRegisterAddressAvaliavle && parentData.otherRegisterAddress.Country === 'CN'
          ? convertOptionCode(lang, parentData.otherRegisterAddress.City, 'ChinaArea', options)
          : parentData && parentData.otherRegisterAddressAvaliavle
          ? parentData.otherRegisterAddress.City
          : '',
      type: 'text',
      key: 'city',
      file: '',
    },
    {
      name: `${t('info.otherRegisterAddress')} ${t('LEIForm.Address.address.label')}`,
      value: parentData && parentData.otherRegisterAddressAvaliavle ? parentData.otherRegisterAddress.Address : '',
      type: 'text',
      key: 'address',
      file: '',
    },
    {
      name: `${t('info.officeAddress')} ${t('LEIForm.Address.language.label')}`,
      value: parentData ? convertOptionCode(lang, parentData.officeAddress.Language, 'Language', options) : '',
      type: 'text',
      key: 'language',
      file: '',
    },
    {
      name: `${t('info.officeAddress')} ${t('LEIForm.Address.postCode.label')}`,
      value: parentData ? parentData.officeAddress.PostCode : '',
      type: 'text',
      key: 'postCode',
      file: '',
    },
    {
      name: `${t('info.officeAddress')} ${t('LEIForm.Address.country.label')}`,
      value: parentData ? convertOptionCode(lang, parentData.officeAddress.Country, 'Country', options) : '',
      type: 'text',
      key: 'country',
      file: '',
    },
    {
      name: `${t('info.officeAddress')} ${t('LEIForm.Address.province.label')}`,
      value:
        parentData && parentData.officeAddress.Country === 'CN'
          ? convertOptionCode(lang, parentData.officeAddress.Province, 'ChinaArea', options)
          : parentData
          ? parentData.officeAddress.Province
          : '',
      type: 'text',
      key: 'province',
      file: '',
    },
    {
      name: `${t('info.officeAddress')} ${t('LEIForm.Address.city.label')}`,
      value:
        parentData && parentData.officeAddress.Country === 'CN'
          ? convertOptionCode(lang, parentData.officeAddress.City, 'ChinaArea', options)
          : parentData
          ? parentData.officeAddress.City
          : '',
      type: 'text',
      key: 'city',
      file: '',
    },
    {
      name: `${t('info.officeAddress')} ${t('LEIForm.Address.address.label')}`,
      value: parentData && parentData.otherOfficeAddress ? parentData.officeAddress.Address : '',
      type: 'text',
      key: 'address',
      file: '',
    },
    {
      name: `${t('info.otherOfficeAddress')} ${t('LEIForm.Address.language.label')}`,
      value:
        parentData && parentData.otherOfficeAddress
          ? convertOptionCode(lang, parentData.otherOfficeAddress.Language, 'Language', options)
          : '',
      type: 'text',
      key: 'language',
      file: '',
    },
    {
      name: `${t('info.otherOfficeAddress')} ${t('LEIForm.Address.postCode.label')}`,
      value: parentData && parentData.otherOfficeAddressAvaliavle ? parentData.otherOfficeAddress.PostCode : '',
      type: 'text',
      key: 'postCode',
      file: '',
    },
    {
      name: `${t('info.otherOfficeAddress')} ${t('LEIForm.Address.country.label')}`,
      value:
        parentData && parentData.otherOfficeAddressAvaliavle
          ? convertOptionCode(lang, parentData.otherOfficeAddress.Country, 'Country', options)
          : '',
      type: 'text',
      key: 'country',
      file: '',
    },
    {
      name: `${t('info.otherOfficeAddress')} ${t('LEIForm.Address.province.label')}`,
      value:
        parentData && parentData.otherOfficeAddressAvaliavle && parentData.otherOfficeAddress.Country === 'CN'
          ? convertOptionCode(lang, parentData.otherOfficeAddress.Province, 'ChinaArea', options)
          : parentData && parentData.otherOfficeAddressAvaliavle
          ? parentData.otherOfficeAddress.Province
          : '',
      type: 'text',
      key: 'province',
      file: '',
    },
    {
      name: `${t('info.otherOfficeAddress')} ${t('LEIForm.Address.city.label')}`,
      value:
        parentData && parentData.otherOfficeAddressAvaliavle && parentData.otherOfficeAddress.Country === 'CN'
          ? convertOptionCode(lang, parentData.otherOfficeAddress.City, 'ChinaArea', options)
          : parentData && parentData.otherOfficeAddressAvaliavle
          ? parentData.otherOfficeAddress.City
          : '',
      type: 'text',
      key: 'city',
      file: '',
    },
    {
      name: `${t('info.otherOfficeAddress')} ${t('LEIForm.Address.address.label')}`,
      value: parentData && parentData.otherOfficeAddressAvaliavle ? parentData.otherOfficeAddress.Address : '',
      type: 'text',
      key: 'address',
      file: '',
    },
    {
      name: t('LEIForm.legalForm.legalFormCountry.label'),
      value: parentData ? convertOptionCode(lang, parentData.legalFormCountry, 'Country', options) : '',
      type: 'text',
      key: 'legalFormCountry',
      file: '',
    },
    {
      name: t('LEIForm.legalForm.legalFormLanguage.label'),
      value: parentData ? parentData.legalFormLanguage : '',
      type: 'text',
      key: 'legalFormLanguage',
      file: '',
    },
    {
      name: t('LEIForm.legalForm.legalFormCode.label'),
      value: parentData ? convertOptionCode(lang, parentData.legalFormCode, 'LegalForm', options) : '',
      type: 'text',
      key: 'legalFormCode',
      file: '',
    },
    {
      name: t('LEIForm.businessInfo.registrationAuthorityCode.label'),
      value: parentData ? parentData.registrationAuthorityCode : '',
      type: 'text',
      key: 'registrationAuthorityCode',
      file: '',
    },
    {
      name: t('LEIForm.businessInfo.businessRegistrationCertificateNumber.label'),
      value: parentData ? parentData.businessRegistrationCertificateNumber : '',
      type: 'text',
      key: 'businessRegistrationCertificateNumber',
      file: '',
    },
    {
      name: t('LEIForm.businessInfo.businessRegistrationOffice.label'),
      value: parentData ? parentData.businessRegistrationOffice : '',
      type: 'text',
      key: 'businessRegistrationOffice',
      file: '',
    },
    {
      name: t('LEIForm.businessInfo.effectiveDateOfBusinessRegistrationCertificate.label'),
      value: parentData ? parentData.effectiveDateOfBusinessRegistrationCertificate : '',
      type: 'text',
      key: 'effectiveDateOfBusinessRegistrationCertificate',
      file: '',
    },
    {
      name: t('LEIForm.businessInfo.certificateOfIncorporationCopy.label'),
      value:
        parentData && parentData.certificateOfIncorporationCopy instanceof File
          ? parentData.certificateOfIncorporationCopy.name
          : parentData && parentData.certificateOfIncorporationCopy instanceof String
          ? parentData.certificateOfIncorporationCopy
          : '',
      type: 'link',
      key: 'certificateOfIncorporationCopy',
      file:
        parentData && parentData.certificateOfIncorporationCopy instanceof File
          ? parentData.certificateOfIncorporationCopy
          : '',
    },
    {
      name: t('LEIForm.businessInfo.businessRegistrationCertificateDocument.label'),
      value:
        parentData && parentData.certificateOfIncorporationCopy instanceof File
          ? parentData.certificateOfIncorporationCopy.name
          : parentData && parentData.certificateOfIncorporationCopy instanceof String
          ? parentData.certificateOfIncorporationCopy
          : '',
      type: 'link',
      key: 'businessRegistrationCertificateDocument',
      file:
        parentData && parentData.certificateOfIncorporationCopy instanceof File
          ? parentData.certificateOfIncorporationCopy
          : '',
    },
    {
      name: t('LEIForm.parent.checkFileType.label'),
      value: parentData ? parentData.checkFileType : '',
      type: 'text',
      key: 'ultimateParentLEICode',
      file: '',
    },
    {
      name: t('LEIForm.parent.criterionType.label'),
      value: parentData ? parentData.criterionType : '',
      type: 'text',
      key: 'criterionType',
      file: '',
    },
    {
      name: t('LEIForm.parent.relationShipStartDate.label'),
      value: parentData ? parentData.relationShipStartDate : '',
      type: 'text',
      key: 'relationShipStartDate',
      file: '',
    },
    {
      name: t('LEIForm.parent.relationShipEndDate.label'),
      value: parentData ? parentData.relationShipEndDate : '',
      type: 'text',
      key: 'relationShipEndDate',
      file: '',
    },
    {
      name: t('LEIForm.parent.accountStartTime.label'),
      value: parentData ? parentData.accountStartTime : '',
      type: 'text',
      key: 'accountStartTime',
      file: '',
    },
    {
      name: t('LEIForm.parent.accountEndTime.label'),
      value: parentData ? parentData.accountEndTime : '',
      type: 'text',
      key: 'accountEndTime',
      file: '',
    },
    {
      name: t('LEIForm.parent.docStartTime.label'),
      value: parentData ? parentData.docStartTime : '',
      type: 'text',
      key: 'docStartTime',
      file: '',
    },
    {
      name: t('LEIForm.parent.docEndTime.label'),
      value: parentData ? parentData.docEndTime : '',
      type: 'text',
      key: 'docEndTime',
      file: '',
    },
    {
      name: t('LEIForm.parent.checkFileType.label'),
      value:
        parentData && parentData.cerifiedDocuments instanceof File
          ? parentData.cerifiedDocuments.name
          : parentData && parentData.cerifiedDocuments instanceof String
          ? parentData.cerifiedDocuments
          : '',
      type: 'link',
      key: 'cerifiedDocuments',
      file: parentData && parentData.cerifiedDocuments instanceof File ? parentData.cerifiedDocuments : '',
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <Row key={index}>
          <div className="title">{item.name}</div>
          {item.type === 'link' ? (
            <a
              target={'_blank'}
              rel="noreferrer"
              href={item.file instanceof File ? URL.createObjectURL(item.file as Blob) : '/#'}
            >
              {item.value}
            </a>
          ) : (
            <div className="content">{item.value}</div>
          )}
        </Row>
      ))}
    </>
  );
};

export {
  InitBasicInformation,
  InitLegalAddress,
  InitLegalOfficeAddress,
  InitLegalForm,
  InitSupportingMaterials,
  InitDirectParent,
  InitUltimateParent,
  InitLegalOtherAddress,
  InitLegalOtherOfficeAddress,
};

import { axios } from '@/utils/axios';

// api有問題暫時看不到資料, 待後端確認
/**
 * ANCHOR 取得列表
 */
export const getCompanyService = async () => {
  return axios.get('/api/companyCategory');
};

/**
 * ANCHOR 上傳
 */
export const storeCompanyService = async () => {
  return axios.post('/api/companyCategory');
};

/**
 * ANCHOR 更新
 */
export const updateCompanyService = async (id: number, data: []) => {
  return axios.put(`/api/companyCategory/${id}`, data);
};

/**
 * ANCHOR 刪除
 */
export const DestroyCompanyService = async (id: number) => {
  return axios.delete(`/api/companyCategory/${id}`);
};

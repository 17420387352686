import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCompanyService } from '@/api/type/companyCategory.api';
import { getCompanyCategory } from '@/api/type/companyProductService.api';

import { SearchBarInputsWrapper } from '@/components/Container/AuthContainer/styles';
import { H1 } from '@/components/UI/Typography/Heading';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  IconButton,
  TextField,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { FormSelect } from '@/components/UI/Select';
// import { category, product } from '@/data/select';
import { convertToSelectData } from '@/utils/convert';
import useCountryDetail from '@/hooks/useCountryDetail';
import { BannerWrapper, SearchBarWrapper, SearchIconWrapper, SearchButton, SearchInputWrapper } from './style';
import Map from '@/components/UI/Map';
import MultiSelect from '@/components/UI/Select/MultiSelect';
import useResize from '@/hooks/useResize';
import { Flex } from '@/layout';

interface CheckBoxProps {
  value: string;
  text: string;
  selected: boolean;
}

interface FormProps {
  acc_coname: string;
  acc_country: string[];
  province: CheckBoxProps[];
  city: CheckBoxProps[];
  lawFirmSectorSpecialty: string[];
  practiceAreas: string[];
}

const CNSearchBar = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();
  const title = t('lawFirmsList');
  const navigate = useNavigate();
  const provinceCheckboxList = t('mapOptions.province', { returnObjects: true });
  const cityCheckboxList = t('mapOptions.city.Guangdong', { returnObjects: true });
  const [open, setOpen] = useState(false);
  const { handleSubmit, control, setValue, watch } = useForm<FormProps>();
  const [provinceList, setProvinceList] = useState<CheckBoxProps[]>();
  const [cityList, setCityList] = useState<CheckBoxProps[]>();
  const [sectorOptions, setSectorOptions] = useState<CheckBoxProps[]>(
    t('mapOptions.lawFirmSectorSpecialty', { returnObjects: true })
  );
  const [areasOptions, setAreasOptions] = useState<CheckBoxProps[]>(
    t('mapOptions.practiceAreas', { returnObjects: true })
  );

  const [showMap, setShowMap] = useState(true);
  const [showSector, setShowSector] = useState(false);
  const [showAreas, setShowAreas] = useState(false);

  useEffect(() => {
    setProvinceList(provinceCheckboxList.data);
    setValue('acc_country', [] as string[]);
    setCityList(t('mapOptions.city.Guangdong.data', { returnObjects: true }));
    setSectorOptions(t('mapOptions.lawFirmSectorSpecialty', { returnObjects: true }));
    setAreasOptions(t('mapOptions.practiceAreas', { returnObjects: true }));
  }, [t]);

  const onSubmit = handleSubmit(async (data) => {
    const provinceQuery = provinceList
      ?.filter((item) => item.selected)
      .map((item) => item.value)
      .join(',');

    const cityQuery = cityList
      ?.filter((item) => item.selected)
      .map((item) => item.value)
      .join(',');
    let queryString = '';
    if (provinceQuery) {
      queryString += `&province=${provinceQuery}`;
    }
    if (cityQuery) {
      queryString += `&city=${cityQuery}`;
    }
    if (data.acc_coname) {
      queryString += `&acc_coname=${data.acc_coname}`;
    }
    if (data.lawFirmSectorSpecialty && data.lawFirmSectorSpecialty.length !== 0) {
      queryString += `&lawFirmSectorSpecialty=${data.lawFirmSectorSpecialty.join(',')}`;
    }
    if (data.practiceAreas && data.practiceAreas.length !== 0) {
      queryString += `&practiceAreas=${data.practiceAreas.join(',')}`;
    }
    setOpen(false);
    navigate(`/lawFirmsList?per_page=10&page=1${queryString}`);
  });

  const openHandler = (type: string) => {
    // 檢查若點擊的按鈕已經是open的, 則收合
    if (
      (open && type === 'map' && showMap) ||
      (open && type === 'sector' && showSector) ||
      (open && type === 'areas' && showAreas)
    ) {
      setOpen(false);
      return;
    }

    setOpen(true);

    // 依照點擊的按鈕, 顯示對應的選項, 其餘隱藏
    switch (type) {
      case 'map':
        setShowMap(true);
        setShowSector(false);
        setShowAreas(false);
        break;
      case 'sector':
        setShowMap(false);
        setShowSector(true);
        setShowAreas(false);
        break;
      case 'areas':
        setShowMap(false);
        setShowSector(false);
        setShowAreas(true);
        break;
    }
  };

  // 點擊畫面中的按鈕會有自動收合的bug, 故拉出額外function呼叫sumbit
  const searchSubmit = () => {
    onSubmit();
  };

  const resetSearch = () => {
    setValue('acc_coname', '');
    setValue('acc_country', [] as string[]);
    setValue('lawFirmSectorSpecialty', [] as string[]);
    setValue('practiceAreas', [] as string[]);
    setProvinceList(provinceCheckboxList.data);
    setCityList(t('mapOptions.city.Guangdong.data', { returnObjects: true }));
    setSectorOptions(t('mapOptions.lawFirmSectorSpecialty', { returnObjects: true }));
    setAreasOptions(t('mapOptions.practiceAreas', { returnObjects: true }));
    navigate(`/lawFirmsList?per_page=10&page=1`);
  };

  const checkBoxHandler = (index: number, list: CheckBoxProps[]) => {
    const current = watch('acc_country');
    const currentLangList = t('mapOptions.province', { returnObjects: true });

    // 確保list的文字不會變成英文
    const translateData = list.map((item, index) => {
      return {
        ...item,
        text: currentLangList.data[index].text,
      };
    });

    const newData = translateData.map((item) => {
      // 補回current中有的省份, 將相同value的資料改為true
      if (current && current.includes(item.value)) {
        return {
          ...item,
          selected: true,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });

    // 若該省份的selected為false, 將其加入acc_country中
    if (!newData[index].selected) {
      if (current) {
        setValue('acc_country', [...current, newData[index].value]);
      } else {
        setValue('acc_country', [newData[index].value]);
      }
    } else {
      // 若該省份的selected為true, 將其從acc_country中移除
      if (current) {
        setValue(
          'acc_country',
          current.filter((item) => item !== newData[index].value)
        );
      }
    }

    // 調整按鈕顯示狀態
    newData[index].selected = !newData[index].selected;
    setProvinceList([...newData]);
  };

  const cityCheckboxHandler = (index: number) => {
    if (!cityList) return;
    cityList[index].selected = !cityList[index].selected;
    setCityList([...cityList]);
    selectChange();
  };

  const sectorCheckboxHandler = (index: number) => {
    sectorOptions[index].selected = !sectorOptions[index].selected;
    setSectorOptions([...sectorOptions]);
    setValue(
      'lawFirmSectorSpecialty',
      sectorOptions.filter((item) => item.selected).map((item) => item.value)
    );
  };

  const areasCheckboxHandler = (index: number) => {
    areasOptions[index].selected = !areasOptions[index].selected;
    setAreasOptions([...areasOptions]);
    setValue(
      'practiceAreas',
      areasOptions.filter((item) => item.selected).map((item) => item.value)
    );
  };

  const selectChange = () => {
    if (!provinceList) return;
    if (!cityList) return;

    const selectedCity = cityList.filter((item) => item.selected).map((item) => item.value);
    const selectedProvince = provinceList.filter((item) => item.selected).map((item) => item.value);
    setValue('acc_country', [...selectedProvince, ...selectedCity]);
  };

  const changeSelected = (name: string, list: CheckBoxProps[]) => {
    const currentProvince = t('mapOptions.province', { returnObjects: true });
    // 透過name去找出對應的text在provinceCheckboxList中的index
    const index = currentProvince.data.findIndex((item) => {
      return item.text === name;
    });
    checkBoxHandler(index, list);
  };

  return (
    <BannerWrapper>
      <div className="mask">
        {provinceList && !isTablet && <Map title={title} changeSelected={changeSelected} provinceList={provinceList} />}
        <SearchBarWrapper>
          <form onSubmit={onSubmit}>
            <Accordion expanded={open} defaultExpanded={false} style={{ maxWidth: 995, width: '95%', margin: 'auto' }}>
              <AccordionSummary aria-label="Expand" style={{ background: 'transparent' }}>
                <SearchBarInputsWrapper>
                  <SearchInputWrapper isTablet={isTablet} isMobile={isMobile}>
                    <div className="inputWrap map">
                      <Controller
                        control={control}
                        defaultValue=""
                        name={'acc_coname'}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            placeholder={t('form.lawFirmsName.placeholder')}
                            variant="outlined"
                            value={value}
                            sx={{ width: '100%', background: '#FFF', borderRadius: '5px' }}
                            onChange={onChange}
                            size="small"
                            error={!!error}
                            helperText={error ? error.message : null}
                            autoComplete="off"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                searchSubmit();
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="inputWrap map" onClick={() => openHandler('map')}>
                      <MultiSelect
                        control={control}
                        variant={'outlined'}
                        size={'small'}
                        data={[...provinceCheckboxList.data, ...cityCheckboxList.data]}
                        inputLabel={t('form.searchLocation.label')}
                        selectLabel={t('form.searchLocation.label')}
                        name={'acc_country'}
                        readOnly={true}
                      />
                    </div>
                    <div className="inputWrap options" onClick={() => openHandler('sector')}>
                      <MultiSelect
                        control={control}
                        variant={'outlined'}
                        size={'small'}
                        data={sectorOptions}
                        inputLabel={t('form.lawFirmSectorSpecialty.label')}
                        selectLabel={t('form.lawFirmSectorSpecialty.label')}
                        name={'lawFirmSectorSpecialty'}
                        readOnly={true}
                      />
                    </div>
                    <div className="inputWrap options" onClick={() => openHandler('areas')}>
                      <MultiSelect
                        control={control}
                        variant={'outlined'}
                        size={'small'}
                        data={areasOptions}
                        inputLabel={t('form.practiceAreas.label')}
                        selectLabel={t('form.practiceAreas.label')}
                        name={'practiceAreas'}
                        readOnly={true}
                      />
                    </div>
                    <Flex gridGap={'5px'} style={{ width: isMobile ? '100%' : 'unset' }}>
                      <IconButton
                        aria-label="search"
                        style={{ padding: '0', margin: '0', width: isMobile ? '50%' : 'unset' }}
                        onClick={(e) => {
                          e.preventDefault();
                          searchSubmit();
                        }}
                      >
                        <SearchIconWrapper isMobile={isMobile}>
                          <img width={32} src="/images/yellowpage/search.svg" alt="" />
                        </SearchIconWrapper>
                      </IconButton>
                      <IconButton
                        aria-label="search"
                        style={{ padding: '0', margin: '0', width: isMobile ? '50%' : 'unset' }}
                        onClick={(e) => {
                          e.preventDefault();
                          resetSearch();
                        }}
                      >
                        <SearchIconWrapper isMobile={isMobile} className="reset">
                          <span>X</span>
                        </SearchIconWrapper>
                      </IconButton>
                    </Flex>
                  </SearchInputWrapper>
                </SearchBarInputsWrapper>
              </AccordionSummary>
              <AccordionDetails>
                {showMap && (
                  <>
                    <Box>
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: 16,
                          lineHeight: '19px',
                          color: '#212121',
                        }}
                      >
                        {t('pages.yellowPage.CNSearchBar.chinaProvince')}
                      </div>

                      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                        {provinceList &&
                          provinceList.map((province, index) => (
                            <FormControlLabel
                              key={province.text + index}
                              control={
                                <Checkbox
                                  sx={{
                                    '&.Mui-checked': {
                                      color: '#7EB442',
                                    },
                                  }}
                                  onChange={() => {
                                    checkBoxHandler(index, provinceList);
                                  }}
                                  checked={province.selected}
                                />
                              }
                              label={<label htmlFor="">{province.text}</label>}
                            />
                          ))}
                      </div>
                    </Box>

                    {cityList && (
                      <>
                        <hr style={{ borderTop: '1px solid #E9E9E9' }} />
                        <Box marginTop={'15px'}>
                          <div
                            style={{
                              fontWeight: 700,
                              fontSize: 16,
                              lineHeight: '19px',
                              color: '#212121',
                            }}
                          >
                            {t('pages.yellowPage.CNSearchBar.cities')}
                          </div>

                          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                            {cityList.map((city, index) => (
                              <FormControlLabel
                                key={city.text + index}
                                control={
                                  <Checkbox
                                    sx={{
                                      '&.Mui-checked': {
                                        color: '#7EB442',
                                      },
                                    }}
                                    onChange={() => {
                                      cityCheckboxHandler(index);
                                    }}
                                    checked={city.selected}
                                  />
                                }
                                label={<label htmlFor="">{city.text}</label>}
                              />
                            ))}
                          </div>
                        </Box>
                      </>
                    )}
                  </>
                )}

                {showSector && (
                  <Box>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '19px',
                        color: '#212121',
                      }}
                    >
                      {t('form.lawFirmSectorSpecialty.label')}
                    </div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                      {sectorOptions.map((sector, index) => (
                        <FormControlLabel
                          key={sector.text + index}
                          control={
                            <Checkbox
                              sx={{
                                '&.Mui-checked': {
                                  color: '#7EB442',
                                },
                              }}
                              onChange={() => {
                                sectorCheckboxHandler(index);
                              }}
                              checked={sector.selected}
                            />
                          }
                          label={<label htmlFor="">{sector.text}</label>}
                        />
                      ))}
                    </div>
                  </Box>
                )}

                {showAreas && (
                  <Box>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '19px',
                        color: '#212121',
                      }}
                    >
                      {t('form.practiceAreas.label')}
                    </div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                      {areasOptions.map((areas, index) => (
                        <FormControlLabel
                          key={areas.text + index}
                          control={
                            <Checkbox
                              sx={{
                                '&.Mui-checked': {
                                  color: '#7EB442',
                                },
                              }}
                              onChange={() => {
                                areasCheckboxHandler(index);
                              }}
                              checked={areas.selected}
                            />
                          }
                          label={<label htmlFor="">{areas.text}</label>}
                        />
                      ))}
                    </div>
                  </Box>
                )}

                <SearchButton
                  style={{ backgroundColor: '#ADD747', cursor: 'pointer', marginTop: 45 }}
                  onClick={(e) => {
                    searchSubmit();
                  }}
                >
                  <img width={32} src="/images/yellowpage/search.svg" alt="" />
                  {t('buttons.search')}
                </SearchButton>
              </AccordionDetails>
            </Accordion>
          </form>
        </SearchBarWrapper>
      </div>
    </BannerWrapper>
  );
};

export default CNSearchBar;

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContainerWrapper, Info, Image, SigninButton, RegistInfo } from './style';
import useCurrentLang from '@/hooks/useCurrentLang';
import useResize from '@/hooks/useResize';

const Banner = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const  lang = useCurrentLang();
  const { isTablet, isMobile } = useResize();

  return (
    <ContainerWrapper isTablet={isTablet} isMobile={isMobile}>
      <Info lang={lang} isTablet={isTablet} isMobile={isMobile}>
        <div>{t('pages.index.Banner.title')}</div>
        <div>{t('pages.index.Banner.description')}</div>
        <div>
          <Link to="/auth/signin">{t('buttons.signIn')}</Link>
          <Link to="/auth/signup">
            <SigninButton>{t('buttons.register')}</SigninButton>
          </Link>
        </div>
      </Info>
      <RegistInfo isTablet={isTablet} isMobile={isMobile}>
        <Link to="/auth/signup">{t('pages.index.Banner.registerButton')}</Link>{t('pages.index.Banner.registerFree')}<Link to="/features">{t('pages.index.Banner.featuresButton')}</Link>.
      </RegistInfo>
      <Image isTablet={isTablet} isMobile={isMobile} />
    </ContainerWrapper>
  );
};

export default Banner;

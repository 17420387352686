import styled from 'styled-components';

const ContainerWrapper = styled.div<{ isTablet: boolean }>`
  width: 100%;
  max-width: 1325px;
  margin: 0 auto;
  padding: 0 0 70px;
  display: flex;
  flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
  gap: 45px;
  padding: ${(p) => (p.isTablet ? '20px 20px 80px' : '0 0 100px')};
`;

const Title = styled.div<{ isTablet: boolean }>`
  flex-basis: ${(p) => (p.isTablet ? 'unset' : '400px')};
  flex-shrink: 0;
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-weight: 700;
  line-height: 70px;
  color: ${(p) => p.theme.colors.gray500};
  text-align: ${(p) => (p.isTablet ? 'center' : 'right')}; ;
`;

const Icon = styled.img`
  width: 90px;
  height: 90px;
  margin-top: 7px;
`;

const CardList = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(p) => (p.isMobile ? '50px' : '70px')};
  flex: 1;

  > a {
    width: 100%;
    display: flex;
    gap: 30px;
    flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
    align-items: center;
  }
`;

const CardInfo = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: ${(p) => (p.isMobile ? 'center' : 'left')};
`;

const CardTitle = styled.div<{ isMobile: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSizes.md : p.theme.fontSizes.lg)};
  font-weight: 700;
  line-height: ${(p) => (p.isMobile ? '20px' : '37.5px')};
  color: ${(p) => p.theme.colors.gray500};
`;

const CardText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 400;
  line-height: 25px;
  color: ${(p) => p.theme.colors.gray400};
`;

export { ContainerWrapper, Title, Icon, CardList, CardInfo, CardTitle, CardText };

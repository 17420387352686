import { useTranslation } from 'react-i18next';
import useResize from '@/hooks/useResize';
import { Link } from 'react-router-dom';
import { ContainerWrapper, Title, Icon, CardList, CardInfo, CardTitle, CardText } from './style';

const OurServices = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();
  const ourserviceLinks = t('pages.index.OurService.data', { returnObjects: true });

  return (
    <>
      <ContainerWrapper isTablet={isTablet}>
        <Title isTablet={isTablet}>{t('portalFeatures')}</Title>
        <CardList isMobile={isMobile}>
          {ourserviceLinks.map((item) => (
            <Link to={item.link} key={item.text}>
              <Icon src={item.icon} />
              <CardInfo isMobile={isMobile}>
                <CardTitle isMobile={isMobile}>{item.title}</CardTitle>
                <CardText>{item.text}</CardText>
              </CardInfo>
            </Link>
          ))}
        </CardList>
      </ContainerWrapper>
    </>
  );
};

export default OurServices;

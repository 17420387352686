import { roleData, country, categoryLang, title } from '@/data/select';

/**
 *
 * @param link
 * @returns
 */
const convertLink = (link: string) => {
  if (!link) {
    return '';
  }

  // 將logo的連結頭部去掉, 改為當前主網域
  // https://dealmakingstoragerpro.blob.core.windows.net
  // https://dealmakingstoragedev.blob.core.windows.net
  if (link.includes('https://dealmakingstoragerpro.blob.core.windows.net')) {
    return '/api/' + link.split('https://dealmakingstoragerpro.blob.core.windows.net/')[1];
  }

  return '/api/' + link.split('https://dealmakingstoragedev.blob.core.windows.net/')[1];
};

/**
 *
 * @param rowData
 * @returns
 */
function convertCNRegionData(rowData: CNReigon[], lang: string) {
  return rowData.map((item) => ({
    value: item.province_zh,
    text: lang === 'en' ? item.province_en : lang === 'tc' ? item.province_tc : item.province_zh,
  }));
}

/**
 *
 * @param rowData
 * @returns
 */
function convertCNCitiesData(rowData: CNReigon[], lang: string, name?: string) {
  if (!name) return [];
  const province = rowData.find((item) => item.province_zh === name);
  if (!province) return [];
  return province.cities.map((item) => ({
    value: item.city_zh,
    text: lang === 'en' ? item.city_en : lang === 'tc' ? item.city_tc : item.city_zh,
  }));
}

/**
 *
 * @param rowData
 * @returns
 */
function convertToSelectData(rowData: Array<string | number>, option?: { multiple?: boolean }) {
  return option?.multiple
    ? rowData.map((data, index) => ({ value: index + 1, text: data }))
    : rowData.map((data) => ({ value: data, text: data }));
}

function convertTitle(rawData: string, currentLang: string) {
  const lang = currentLang === 'en' ? 'en' : currentLang === 'sc' ? 'sc' : 'tc';
  const titleData = title[lang];
  const valueDetail = title['en'];

  const index = valueDetail.findIndex((data) => data === rawData);
  if (index === -1) {
    return titleData[0];
  }
  return titleData[index];
}

function convertCategory(category: string, currentLang: string) {
  const lang = currentLang === 'en' ? 'en' : currentLang === 'sc' ? 'sc' : 'tc';
  const categoryData = categoryLang[lang];
  const valueDetail = categoryLang['en'];
  const otherData = {
    haveOther: false,
    data: '',
  };

  let ary = category.split(', ').map((item) => {
    // 找出item在en語系的index
    const index = valueDetail.findIndex((data) => data === item);
    // 透過index找出當前語系的資料, 若為Other則固定回傳name的值
    if (index === -1 && item !== 'Other') {
      return categoryData[categoryData.length - 1];
    }
    // 若有Other則將haveOther設為true並暫存進OtherData並跳過
    if (item === 'Other') {
      otherData.haveOther = true;
      otherData.data = categoryData[categoryData.length - 1];
      return '';
    }
    return categoryData[index];
  });

  // 若有Other則將OtherData的值加入陣列中
  if (otherData.haveOther) {
    // 加入前先把陣列中的空值過濾掉
    ary = ary.filter((item) => item !== '');
    ary.push(otherData.data);
  }

  return ary.join(', ');
}

function convertCountryCode(countryCode: string) {
  if (!countryCode) return `${country['en'][0].countryCode} (${country['en'][0].name})`;
  const defaultCountry = country['en'].filter((countryDetail: CountryPorps) => {
    // countryDetail.countryCode要去除加號後再作比較
    const code = countryDetail.countryCode.replace('+', '');
    const cleanCountryCode = countryCode.replace('+', '');
    return code === cleanCountryCode;
  });
  return `${defaultCountry[0].countryCode} (${defaultCountry[0].name})`;
}

/**
 * @param rowData
 * @returns
 */
function converToMultiLangSelectData(rowData: MultiLangOptions, currentLang: string) {
  // 透過currentLang找出要求的語系, 並將資料轉換成select的格式, 但value必須為en語系的資料
  const lang = currentLang === 'en' ? 'en' : currentLang === 'sc' ? 'sc' : 'tc';
  return rowData[lang].map((data, index) => ({ value: rowData['en'][index], text: data }));
}

/**
 * 將傳入的國家資料轉換成當前語系的資料
 * @param rawData
 * @returns
 */
function convertToCurrentLangData(rawData: string, type: string, currentLang: string) {
  const lang = currentLang === 'en' ? 'en' : currentLang === 'sc' ? 'sc' : 'tc';
  const countryData = country[lang];
  const valueDetail = country['en'];
  const otherData = {
    haveOther: false,
    data: '',
  };

  if (type === 'name') {
    // 找出item在en語系的index
    const index = valueDetail.findIndex((data) => data.name === rawData);
    // 透過index找出當前語系的資料, 若為Other則固定回傳name的值
    if (index === -1 && rawData !== 'Other') {
      return countryData[countryData.length - 1].name;
    }

    // 若有Other則將haveOther設為true並暫存進OtherData並跳過
    if (rawData === 'Other') {
      return countryData[countryData.length - 1].name;
    }

    return countryData[index].name;
  }

  if (type === 'language') {
    let ary = rawData.split(', ').map((item) => {
      // 找出item在en語系的index
      const index = valueDetail.findIndex((data) => data.language === item);
      // 透過index找出當前語系的資料, 若為Other則固定回傳name的值
      if (index === -1 && item !== 'Other') {
        return countryData[countryData.length - 1].name;
      }
      // 若有Other則將haveOther設為true並暫存進OtherData並跳過
      if (item === 'Other') {
        otherData.haveOther = true;
        otherData.data = countryData[countryData.length - 1].name;
        return '';
      }
      return countryData[index].language;
    });

    // 若有Other則將OtherData的值加入陣列中
    if (otherData.haveOther) {
      // 加入前先把陣列中的空值過濾掉
      ary = ary.filter((item) => item !== '');
      ary.push(otherData.data);
    }

    return ary.join(', ');
  }

  if (type === 'currency') {
    let ary = rawData.split(', ').map((item) => {
      // 找出item在en語系的index
      const index = valueDetail.findIndex((data) => data.currency === item);
      // 透過index找出當前語系的資料, 若為Other則固定回傳name的值
      if (index === -1 && item !== 'Other') {
        return countryData[countryData.length - 1].name;
      }
      // 若有Other則將haveOther設為true並暫存進OtherData並跳過
      if (item === 'Other') {
        otherData.haveOther = true;
        otherData.data = countryData[countryData.length - 1].name;
        return '';
      }
      return countryData[index].currency;
    });

    // 若有Other則將OtherData的值加入陣列中
    if (otherData.haveOther) {
      // 加入前先把陣列中的空值過濾掉
      ary = ary.filter((item) => item !== '');
      ary.push(otherData.data);
    }

    return ary.join(', ');
  }

  return rawData;
}

/**
 * 將資料轉換成checkbox的格式
 * @param rowData
 * @returns
 */
function convertToCheckboxData(rowData: Array<string | number>) {
  return rowData.map((data, index) => ({ value: index + 1, text: data, checked: false }));
}

const convertToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(error);
  });
};

const convertTiming = (time: string) => {
  // 轉換並對比距離當前時間多久
  const now = new Date();
  const date = new Date(time);
  const diff = now.getTime() - date.getTime();
  const days = Math.floor(diff / (24 * 60 * 60 * 1000));
  const hours = Math.floor(diff / (60 * 60 * 1000));
  const mins = Math.floor(diff / (60 * 1000));
  const secs = Math.floor(diff / 1000);

  if (days > 0) {
    return `${days} days ago`;
  }
  if (hours > 0) {
    return `${hours} hours ago`;
  }
  if (mins > 0) {
    return `${mins} mins ago`;
  }
  if (secs > 0) {
    return `${secs} secs ago`;
  }
  return '';
};

/**
 *
 * @param str 接受的格式為 1,2,4,6 的字串
 * @returns
 */
const convertToNumberArray = (str: string) =>
  str
    .replace(',', '')
    .split('')
    .map((s) => Number(s));

const convertToTimeDifference = (time: string) => {
  const data = new Date(time);
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - data.getTime();

  const hours = Math.floor(timeDifference / 1000 / 60 / 60);
  const minutes = Math.floor((timeDifference / 1000 / 60) % 60);

  let result = '';
  if (hours > 0) {
    result += `${hours}h`;
  }
  if (minutes > 0) {
    result += ` ${minutes}m`;
  }
  return `${result || 'seconds'} ago`;
};

const convertToFileSize = (size: number) => {
  // 轉換檔案大小, 從GB開始轉換, 若小於1GB則顯示MB
  // 若小於1MB則顯示KB
  if (!size) return '0 KB';

  const gb = size / 1024 / 1024 / 1024;
  const mb = size / 1024 / 1024;

  if (gb < 1) {
    // 若小於1GB則顯示MB
    if (mb < 1) {
      // 若小於1MB則顯示KB
      const kb = size / 1024;
      return kb.toFixed(2) + ' KB';
    } else {
      return mb.toFixed(2) + ' MB';
    }
  } else {
    return gb.toFixed(2) + ' GB';
  }
};

const convertToData = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat('zh-TW', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }).format(date);
  return formattedDate;
};

const convertToRole = (id: number, dealTypeId: number) => {
  const roleText = roleData[dealTypeId].find((role) => role.value === Number(id))?.text;
  return roleText || '';
};

const convertToBadgeLevel = (verification: {
  creditReport: boolean;
  eCert: boolean;
  identification: boolean;
  person: boolean;
  supplemental: boolean;
}) => {
  return Object.values(verification)
    .reduce((acc, cur) => acc + Number(cur === true), 0)
    .toString();
};

const convertNotification = (data: WSNotification[]) => {
  const filteredArray = data.filter((item) => item.type !== '1' && item.type !== '90');

  const convertData: NotificationCards[] = filteredArray.map((item) => {
    const title =
      item.type === '0'
        ? 'Deal Participant invitation'
        : item.type === '1'
        ? 'New messages'
        : item.type === '2'
        ? `Meeting ${item.message}`
        : item.type === '4'
        ? 'File translating'
        : 'A transaction requires a signature';
    const desc =
      item.type === '0'
        ? `from ${item.fromUser?.acc_coname} - ${item.fromUser?.acc_firstName} ${item.fromUser?.acc_lastName}`
        : item.type === '1'
        ? `${item.fromUser?.acc_firstName} ${item.fromUser?.acc_lastName}: ${item.message}`
        : item.type === '2'
        ? `from ${item.fromUser?.acc_coname} - ${item.fromUser?.acc_firstName} ${item.fromUser?.acc_lastName}`
        : item.type === '4'
        ? item.message
        : `from ${item.fromUser?.acc_coname} - ${item.fromUser?.acc_firstName} ${item.fromUser?.acc_lastName}`;
    const link =
      item.type === '0'
        ? `/deal/${item.reference_id}/details`
        : item.type === '1'
        ? ''
        : item.type === '2'
        ? '/event'
        : item.type === '4'
        ? '/docLibrary'
        : `/deal/${item.reference_id}`;

    return {
      id: item.id,
      title,
      desc,
      link,
      status: item.status,
      time: convertTiming(item.created_at),
    };
  });

  return convertData;
};

interface LEIOptions {
  code: string;
  country: string;
  textZh: string;
  textEn: string;
  textTw: string;
  textOri: string;
  status: number;
}

interface LEILocations {
  code: string;
  parentCode: string;
  textEn: string;
  textZh: string;
  textTw: string;
  textOri: string;
}

interface LegalForm {
  code: string;
  country: string;
  countryName: string;
  language: string;
  textEn: string;
  textOri: string;
  textZh: string;
  textTw: string;
  status: number;
}

const convertLEIOptions = (lang: string, options?: LEIOptions[]) => {
  if (!options) return [];
  const filteredAry = options.filter((data) => data.status === 1);
  const ary = filteredAry.map((data, index) => ({
    value: data.code,
    text:
      lang === 'sc' && data.textZh
        ? data.textZh
        : lang === 'tc' && data.textTw
        ? data.textTw
        : lang === 'en' && data.textEn
        ? data.textEn
        : data.textOri,
  }));

  return ary.filter((item, index, self) => self.findIndex((t) => t.text !== '' && t.value === item.value) === index);
};

const convertRAInformation = (lang: string, options?: LEIOptions[], country?: string) => {
  if (!options) return [];
  if (!country) return [];

  // 找出在options中對應的country
  const filteredOptions = options.filter((data) => data.country === country && data.status === 1);

  const ary = filteredOptions.map((data, index) => ({
    value: data.code,
    text:
      lang === 'sc' && data.textZh
        ? data.textZh
        : lang === 'tc' && data.textTw
        ? data.textTw
        : lang === 'en' && data.textEn
        ? data.textEn
        : data.textOri,
  }));

  return ary.filter((item, index, self) => self.findIndex((t) => t.text !== '' && t.value === item.value) === index);
};

const convertLocations = (lang: string, options?: LEILocations[]) => {
  if (!options) return [];

  // 僅限定下列國家是可以選擇的
  const allowCountry = [
    'CN',
    'HK',
    'MO',
    'TW',
    'MH',
    'GB',
    'VU',
    'AU',
    'NZ',
    'CH',
    'KH',
    'MY',
    'VG',
    'KY',
    'SG',
    'SC',
    'RU',
  ];

  // const allowCountry = ['CN', 'HK', 'MO'];

  // 找出在options中對應的code
  const filteredOptions = options.filter((data) => allowCountry.includes(data.code));

  const sortOptions = filteredOptions.map((data, index) => ({
    value: data.code,
    text:
      lang === 'sc' && data.textZh
        ? data.textZh
        : lang === 'tc' && data.textTw
        ? data.textTw
        : lang === 'en' && data.textEn
        ? data.textEn
        : data.textOri,
  }));

  // 將中國的資料放在最前面, 香港的資料放在第二個 澳門的資料放在第三個
  const chinaData = sortOptions.filter((data) => data.value === 'CN');
  const hongKongData = sortOptions.filter((data) => data.value === 'HK');
  const macauData = sortOptions.filter((data) => data.value === 'MO');
  const otherData = sortOptions.filter((data) => data.value !== 'CN' && data.value !== 'HK' && data.value !== 'MO');

  return [...chinaData, ...hongKongData, ...macauData, ...otherData];
};

const convertLEICountryCode = (options?: LEILocations[]) => {
  if (!options) return [];
  // 僅限定下列國家是可以選擇的
  const allowCountry = [
    'CN',
    'HK',
    'MO',
    'TW',
    'MH',
    'GB',
    'VU',
    'AU',
    'NZ',
    'CH',
    'KH',
    'MY',
    'VG',
    'KY',
    'SG',
    'SC',
    'RU',
  ];

  // 轉換allowCountry的資料成英文原名
  const countryData = allowCountry.map((data) => {
    const country = options.find((item) => item.code === data);
    return country?.textEn;
  });

  // 從country['en']的資料取出name相同的國家資料後將countryCode並轉換成select的格式
  const ary = countryData.map((item, index) => {
    const enData = country['en'].find((data) => {
      if (item === 'British Virgin Islands') {
        return data.name === 'Virgin Islands, British';
      }
      return data.name === item;
    });

    return {
      value: enData ? enData.countryCode : '',
      text: enData ? enData.countryCode : '',
    };
  });

  return ary;
};

const convertChinaArea = (lang: string, options?: LEILocations[]) => {
  if (!options) return [];
  // 返回options中的前34筆資料
  const provinceData = options.slice(1, 35);
  return provinceData.map((data) => ({
    value: data.code,
    text:
      lang === 'sc' && data.textZh
        ? data.textZh
        : lang === 'tc' && data.textTw
        ? data.textTw
        : lang === 'en' && data.textEn
        ? data.textEn
        : data.textOri,
  }));
};

const convertChinaCity = (lang: string, parentCode?: string, options?: LEILocations[]) => {
  if (!parentCode) return [];
  if (!options) return [];
  // 找出parentCode的資料
  const cityData = options.slice(3000, options.length - 1);
  const city = cityData.filter((data) => data.parentCode === parentCode);
  return city.map((data) => ({
    value: data.code,
    text:
      lang === 'sc' && data.textZh
        ? data.textZh
        : lang === 'tc' && data.textTw
        ? data.textTw
        : lang === 'en' && data.textEn
        ? data.textEn
        : data.textOri,
  }));
};

const convertLegalFormCountry = (lang: string, options?: LegalForm[]) => {
  if (!options) return [];
  // 將轉換的結果中重複的語言過濾掉
  const ary = options.map((data, index) => ({ value: data.countryName, text: data.countryName }));
  const result = ary.filter(
    (item, index, self) => self.findIndex((t) => t.value !== '' && t.value === item.value) === index
  );
  return result;
};

const convertLegalFormLanguage = (options?: LegalForm[], countryCode?: string) => {
  if (!options) return [];
  if (!countryCode) return [];
  // 找出在options中對應的country
  const filteredOptions = options.filter((data) => data.country === countryCode);

  // 將轉換的結果中重複的語言過濾掉
  const ary = filteredOptions.map((data, index) => ({ value: data.language, text: data.language }));

  const result = ary.filter(
    (item, index, self) => self.findIndex((t) => t.value !== '' && t.value === item.value) === index
  );

  return result;
};

const convertLegalFormCode = (lang: string, options?: LegalForm[], countryCode?: string, formLang?: string) => {
  if (!options) return [];
  if (!formLang) return [];
  if (!countryCode) return [];
  // 找出在options中對應的country
  const filteredOptions = options.filter(
    (data) => data.status === 1 && data.country === countryCode && data.language === formLang
  );

  const ary = filteredOptions.map((data, index) => ({
    value: data.code,
    text:
      lang === 'sc' && data.textZh
        ? data.textZh
        : lang === 'tc' && data.textTw
        ? data.textTw
        : lang === 'en' && data.textEn
        ? data.textEn
        : data.textOri,
  }));
  const result = ary.filter(
    (item, index, self) => self.findIndex((t) => t.text !== '' && t.value === item.value) === index
  );

  return result;
};

interface LEIOptionsType {
  type:
    | 'EntityLegalCategory'
    | 'Country'
    | 'ChinaArea'
    | 'NoRelationshipReason'
    | 'CheckFileType'
    | 'CriterionType'
    | 'RAInformation'
    | 'LegalForm'
    | 'LegalStatus'
    | 'LegalForm'
    | 'LegalStatus'
    | 'ProcessStatus'
    | 'RegisterStatus'
    | 'Language'
    | 'IDType'
    | 'LEIPurpose';
}

const convertOptionCode = (lang: string, code: string, type: LEIOptionsType['type'], options: LEIResponse) => {
  if(!options) return;
  // 將code轉換成對應的語系的text
  const data = options[type].find((item) => item.code === code);
  if (!data) return '';
  return lang === 'sc' && data.textZh
    ? data.textZh
    : lang === 'tc' && data.textTw
    ? data.textTw
    : lang === 'en' && data.textEn
    ? data.textEn
    : data.textOri;
};

export {
  convertToSelectData,
  convertToCheckboxData,
  convertToBase64,
  convertToNumberArray,
  convertToTimeDifference,
  convertToFileSize,
  convertToData,
  convertToRole,
  convertToBadgeLevel,
  convertTiming,
  convertNotification,
  convertLink,
  convertLEIOptions,
  convertLocations,
  convertLegalFormLanguage,
  convertLegalFormCode,
  convertLegalFormCountry,
  converToMultiLangSelectData,
  convertToCurrentLangData,
  convertCountryCode,
  convertCategory,
  convertTitle,
  convertChinaArea,
  convertChinaCity,
  convertOptionCode,
  convertCNRegionData,
  convertCNCitiesData,
  convertRAInformation,
  convertLEICountryCode,
};

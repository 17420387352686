import { axios } from '@/utils/axios';

// api有問題暫時看不到資料, 待後端確認
/**
 * ANCHOR 取得列表
 */
export const getCompanyCategory = async () => {
  return axios.get('/api/companyService');
};

/**
 * ANCHOR 上傳
 */
export const storeCompanyCategory = async () => {
  return axios.post('/api/companyService');
};

/**
 * ANCHOR 更新
 */
export const updateCompanyCategory = async (id: number, data: []) => {
  return axios.put(`/api/companyService/${id}`, data);
};

/**
 * ANCHOR 刪除
 */
export const DestroyCompanyCategory = async (id: number) => {
  return axios.delete(`/api/companyService/${id}`);
};

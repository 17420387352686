import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

import PageContainer from '@/components/Container/PageContainer';
import SearchBar from './SearchBar';
import List from './List';

import { getYellowPageSearch } from '@/api/auth.api';
import CNSearchBar from './CNSearchBar';
import { LoadingPage } from '@/layout';

const YellowPage = ({ type = 'YellowPage' }: { type: 'YellowPage' | 'LawFirmsList' }) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showData, setShowData] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [resData, setResData] = useState<any>();
  const [recommendData, setRecommendData] = useState<any>();

  useEffect(() => {
    if (type === 'YellowPage') {
      searchParams.get('page') === null && navigate('/eDirectory?page=1&per_page=10');
    } else if (type === 'LawFirmsList') {
      searchParams.get('page') === null && navigate('/lawFirmsList?page=1&per_page=10');
    }

    getData();
    // if (searchParams.get('page') !== null) {
    // } else {
    //   getRecommendData();
    // }
  }, [location.search, t]);

  const getData = async () => {
    if (searchParams.get('page') === null) return;
    const currentPage = searchParams.get('page');
    const currentPerPage = searchParams.get('per_page');
    if (currentPage) {
      setPage(parseInt(currentPage));
    }
    if (currentPerPage) {
      setPerPage(parseInt(currentPerPage));
    }

    if (type === 'YellowPage') {
      try {
        setIsLoading(true);
        const res: YellowPageResponse = (await getYellowPageSearch(location.search)).data;
        if (res.success) {
          setResData(res.data);
          setTotalData(res.data.total);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } else if (type === 'LawFirmsList') {
      const queryString = '&category=Legal Services';
      try {
        setIsLoading(true);
        const res: YellowPageResponse = (await getYellowPageSearch(location.search + queryString)).data;
        if (res.success) {
          setResData(res.data);
          setTotalData(res.data.total);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getRecommendData = async () => {
    // 初次進入的預設值放入網址列
    searchParams.set('page', '1');
    searchParams.set('per_page', '10');
    try {
      const res = (await getYellowPageSearch(`?per_page=${perPage}&page=${page}`)).data;

      if (res.success) {
        setRecommendData(res.data);
      }
    } catch (error) {}
  };

  return (
    <PageContainer title={'e-Directory'}>
      <LoadingPage isLoading={isLoading} />
      {type === 'YellowPage' ? <SearchBar /> : <CNSearchBar />}
      <List
        showData={showData}
        data={resData?.data}
        recommendData={recommendData?.data}
        setPage={setPage}
        totalData={totalData}
        perPage={perPage}
        page={page}
        lastPage={resData?.last_page}
        total={resData?.total}
        type={type}
      />
    </PageContainer>
  );
};

export default YellowPage;

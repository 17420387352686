import { useEffect, useState } from 'react';
import { Button } from '@/components/UI/Button';
import { H2 } from '@/components/UI/Typography/Heading';
import { Box, Modal, Button as DefaultBtn } from '@mui/material';
import {TCTermsMarkdown, PICSTermsMarkdown} from '@/components/UI/Terms';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useResize from '@/hooks/useResize';
import { Flex } from '@/layout';

interface openState {
  infoCollect: boolean;
  terms: boolean;
}

interface ModalProps {
  open: openState;
  handleClose: (key: string) => void;
  handleChange: (key: string, current: boolean) => void;
}

const StyledH2 = styled(H2)<{ isMobile: boolean }>`
  font-size: ${(p) => (p.isMobile ? p.theme.fontSizes.md : p.theme.fontSizes.lg)};
`;

export default function TermsModal({ open, handleClose, handleChange }: ModalProps) {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile } = useResize();
  const [personalInfo, setPersonalInfo] = useState(true);
  const [termsInfo, setTermsInfo] = useState(true);
  const personalHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { currentTarget } = e;

    if (currentTarget.scrollHeight - currentTarget.scrollTop <= currentTarget.clientHeight + 2) {
      setPersonalInfo(false);
    }
  };

  const termHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { currentTarget } = e;

    if (currentTarget.scrollHeight - currentTarget.scrollTop <= currentTarget.clientHeight + 2) {
      setTermsInfo(false);
    }
  };

  const [TC, setTC] = useState('');
  const [PICS, setPICS] = useState('');

  useEffect(() => {
    fetch(TCTermsMarkdown)
      .then((res) => res.text())
      .then((md) => {
        setTC(md);
      });

    fetch(PICSTermsMarkdown)
      .then((res) => res.text())
      .then((md) => {
        setPICS(md);
      });
  },[]);

  return (
    <>
      <Modal
        open={open.infoCollect}
        onClose={() => handleClose('infoCollect')}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEnforceFocus
        sx={{ display: 'flex' }}
      >
        <div
          style={{
            margin: 'auto',
            width: '100%',
            maxWidth: '930px',
            backgroundColor: '#fff',
            padding: isMobile ? '15px 25px' : '30px 50px',
            borderRadius: '40px 40px 40px 0px',
          }}
        >
          <StyledH2 isMobile={isMobile}>{t('pages.auth.signUp.modal.terms.pic.title')}</StyledH2>
          <span style={{ font: 'Roboto 400 16px', lineHeight: '30px', color: 'red' }}>
            {t('pages.auth.signUp.modal.terms.pic.content')}
          </span>
          <div
            className="content"
            style={{
              marginTop: '25px',
              height: '40vh',
              overflowY: 'auto',
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: isMobile ? '16px' : '20px',
              color: '#616161',
              lineHeight: '30px',
            }}
            onScroll={personalHandler}
          >
            <ReactMarkdown children={PICS} remarkPlugins={[remarkGfm]} />
          </div>
          <Flex
            gridGap={isMobile ? 20 : 40}
            marginTop={'28px'}
            alignItems={'center'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Button
              width={isMobile ? '100%' : 'unset'}
              onClick={() => handleChange('infoCollect', personalInfo)}
              size={'lg'}
              variant={'primary'}
              rounded={false}
              disabled={personalInfo}
            >
              {t('buttons.agree')}
            </Button>
            <Button
              width={isMobile ? '100%' : 'unset'}
              color="primary"
              onClick={() => handleClose('infoCollect')}
              style={{ textDecoration: 'underline' }}
            >
              {t('buttons.back')}
            </Button>
          </Flex>
        </div>
      </Modal>

      <Modal
        open={open.terms}
        onClose={() => handleClose('terms')}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEnforceFocus
        sx={{ display: 'flex' }}
      >
        <div
          style={{
            margin: 'auto',
            width: '100%',
            maxWidth: '930px',
            backgroundColor: '#fff',
            padding: isMobile ? '15px 25px' : '30px 50px',
            borderRadius: '40px 40px 40px 0px',
          }}
        >
          <StyledH2 isMobile={isMobile}>{t('pages.auth.signUp.modal.terms.t&c.title')}</StyledH2>
          <span style={{ font: 'Roboto 400 16px', lineHeight: '30px', color: 'red' }}>
            {t('pages.auth.signUp.modal.terms.t&c.title')}
          </span>
          <div
            className="content"
            style={{
              marginTop: '25px',
              height: '40vh',
              overflowY: 'auto',
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: isMobile ? '16px' : '20px',
              color: '#616161',
              lineHeight: '30px',
            }}
            onScroll={termHandler}
          >
            <ReactMarkdown children={TC} remarkPlugins={[remarkGfm]} />
          </div>
          <Flex
            gridGap={isMobile ? 20 : 40}
            marginTop={'28px'}
            alignItems={'center'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Button
              width={isMobile ? '100%' : 'unset'}
              onClick={() => handleChange('terms', termsInfo)}
              size={'lg'}
              variant={'primary'}
              rounded={false}
              disabled={termsInfo}
            >
              {t('buttons.agree')}
            </Button>
            <Button
              width={isMobile ? '100%' : 'unset'}
              color="primary"
              onClick={() => handleClose('terms')}
              style={{ textDecoration: 'underline' }}
            >
              {t('buttons.back')}
            </Button>
          </Flex>
        </div>
      </Modal>
    </>
  );
}

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Banner from './components/Banner';
import OurServices from './components/OurServices';
import Footer from './Footer';
import Steps from './Services/Steps';
import { GoTop } from './style';
import useResize from '@/hooks/useResize';
import { Box } from '@/layout';
import StrategicPartners from './StrategicPartners';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector, switchLayout } from '@/redux/auth/auth.slice';
import { useDispatch } from 'react-redux';

const Index = () => {
  // @ts-ignore
  const { t, i18n } = useTranslation();
  const { isTablet, isMobile } = useResize();
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAppSelector(getAuthSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams.get('lang')) {
      // 驗證語系是否在支援的語系內, 若不在則預設為i18n的語系
      const lang = ['sc', 'tc', 'en'].includes(searchParams.get('lang') as string)
        ? searchParams.get('lang')
        : i18n.language;
      i18n.changeLanguage(lang!);
    }
  }, []);

  useEffect(() => {
    // 檢測是否為guest帳號, 若login為true且accessToken為空, 則將login設為false
    if (!auth.isLogin) return;
    dispatch(switchLayout(false));
  }, [auth, dispatch]);

  return (
    <Box position={'relative'} zIndex={0}>
      <Banner />
      <div style={{ paddingTop: isMobile ? 150 : isTablet ? 200 : 50 }}>
        <Steps />
      </div>
      <OurServices />
      <StrategicPartners />
      <Footer />
      <GoTop
        isTablet={isTablet}
        isMobile={isMobile}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        {t('top')}
      </GoTop>
    </Box>
  );
};

export default Index;

const categorySc = [
  "农业",
  "建筑工程",
  "教育",
  "电子",
  "能源",
  "娱乐",
  "金融及保险",
  "医疗保健",
  "酒店及餐饮",
  "进口/出口贸易及批发",
  "信息科技",
  "机械设备",
  "制造业",
  "媒体及传播",
  "采矿和挖掘",
  "制药",
  "专业或商业服务",
  "房地产",
  "零售",
  "社会及个人服务",
  "纺织及制衣",
  "仓库及速递服务业",
  "公用事业",
  "废弃物管理",
  "法律服务",
  "其他"
];

export default categorySc;

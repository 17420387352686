import { axios } from '@/utils/axios';
import filenameValidator from '@/utils/filenameValidator';

export const getDealList = () => {
  return axios.get<{ data: DealListResponses[] }>('/api/deal/getDealList');
};

export const getDealDetail = (deal_id: string) => {
  return axios.get<{ data: DealDetail }>(`/api/deal/getDeal/${deal_id}`);
};

export const dealInitiationStep1 = (data: DealStep1FormRequest) => {
  return axios.post<DealStep1FormResponse>('/api/deal/step1', data);
};

// export const dealInitiationStep2 = (data: DealStep2FormFields) => {
//   return axios.post<DealStep1FormResponse>('/api/deal/step2', data);
// };

export const dealInitiationStep3 = (data: { deal_id: string }) => {
  return axios.post('/api/deal/step3', data);
};

export const dealInitiationStep4 = (data: { deal_id: string }) => {
  return axios.post('/api/deal/step4', data);
};

export const getDealCompanyList = (query?: string) => {
  const url = `/api/deal/getConpanies${query ? `?keyword=${query}` : ''}`;
  return axios.get<{ data: CompanyDetail[] }>(url);
};

export const inviteCompany = (data: { acc_id: string; deal_id: string }) => {
  return axios.post<{ data: { company: DealInvitedCompany[]; personal: DealInvitedPersonal[] } }>(
    '/api/deal/inviteCompany',
    data
  );
};

export const getDealPersonal = () => {
  return axios.get<{ data: PersonalDetail[] }>('/api/deal/getPersonals');
};

export const invitePersonal = (data: { acc_id: string; deal_id: string }) => {
  return axios.post<{ data: { company: DealInvitedCompany[]; personal: DealInvitedPersonal[] } }>(
    '/api/deal/invitePersonal',
    data
  );
};

export const getInvitedParticipants = (id: string) => {
  return axios.get<{ data: { company: DealInvitedCompany[]; personal: DealInvitedPersonal[] } }>(
    `/api/deal/getDealParticipants/${id}`
  );
};

export const removeParticipant = (data: { acc_id: string; deal_id: string }) => {
  return axios.post<{ data: { company: DealInvitedCompany[]; personal: DealInvitedPersonal[] } }>(
    '/api/deal/removeParticipant',
    data
  );
};

export const getInvitedNotification = () => {
  return axios.get<{ data: DealNotification[] }>('/api/deal/getInviteNotifications');
};

export const acceptInvite = (deal_id: string) => {
  return axios.post<{ data: RespondInviteResponse }>(`/api/deal/acceptInvite/${deal_id}`);
};

export const rejectInvite = (deal_id: string) => {
  return axios.post<{ data: RespondInviteResponse }>(`/api/deal/rejectInvite/${deal_id}`);
};

// 展示用
export const getQrcodeUrl = (role: string) => {
  if (role === 'cai') {
    return axios.get('/api/eSign/myfacesign/getAsign');
  } else {
    return axios.get('/api/eSign/myfacesign/getBsign');
  }
};

interface faceSign {
  dealId: string;
  fileId: string;
  dealRole: string;
}

export const faceSignInit = () => {
  return axios.post('/api/eSign/myfacesign/init');
};

export const startSign = (data: faceSign) => {
  return axios.post('/api/eSign/myfacesign/startSign', data);
};

export const getDocTemplate = (deal_id: string) => {
  return axios.get<{ data: DocTemplate }>(`/api/template?deal_id=${deal_id}`);
};

export const getDocLibrary = () => {
  return axios.get<{ data: DocLibrary[] }>('/api/document');
};

export const getDealFileList = (deal_id: string) => {
  return axios.get<{ data: DocLibrary[] }>(`/api/deal/getDealFileList/${deal_id}`);
};

export const getDealFileDetail = (file_id: string) => {
  return axios.get<{ data: DocLibrary[] }>(`/api/deal/getDealFileList/${file_id}`);
};

export const addFileToDeal = (data: DealCopyFileRequest) => {
  const formData = new FormData();
  formData.append('deal_id', data.deal_id);
  for (const [index, iterator] of Object.entries(data.files)) {
    formData.append(`files[${index}]`, iterator, filenameValidator(iterator));
  }
  return axios.post<{ data: DocLibrary[] }>('/api/deal/addFileToDeal', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const copyFileToDeal = (data: { deal_id: string; document_library_id: string }) => {
  return axios.post<{ data: DocLibrary[] }>('/api/deal/copyFileToDeal', data);
};

export const deleteFileFromDeal = (data: { deal_id: string; deal_document_id: string }) => {
  return axios.post<{ data: DocLibrary[] }>('/api/deal/deleteFileToDeal', data);
};

export const getaccessToken = () => {
  return axios.post('/api/eSign/myfacesign/getAccessToken');
};

export const getExecutionList = (deal_id: string) => {
  return axios.get(`/api/deal/${deal_id}/execution`);
};

export const addExecutionFile = (data: { name: string }, deal_id: string) => {
  return axios.post(`/api/deal/${deal_id}/execution`, data);
};

export const addNewItem = (data: AddNewItemRequest, deal_id: string) => {
  const formData = new FormData();
  formData.append('file', data.file, filenameValidator(data.file));
  formData.append('doc_type', data.doc_type);

  return axios.post(`/api/deal/${deal_id}/execution/add`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const uploadExecutionFile = (data: ExecutionFileRequest, deal_id: string) => {
  const formData = new FormData();
  formData.append('file', data.file, filenameValidator(data.file));
  formData.append('execution_id', data.executionId);

  return axios.post(`/api/deal/${deal_id}/execution/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deleteExecutionFile = (data: { execution_id: string }, deal_id: string) => {
  return axios.post(`/api/deal/${deal_id}/execution/delete`, data);
};

export const checkExecutionFile = (data: { execution_id: string }, deal_id: string) => {
  return axios.post(`/api/deal/${deal_id}/execution/check`, data);
};

export const signFile = (data: { dealId: string; fileId: string }) => {
  return axios.get(`/api/eSign/signFile?dealId=${data.dealId}&fileId=${data.fileId}`);
};

export const InviteToSign = (data: { dealId: string; fileId: string }) => {
  return axios.post(`/api/eSign/inviteToSign`, data);
};

export const ratingToDeal = (data: { deal_id: string; score: number; comment: string }) => {
  return axios.post(`/api/deal/ratingToDeal`, data);
};

export const cancelDeal = (data: { deal_id: string }) => {
  return axios.post(`/api/deal/cancelDeal`, data);
};

export const completedDealExecution = (deal_id: string) => {
  return axios.post(`/api/deal/execution/${deal_id}`);
};

export const getNeedSignFileList = (deal_id: string) => {
  return axios.get(`/api/deal/getNeedSignFileList/${deal_id}`);
};

export const inviteSendEmail = (data: { deal_id: string; email: string }) => {
  return axios.post(`/api/deal/inviteSendEmail`, data);
};

export const startESign = (data: { dealId: string; fileId: string; dealRole: string }) => {
  return axios.post(`/api/eSign/ebram/startSign`, data);
};

export const uploadP12 = (data: P12Request) => {
  const formData = new FormData();
  formData.append('P12', data.P12, filenameValidator(data.P12));
  formData.append('P12Password', data.P12Password);
  formData.append('dealId', data.dealId);
  formData.append('fileId', data.fileId);
  formData.append('dealRole', data.dealRole);

  return axios.post(`/api/eSign/myfacesign/uploadP12`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const requestConfirmCompletion = (deal_id: string, data?: { isAccept: boolean }) => {
  return axios.post(`/api/deal/${deal_id}/execution/requestConfirmCompletion`, data);
};

export const checklsCanSign = (data: { dealId: string; fileId: string }) => {
  return axios.post<{ success: boolean; message: string }>(`/api/eSign/myfacesign/checkIsCanSign`, data);
};

export const cancelToSign = (data: { dealId: string; fileId: number; cancelSignReason: string }) => {
  return axios.post(`/api/eSign/cancelToSign`, data);
};

// 取得交易數量與各項交易的進度
export const getDealStatistical = async () => {
  return axios.get('/api/deal/statistical');
};

export const getTemplate = async (dealID: string) => {
  return axios.get<{ data: { other: ContractTemplatesListProps[]; recommend: ContractTemplatesListProps[] } }>(
    `/api/template?deal_id=${dealID}`
  );
};

export const verifyDocument = async (data: { file: File; deal_id: string; file_id: string }) => {
  const formData = new FormData();
  formData.append('file', data.file, filenameValidator(data.file));
  formData.append('deal_id', data.deal_id);
  formData.append('file_id', data.file_id);

  return axios.post('/api/deal/verifyDocument', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

// 邀請非平台用戶(guest role)
export const inviteNoneDMPUser = async (data: inviteNoneDMPUserRequest) => {
  return axios.post(`/api/deal/inviteNoneDMPUser`, data);
};

// 邀請非平台用戶進行簽名
export const noneDMPUserInviteToSign = async (data: { dealId: string; fileId: string }) => {
  return axios.post(`/api/eSign/noneDMPUser/inviteToSign`, data);
};

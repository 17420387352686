import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getDocument, storeDocument } from '@/api/document.api';
import { errorHandler } from '@/utils/toastHandler';
import PageContainer from '@/components/Container/PageContainer';

import DocumentList from '@/components/DocLibrary/DocumentList';
import { DropZone, UploadFileList } from '@/components/UI/FileDropzone';
import { Flex, LoadingPage } from '@/layout';
import { Button } from '@/components/UI/Button';
import { successHandler } from '@/utils/toastHandler';
import Loading from '@/components/UI/Loading';
import { extensionValidator } from '@/utils/extensionValidator';
import { StorageBar } from '@/components/UI/StorageBar';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';

const DocLibrary = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const [isDropActive, setIsDropActive] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDocumentStorageAlmostFullModal, setShowDocumentStorageAlmostFullModal] = useState<boolean>(false);
  const [showDocumentStorageFullModal, setShowDocumentStorageFullModal] = useState<boolean>(false);

  const onDragStateChange = useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive);
  }, []);

  const onFilesDrop = useCallback((files: File[]) => {
    setFiles(files);
  }, []);

  const onUploadFile = async () => {
    setIsLoading(true);
    try {
      if (!extensionValidator(files)) {
        errorHandler(t('error.extension'));
        return;
      }
      // 驗證檔案大小, 若超過100MB則不允許上傳
      const totalSize = files.reduce((acc, file) => acc + file.size, 0);
      if (totalSize > 100 * 1024 * 1024) {
        errorHandler(t('error.fileSize'));
        return;
      }

      const res = (await storeDocument({ files: files })).data;
      if (res.success) {
        getDocuments();
        successHandler(t('success.upload'));
        setFiles([] as File[]);
      }
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 413) {
        setShowDocumentStorageFullModal(true);
      }
      if (error.response.status === 422) {
        errorHandler(t('error.fileSize'));
      } else {
        errorHandler(t('error.file'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = (await getDocument()).data;
      setFileList(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  return (
    <PageContainer title={t('docLibrary')}>
      <LoadingPage isLoading={isLoading} />
      <div style={{ width: '100%', maxWidth: '1200px', background: '#ffffff', padding: '30px 40px', borderRadius: 15 }}>
        {/* 尚未串接功能 暫時隱藏 */}
        <StorageBar
          showStorageAlmostFullModal={showDocumentStorageAlmostFullModal}
          showStorageFullModal={showDocumentStorageFullModal}
          setShowStorageAlmostFullModal={setShowDocumentStorageAlmostFullModal}
          setShowStorageFullModal={setShowDocumentStorageFullModal}
        />
        {/* <FileUpload Buttons={buttonGroup} /> */}
        <DropZone files={files} onDragStateChange={onDragStateChange} onFilesDrop={onFilesDrop} setFiles={setFiles} />
        <Text color={theme.colors.gray400} fontSize={theme.fontSizes.sm} textAlign={'center'}>
          {t('pattern.fileName')}
        </Text>
        <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} gridGap={'15px'} marginBottom={'20px'}>
          {files.length === 0 ? (
            <p>{t('noFileToUpload')}</p>
          ) : (
            <p>
              {t('filesReadyToUpload')}: {files.length}
            </p>
          )}
          <UploadFileList files={files} />
          {files.length > 0 && (
            <Button size="table" variant={'danger'} onClick={onUploadFile}>
              {t('buttons.upload')}
            </Button>
          )}
        </Flex>
        {fileList ? <DocumentList data={fileList} getDocuments={getDocuments} /> : <Loading />}
      </div>
    </PageContainer>
  );
};

export default DocLibrary;

const cnRegion: CNReigon[] = [
	{
		province_en: "Beijing",
		province_zh: "北京市",
		province_tc: "北京市",
		cities: [
			{
				city_en: "Beijing",
				city_zh: "北京市",
				city_tc: "北京市",
				prefecture: "none"
			}
		]
	},
	{
		province_en: "Hong Kong",
		province_zh: "香港",
		province_tc: "香港",
		cities: [
			{
				city_en: "Hong Kong",
				city_zh: "香港",
				city_tc: "香港",
				prefecture: "none"
			}
		]
	},
	{
		province_en: "Macau",
		province_zh: "澳门",
		province_tc: "澳門",
		cities: [
			{
				city_en: "Macau",
				city_zh: "澳门",
				city_tc: "澳門",
				prefecture: "none"
			}
		]
	},
	{
		province_en: "Taiwan",
		province_zh: "台湾",
		province_tc: "台灣",
		cities: [
			{
				city_en: "Taiwan",
				city_zh: "台湾",
				city_tc: "台灣",
				prefecture: "none"
			}
		]
	},
	{
		province_en: "Chongqing",
		province_zh: "重庆市",
		province_tc: "重慶市",
		cities: [
			{
				city_en: "Chongqing",
				city_zh: "重庆市",
				city_tc: "重慶市",
				prefecture: "none"
			}
		]
	},
	{
		province_en: "Shanghai",
		province_zh: "上海市",
		province_tc: "上海市",
		cities: [
			{
				city_en: "Shanghai",
				city_zh: "上海市",
				city_tc: "上海市",
				prefecture: "none"
			}
		]
	},
	{
		province_en: "Tianjin",
		province_zh: "天津市",
		province_tc: "天津市",
		cities: [
			{
				city_en: "Tianjin",
				city_zh: "天津市",
				city_tc: "天津市",
				prefecture: "none"
			}
		]
	},
	{
		province_en: "Anhui",
		province_zh: "安徽省",
		province_tc: "安徽省",
		cities: [
			{
				city_en: "Anqing",
				city_zh: "安庆市",
				city_tc: "安慶市",
				prefecture: "direct administration"
			},
			{
				city_en: "Bengbu",
				city_zh: "蚌埠市",
				city_tc: "蚌埠市",
				prefecture: "direct administration"
			},
			{
				city_en: "Bozhou",
				city_zh: "亳州市",
				city_tc: "亳州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Chaohu",
				city_zh: "巢湖市",
				city_tc: "巢湖市",
				prefecture: "Hefei"
			},
			{
				city_en: "Chizhou",
				city_zh: "池州市",
				city_tc: "池州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Chuzhou",
				city_zh: "滁州市",
				city_tc: "滁州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Fuyang",
				city_zh: "阜阳市",
				city_tc: "阜陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Hefei",
				city_zh: "合肥市",
				city_tc: "合肥市",
				prefecture: "direct administration"
			},
			{
				city_en: "Huaibei",
				city_zh: "淮北市",
				city_tc: "淮北市",
				prefecture: "direct administration"
			},
			{
				city_en: "Huainan",
				city_zh: "淮南市",
				city_tc: "淮南市",
				prefecture: "direct administration"
			},
			{
				city_en: "Huangshan",
				city_zh: "黄山市",
				city_tc: "黃山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jieshou",
				city_zh: "界首市",
				city_tc: "界首市",
				prefecture: "Fuyang"
			},
			{
				city_en: "Lu'an",
				city_zh: "六安市",
				city_tc: "六安市",
				prefecture: "direct administration"
			},
			{
				city_en: "Ma'anshan",
				city_zh: "马鞍山市",
				city_tc: "馬鞍山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Mingguang",
				city_zh: "明光市",
				city_tc: "明光市",
				prefecture: "Chuzhou"
			},
			{
				city_en: "Ningguo",
				city_zh: "宁国市",
				city_tc: "寧國市",
				prefecture: "Xuancheng"
			},
			{
				city_en: "Suzhou",
				city_zh: "宿州市",
				city_tc: "宿州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Tianchang",
				city_zh: "天长市",
				city_tc: "天長市",
				prefecture: "Chuzhou"
			},
			{
				city_en: "Tongcheng",
				city_zh: "桐城市",
				city_tc: "桐城市",
				prefecture: "Anqing"
			},
			{
				city_en: "Tongling",
				city_zh: "铜陵市",
				city_tc: "銅陵市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wuhu",
				city_zh: "芜湖市",
				city_tc: "蕪湖市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xuancheng",
				city_zh: "宣城市",
				city_tc: "宣城市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Fujian",
		province_zh: "福建省",
		province_tc: "福建省",
		cities: [
			{
				city_en: "Fu'an",
				city_zh: "福安市",
				city_tc: "福安市",
				prefecture: "Ningde"
			},
			{
				city_en: "Fuding",
				city_zh: "福鼎市",
				city_tc: "福鼎市",
				prefecture: "Ningde"
			},
			{
				city_en: "Fuqing",
				city_zh: "福清市",
				city_tc: "福清市",
				prefecture: "Fuzhou"
			},
			{
				city_en: "Fuzhou",
				city_zh: "福州市",
				city_tc: "福州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jian'ou",
				city_zh: "建瓯市",
				city_tc: "建甌市",
				prefecture: "Nanping"
			},
			{
				city_en: "Jinjiang",
				city_zh: "晋江市",
				city_tc: "晉江市",
				prefecture: "Quanzhou"
			},
			{
				city_en: "Longhai",
				city_zh: "龙海市",
				city_tc: "龍海市",
				prefecture: "Zhangzhou"
			},
			{
				city_en: "Longyan",
				city_zh: "龙岩市",
				city_tc: "龍岩市",
				prefecture: "direct administration"
			},
			{
				city_en: "Nan'an",
				city_zh: "南安市",
				city_tc: "南安市",
				prefecture: "Quanzhou"
			},
			{
				city_en: "Nanping",
				city_zh: "南平市",
				city_tc: "南平市",
				prefecture: "direct administration"
			},
			{
				city_en: "Ningde",
				city_zh: "宁德市",
				city_tc: "寧德市",
				prefecture: "direct administration"
			},
			{
				city_en: "Putian",
				city_zh: "莆田市",
				city_tc: "莆田市",
				prefecture: "direct administration"
			},
			{
				city_en: "Quanzhou",
				city_zh: "泉州市",
				city_tc: "泉州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Sanming",
				city_zh: "三明市",
				city_tc: "三明市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shaowu",
				city_zh: "邵武市",
				city_tc: "邵武市",
				prefecture: "Nanping"
			},
			{
				city_en: "Shishi",
				city_zh: "石狮市",
				city_tc: "石獅市",
				prefecture: "Quanzhou"
			},
			{
				city_en: "Wuyishan",
				city_zh: "武夷山市",
				city_tc: "武夷山市",
				prefecture: "Nanping"
			},
			{
				city_en: "Xiamen",
				city_zh: "厦门市",
				city_tc: "廈門市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yong'an",
				city_zh: "永安市",
				city_tc: "永安市",
				prefecture: "Sanming"
			},
			{
				city_en: "Zhangping",
				city_zh: "漳平市",
				city_tc: "漳平市",
				prefecture: "Longyan"
			},
			{
				city_en: "Zhangzhou",
				city_zh: "漳州市",
				city_tc: "漳州市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Gansu",
		province_zh: "甘肃省",
		province_tc: "甘肅省",
		cities: [
			{
				city_en: "Baiyin",
				city_zh: "白银市",
				city_tc: "白銀市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dingxi",
				city_zh: "定西市",
				city_tc: "定西市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dunhuang",
				city_zh: "敦煌市",
				city_tc: "敦煌市",
				prefecture: "Jiuquan"
			},
			{
				city_en: "Hezuo",
				city_zh: "合作市",
				city_tc: "合作市",
				prefecture: "Gannan"
			},
			{
				city_en: "Jiayuguan",
				city_zh: "嘉峪关市",
				city_tc: "嘉峪關市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jinchang",
				city_zh: "金昌市",
				city_tc: "金昌市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jiuquan",
				city_zh: "酒泉市",
				city_tc: "酒泉市",
				prefecture: "direct administration"
			},
			{
				city_en: "Lanzhou",
				city_zh: "兰州市",
				city_tc: "蘭州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Linxia",
				city_zh: "临夏市",
				city_tc: "臨夏市",
				prefecture: "Linxia"
			},
			{
				city_en: "Longnan",
				city_zh: "陇南市",
				city_tc: "隴南市",
				prefecture: "direct administration"
			},
			{
				city_en: "Pingliang",
				city_zh: "平凉市",
				city_tc: "平涼市",
				prefecture: "direct administration"
			},
			{
				city_en: "Qingyang",
				city_zh: "庆阳市",
				city_tc: "慶陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Tianshui",
				city_zh: "天水市",
				city_tc: "天水市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wuwei",
				city_zh: "武威市",
				city_tc: "武威市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yumen",
				city_zh: "玉门市",
				city_tc: "玉門市",
				prefecture: "Jiuquan"
			},
			{
				city_en: "Zhangye",
				city_zh: "张掖市",
				city_tc: "張掖市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Guangdong",
		province_zh: "广东省",
		province_tc: "廣東省",
		cities: [
			{
				city_en: "Chaozhou",
				city_zh: "潮州市",
				city_tc: "潮州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dongguan",
				city_zh: "东莞市",
				city_tc: "東莞市",
				prefecture: "direct administration"
			},
			{
				city_en: "Enping",
				city_zh: "恩平市",
				city_tc: "恩平市",
				prefecture: "Jiangmen"
			},
			{
				city_en: "Foshan",
				city_zh: "佛山市",
				city_tc: "佛山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Gaozhou",
				city_zh: "高州市",
				city_tc: "高州市",
				prefecture: "Maoming"
			},
			{
				city_en: "Guangzhou",
				city_zh: "广州市",
				city_tc: "廣州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Heshan",
				city_zh: "鹤山市",
				city_tc: "鶴山市",
				prefecture: "Jiangmen"
			},
			{
				city_en: "Heyuan",
				city_zh: "河源市",
				city_tc: "河源市",
				prefecture: "direct administration"
			},
			{
				city_en: "Huazhou",
				city_zh: "化州市",
				city_tc: "化州市",
				prefecture: "Maoming"
			},
			{
				city_en: "Huizhou",
				city_zh: "惠州市",
				city_tc: "惠州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jiangmen",
				city_zh: "江门市",
				city_tc: "江門市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jieyang",
				city_zh: "揭阳市",
				city_tc: "揭陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Kaiping",
				city_zh: "开平市",
				city_tc: "開平市",
				prefecture: "Jiangmen"
			},
			{
				city_en: "Lechang",
				city_zh: "乐昌市",
				city_tc: "樂昌市",
				prefecture: "Shaoguan"
			},
			{
				city_en: "Leizhou",
				city_zh: "雷州市",
				city_tc: "雷州市",
				prefecture: "Zhanjiang"
			},
			{
				city_en: "Lianjiang",
				city_zh: "廉江市",
				city_tc: "廉江市",
				prefecture: "Zhanjiang"
			},
			{
				city_en: "Lianzhou",
				city_zh: "连州市",
				city_tc: "連州市",
				prefecture: "Qingyuan"
			},
			{
				city_en: "Lufeng",
				city_zh: "陆丰市",
				city_tc: "陸豐市",
				prefecture: "Shanwei"
			},
			{
				city_en: "Luoding",
				city_zh: "罗定市",
				city_tc: "羅定市",
				prefecture: "Yunfu"
			},
			{
				city_en: "Maoming",
				city_zh: "茂名市",
				city_tc: "茂名市",
				prefecture: "direct administration"
			},
			{
				city_en: "Meizhou",
				city_zh: "梅州市",
				city_tc: "梅州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Nanxiong",
				city_zh: "南雄市",
				city_tc: "南雄市",
				prefecture: "Shaoguan"
			},
			{
				city_en: "Puning",
				city_zh: "普宁市",
				city_tc: "普寧市",
				prefecture: "Jieyang"
			},
			{
				city_en: "Qingyuan",
				city_zh: "清远市",
				city_tc: "清遠市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shantou",
				city_zh: "汕头市",
				city_tc: "汕頭市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shanwei",
				city_zh: "汕尾市",
				city_tc: "汕尾市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shaoguan",
				city_zh: "韶关市",
				city_tc: "韶關市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shenzhen",
				city_zh: "深圳市",
				city_tc: "深圳市",
				prefecture: "direct administration"
			},
			{
				city_en: "Sihui",
				city_zh: "四会市",
				city_tc: "四會市",
				prefecture: "Zhaoqing"
			},
			{
				city_en: "Taishan",
				city_zh: "台山市",
				city_tc: "台山市",
				prefecture: "Jiangmen"
			},
			{
				city_en: "Wuchuan",
				city_zh: "吴川市",
				city_tc: "吳川市",
				prefecture: "Zhanjiang"
			},
			{
				city_en: "Xingning",
				city_zh: "兴宁市",
				city_tc: "興寧市",
				prefecture: "Meizhou"
			},
			{
				city_en: "Xinyi",
				city_zh: "信宜市",
				city_tc: "信宜市",
				prefecture: "Maoming"
			},
			{
				city_en: "Yangchun",
				city_zh: "阳春市",
				city_tc: "陽春市",
				prefecture: "Yangjiang"
			},
			{
				city_en: "Yangjiang",
				city_zh: "阳江市",
				city_tc: "陽江市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yingde",
				city_zh: "英德市",
				city_tc: "英德市",
				prefecture: "Qingyuan"
			},
			{
				city_en: "Yunfu",
				city_zh: "云浮市",
				city_tc: "雲浮市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhanjiang",
				city_zh: "湛江市",
				city_tc: "湛江市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhaoqing",
				city_zh: "肇庆市",
				city_tc: "肇慶市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhongshan",
				city_zh: "中山市",
				city_tc: "中山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhuhai",
				city_zh: "珠海市",
				city_tc: "珠海市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Guangxi",
		province_zh: "广西壮族自治区",
		province_tc: "廣西壯族自治區",
		cities: [
			{
				city_en: "Baise",
				city_zh: "百色市",
				city_tc: "百色市",
				prefecture: "direct administration"
			},
			{
				city_en: "Beihai",
				city_zh: "北海市",
				city_tc: "北海市",
				prefecture: "direct administration"
			},
			{
				city_en: "Beiliu",
				city_zh: "北流市",
				city_tc: "北流市",
				prefecture: "Yulin"
			},
			{
				city_en: "Cenxi",
				city_zh: "岑溪市",
				city_tc: "岑溪市",
				prefecture: "Wuzhou"
			},
			{
				city_en: "Chongzuo",
				city_zh: "崇左市",
				city_tc: "崇左市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dongxing",
				city_zh: "东兴市",
				city_tc: "東興市",
				prefecture: "Fangchenggang"
			},
			{
				city_en: "Fangchenggang",
				city_zh: "防城港市",
				city_tc: "防城港市",
				prefecture: "direct administration"
			},
			{
				city_en: "Guigang",
				city_zh: "贵港市",
				city_tc: "貴港市",
				prefecture: "direct administration"
			},
			{
				city_en: "Guilin",
				city_zh: "桂林市",
				city_tc: "桂林市",
				prefecture: "direct administration"
			},
			{
				city_en: "Guiping",
				city_zh: "桂平市",
				city_tc: "桂平市",
				prefecture: "Guigang"
			},
			{
				city_en: "Hechi",
				city_zh: "河池市",
				city_tc: "河池市",
				prefecture: "direct administration"
			},
			{
				city_en: "Heshan",
				city_zh: "合山市",
				city_tc: "合山市",
				prefecture: "Laibin"
			},
			{
				city_en: "Hezhou",
				city_zh: "贺州市",
				city_tc: "賀州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jingxi",
				city_zh: "靖西市",
				city_tc: "靖西市",
				prefecture: "Baise"
			},
			{
				city_en: "Laibin",
				city_zh: "来宾市",
				city_tc: "來賓市",
				prefecture: "direct administration"
			},
			{
				city_en: "Liuzhou",
				city_zh: "柳州市",
				city_tc: "柳州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Nanning",
				city_zh: "南宁市",
				city_tc: "南寧市",
				prefecture: "direct administration"
			},
			{
				city_en: "Pingxiang",
				city_zh: "凭祥市",
				city_tc: "憑祥市",
				prefecture: "Chongzuo"
			},
			{
				city_en: "Qinzhou",
				city_zh: "钦州市",
				city_tc: "欽州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wuzhou",
				city_zh: "梧州市",
				city_tc: "梧州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yulin",
				city_zh: "玉林市",
				city_tc: "玉林市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Guizhou",
		province_zh: "贵州省",
		province_tc: "貴州省",
		cities: [
			{
				city_en: "Anshun",
				city_zh: "安顺市",
				city_tc: "安順市",
				prefecture: "direct administration"
			},
			{
				city_en: "Bijie",
				city_zh: "毕节市",
				city_tc: "畢節市",
				prefecture: "direct administration"
			},
			{
				city_en: "Chishui",
				city_zh: "赤水市",
				city_tc: "赤水市",
				prefecture: "Zunyi"
			},
			{
				city_en: "Duyun",
				city_zh: "都匀市",
				city_tc: "都勻市",
				prefecture: "Qiannan"
			},
			{
				city_en: "Fuquan",
				city_zh: "福泉市",
				city_tc: "福泉市",
				prefecture: "Qiannan"
			},
			{
				city_en: "Guiyang",
				city_zh: "贵阳市",
				city_tc: "貴陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Kaili",
				city_zh: "凯里市",
				city_tc: "凱里市",
				prefecture: "Qiandongnan"
			},
			{
				city_en: "Liupanshui",
				city_zh: "六盘水市",
				city_tc: "六盤水市",
				prefecture: "direct administration"
			},
			{
				city_en: "Panzhou",
				city_zh: "盘州市",
				city_tc: "盤州市",
				prefecture: "Liupanshui"
			},
			{
				city_en: "Qingzhen",
				city_zh: "清镇市",
				city_tc: "清鎮市",
				prefecture: "Guiyang"
			},
			{
				city_en: "Renhuai",
				city_zh: "仁怀市",
				city_tc: "仁懷市",
				prefecture: "Zunyi"
			},
			{
				city_en: "Tongren",
				city_zh: "铜仁市",
				city_tc: "銅仁市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xingyi",
				city_zh: "兴义市",
				city_tc: "興義市",
				prefecture: "Qianxinan"
			},
			{
				city_en: "Zunyi",
				city_zh: "遵义市",
				city_tc: "遵義市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Hainan",
		province_zh: "海南省",
		province_tc: "海南省",
		cities: [
			{
				city_en: "Danzhou",
				city_zh: "儋州市",
				city_tc: "儋州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dongfang",
				city_zh: "东方市",
				city_tc: "東方市",
				prefecture: "none"
			},
			{
				city_en: "Haikou",
				city_zh: "海口市",
				city_tc: "海口市",
				prefecture: "direct administration"
			},
			{
				city_en: "Qionghai",
				city_zh: "琼海市",
				city_tc: "瓊海市",
				prefecture: "none"
			},
			{
				city_en: "Sansha",
				city_zh: "三沙市",
				city_tc: "三沙市",
				prefecture: "direct administration"
			},
			{
				city_en: "Sanya",
				city_zh: "三亚市",
				city_tc: "三亞市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wanning",
				city_zh: "万宁市",
				city_tc: "萬寧市",
				prefecture: "none"
			},
			{
				city_en: "Wenchang",
				city_zh: "文昌市",
				city_tc: "文昌市",
				prefecture: "none"
			},
			{
				city_en: "Wuzhishan",
				city_zh: "五指山市",
				city_tc: "五指山市",
				prefecture: "none"
			}
		]
	},
	{
		province_en: "Hebei",
		province_zh: "河北省",
		province_tc: "河北省",
		cities: [
			{
				city_en: "Anguo",
				city_zh: "安国市",
				city_tc: "安國市",
				prefecture: "Baoding"
			},
			{
				city_en: "Baoding",
				city_zh: "保定市",
				city_tc: "保定市",
				prefecture: "direct administration"
			},
			{
				city_en: "Bazhou",
				city_zh: "霸州市",
				city_tc: "霸州市",
				prefecture: "Langfang"
			},
			{
				city_en: "Botou",
				city_zh: "泊头市",
				city_tc: "泊頭市",
				prefecture: "Cangzhou"
			},
			{
				city_en: "Cangzhou",
				city_zh: "沧州市",
				city_tc: "滄州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Chengde",
				city_zh: "承德市",
				city_tc: "承德市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dingzhou",
				city_zh: "定州市",
				city_tc: "定州市",
				prefecture: "Baoding"
			},
			{
				city_en: "Gaobeidian",
				city_zh: "高碑店市",
				city_tc: "高碑店市",
				prefecture: "Baoding"
			},
			{
				city_en: "Handan",
				city_zh: "邯郸市",
				city_tc: "邯鄲市",
				prefecture: "direct administration"
			},
			{
				city_en: "Hengshui",
				city_zh: "衡水市",
				city_tc: "衡水市",
				prefecture: "direct administration"
			},
			{
				city_en: "Hejian",
				city_zh: "河间市",
				city_tc: "河間市",
				prefecture: "Cangzhou"
			},
			{
				city_en: "Huanghua",
				city_zh: "黄骅市",
				city_tc: "黃驊市",
				prefecture: "Cangzhou"
			},
			{
				city_en: "Jinzhou",
				city_zh: "晋州市",
				city_tc: "晉州市",
				prefecture: "Shijiazhuang"
			},
			{
				city_en: "Langfang",
				city_zh: "廊坊市",
				city_tc: "廊坊市",
				prefecture: "direct administration"
			},
			{
				city_en: "Nangong",
				city_zh: "南宫市",
				city_tc: "南宮市",
				prefecture: "Xingtai"
			},
			{
				city_en: "Pingquan",
				city_zh: "平泉市",
				city_tc: "平泉市",
				prefecture: "Chengde"
			},
			{
				city_en: "Qian'an",
				city_zh: "迁安市",
				city_tc: "遷安市",
				prefecture: "Tangshan"
			},
			{
				city_en: "Qinhuangdao",
				city_zh: "秦皇岛市",
				city_tc: "秦皇島市",
				prefecture: "direct administration"
			},
			{
				city_en: "Renqiu",
				city_zh: "任丘市",
				city_tc: "任丘市",
				prefecture: "Cangzhou"
			},
			{
				city_en: "Sanhe",
				city_zh: "三河市",
				city_tc: "三河市",
				prefecture: "Langfang"
			},
			{
				city_en: "Shahe",
				city_zh: "沙河市",
				city_tc: "沙河市",
				prefecture: "Xingtai"
			},
			{
				city_en: "Shenzhou",
				city_zh: "深州市",
				city_tc: "深州市",
				prefecture: "Hengshui"
			},
			{
				city_en: "Shijiazhuang",
				city_zh: "石家庄市",
				city_tc: "石家莊市",
				prefecture: "direct administration"
			},
			{
				city_en: "Tangshan",
				city_zh: "唐山市",
				city_tc: "唐山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xinji",
				city_zh: "辛集市",
				city_tc: "辛集市",
				prefecture: "Shijiazhuang"
			},
			{
				city_en: "Wu'an",
				city_zh: "武安市",
				city_tc: "武安市",
				prefecture: "Handan"
			},
			{
				city_en: "Xingtai",
				city_zh: "邢台市",
				city_tc: "邢臺市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xinle",
				city_zh: "新乐市",
				city_tc: "新樂市",
				prefecture: "Shijiazhuang"
			},
			{
				city_en: "Zhangjiakou",
				city_zh: "张家口市",
				city_tc: "張家口市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhuozhou",
				city_zh: "涿州市",
				city_tc: "涿州市",
				prefecture: "Baoding"
			},
			{
				city_en: "Zunhua",
				city_zh: "遵化市",
				city_tc: "遵化市",
				prefecture: "Tangshan"
			}
		]
	},
	{
		province_en: "Heilongjiang",
		province_zh: "黑龙江省",
		province_tc: "黑龍江省",
		cities: [
			{
				city_en: "Anda",
				city_zh: "安达市",
				city_tc: "安達市",
				prefecture: "Suihua"
			},
			{
				city_en: "Bei'an",
				city_zh: "北安市",
				city_tc: "北安市",
				prefecture: "Heihe"
			},
			{
				city_en: "Daqing",
				city_zh: "大庆市",
				city_tc: "大慶市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dongning",
				city_zh: "东宁市",
				city_tc: "東寧市",
				prefecture: "Mudanjiang"
			},
			{
				city_en: "Fujin",
				city_zh: "富锦市",
				city_tc: "富錦市",
				prefecture: "Jiamusi"
			},
			{
				city_en: "Fuyuan",
				city_zh: "抚远市",
				city_tc: "撫遠市",
				prefecture: "Jiamusi"
			},
			{
				city_en: "Hailin",
				city_zh: "海林市",
				city_tc: "海林市",
				prefecture: "Mudanjiang"
			},
			{
				city_en: "Hailun",
				city_zh: "海伦市",
				city_tc: "海倫市",
				prefecture: "Suihua"
			},
			{
				city_en: "Harbin",
				city_zh: "哈尔滨市",
				city_tc: "哈爾濱市",
				prefecture: "direct administration"
			},
			{
				city_en: "Hegang",
				city_zh: "鹤岗市",
				city_tc: "鶴崗市",
				prefecture: "direct administration"
			},
			{
				city_en: "Heihe",
				city_zh: "黑河市",
				city_tc: "黑河市",
				prefecture: "direct administration"
			},
			{
				city_en: "Hulin",
				city_zh: "虎林市",
				city_tc: "虎林市",
				prefecture: "Jixi"
			},
			{
				city_en: "Jiamusi",
				city_zh: "佳木斯市",
				city_tc: "佳木斯市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jixi",
				city_zh: "鸡西市",
				city_tc: "雞西市",
				prefecture: "direct administration"
			},
			{
				city_en: "Mishan",
				city_zh: "密山市",
				city_tc: "密山市",
				prefecture: "Jixi"
			},
			{
				city_en: "Mudanjiang",
				city_zh: "牡丹江市",
				city_tc: "牡丹江市",
				prefecture: "direct administration"
			},
			{
				city_en: "Muling",
				city_zh: "穆棱市",
				city_tc: "穆棱市",
				prefecture: "Mudanjiang"
			},
			{
				city_en: "Nehe",
				city_zh: "讷河市",
				city_tc: "訥河市",
				prefecture: "Qiqihar"
			},
			{
				city_en: "Ning'an",
				city_zh: "宁安市",
				city_tc: "寧安市",
				prefecture: "Mudanjiang"
			},
			{
				city_en: "Qiqihar",
				city_zh: "齐齐哈尔市",
				city_tc: "齊齊哈爾市",
				prefecture: "direct administration"
			},
			{
				city_en: "Qitaihe",
				city_zh: "七台河市",
				city_tc: "七臺河市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shangzhi",
				city_zh: "尚志市",
				city_tc: "尚志市",
				prefecture: "Harbin"
			},
			{
				city_en: "Shuangyashan",
				city_zh: "双鸭山市",
				city_tc: "雙鴨山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Suifenhe",
				city_zh: "绥芬河市",
				city_tc: "綏芬河市",
				prefecture: "Mudanjiang"
			},
			{
				city_en: "Suihua",
				city_zh: "绥化市",
				city_tc: "綏化市",
				prefecture: "direct administration"
			},
			{
				city_en: "Tieli",
				city_zh: "铁力市",
				city_tc: "鐵力市",
				prefecture: "Yichun"
			},
			{
				city_en: "Tongjiang",
				city_zh: "同江市",
				city_tc: "同江市",
				prefecture: "Jiamusi"
			},
			{
				city_en: "Wuchang",
				city_zh: "五常市",
				city_tc: "五常市",
				prefecture: "Harbin"
			},
			{
				city_en: "Wudalianchi",
				city_zh: "五大连池市",
				city_tc: "五大連池市",
				prefecture: "Heihe"
			},
			{
				city_en: "Yichun",
				city_zh: "伊春市",
				city_tc: "伊春市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhaodong",
				city_zh: "肇东市",
				city_tc: "肇東市",
				prefecture: "Suihua"
			}
		]
	},
	{
		province_en: "Henan",
		province_zh: "河南省",
		province_tc: "河南省",
		cities: [
			{
				city_en: "Anyang",
				city_zh: "安阳市",
				city_tc: "安陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Changge",
				city_zh: "长葛市",
				city_tc: "長葛市",
				prefecture: "Xuchang"
			},
			{
				city_en: "Dengfeng",
				city_zh: "登封市",
				city_tc: "登封市",
				prefecture: "Zhengzhou"
			},
			{
				city_en: "Dengzhou",
				city_zh: "邓州市",
				city_tc: "鄧州市",
				prefecture: "Nanyang"
			},
			{
				city_en: "Gongyi",
				city_zh: "巩义市",
				city_tc: "鞏義市",
				prefecture: "Zhengzhou"
			},
			{
				city_en: "Hebi",
				city_zh: "鹤壁市",
				city_tc: "鶴壁市",
				prefecture: "direct administration"
			},
			{
				city_en: "Huixian",
				city_zh: "辉县市",
				city_tc: "輝縣市",
				prefecture: "Xinxiang"
			},
			{
				city_en: "Jiaozuo",
				city_zh: "焦作市",
				city_tc: "焦作市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jiyuan",
				city_zh: "济源市",
				city_tc: "濟源市",
				prefecture: "none"
			},
			{
				city_en: "Kaifeng",
				city_zh: "开封市",
				city_tc: "開封市",
				prefecture: "direct administration"
			},
			{
				city_en: "Lingbao",
				city_zh: "灵宝市",
				city_tc: "靈寶市",
				prefecture: "Sanmenxia"
			},
			{
				city_en: "Linzhou",
				city_zh: "林州市",
				city_tc: "林州市",
				prefecture: "Anyang"
			},
			{
				city_en: "Luohe",
				city_zh: "漯河市",
				city_tc: "漯河市",
				prefecture: "direct administration"
			},
			{
				city_en: "Luoyang",
				city_zh: "洛阳市",
				city_tc: "洛陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Mengzhou",
				city_zh: "孟州市",
				city_tc: "孟州市",
				prefecture: "Jiaozuo"
			},
			{
				city_en: "Nanyang",
				city_zh: "南阳市",
				city_tc: "南陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Pingdingshan",
				city_zh: "平顶山市",
				city_tc: "平頂山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Puyang",
				city_zh: "濮阳市",
				city_tc: "濮陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Qinyang",
				city_zh: "沁阳市",
				city_tc: "沁陽市",
				prefecture: "Jiaozuo"
			},
			{
				city_en: "Ruzhou",
				city_zh: "汝州市",
				city_tc: "汝州市",
				prefecture: "Pingdingshan"
			},
			{
				city_en: "Sanmenxia",
				city_zh: "三门峡市",
				city_tc: "三門峽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shangqiu",
				city_zh: "商丘市",
				city_tc: "商丘市",
				prefecture: "direct administration"
			},
			{
				city_en: "Weihui",
				city_zh: "卫辉市",
				city_tc: "衛輝市",
				prefecture: "Xinxiang"
			},
			{
				city_en: "Wugang",
				city_zh: "舞钢市",
				city_tc: "舞鋼市",
				prefecture: "Pingdingshan"
			},
			{
				city_en: "Xiangcheng",
				city_zh: "项城市",
				city_tc: "項城市",
				prefecture: "Zhoukou"
			},
			{
				city_en: "Xingyang",
				city_zh: "荥阳市",
				city_tc: "滎陽市",
				prefecture: "Zhengzhou"
			},
			{
				city_en: "Xinmi",
				city_zh: "新密市",
				city_tc: "新密市",
				prefecture: "Zhengzhou"
			},
			{
				city_en: "Xinxiang",
				city_zh: "新乡市",
				city_tc: "新鄉市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xinyang",
				city_zh: "信阳市",
				city_tc: "信陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xinzheng",
				city_zh: "新郑市",
				city_tc: "新鄭市",
				prefecture: "Zhengzhou"
			},
			{
				city_en: "Xuchang",
				city_zh: "许昌市",
				city_tc: "許昌市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yanshi",
				city_zh: "偃师市",
				city_tc: "偃師市",
				prefecture: "Luoyang"
			},
			{
				city_en: "Yima",
				city_zh: "义马市",
				city_tc: "義馬市",
				prefecture: "Sanmenxia"
			},
			{
				city_en: "Yongcheng",
				city_zh: "永城市",
				city_tc: "永城市",
				prefecture: "Shangqiu"
			},
			{
				city_en: "Yuzhou",
				city_zh: "禹州市",
				city_tc: "禹州市",
				prefecture: "Xuchang"
			},
			{
				city_en: "Zhengzhou",
				city_zh: "郑州市",
				city_tc: "鄭州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhoukou",
				city_zh: "周口市",
				city_tc: "周口市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhumadian",
				city_zh: "驻马店市",
				city_tc: "駐馬店市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Hubei",
		province_zh: "湖北省",
		province_tc: "湖北省",
		cities: [
			{
				city_en: "Anlu",
				city_zh: "安陆市",
				city_tc: "安陸市",
				prefecture: "Xiaogan"
			},
			{
				city_en: "Chibi",
				city_zh: "赤壁市",
				city_tc: "赤壁市",
				prefecture: "Xianning"
			},
			{
				city_en: "Dangyang",
				city_zh: "当阳市",
				city_tc: "當陽市",
				prefecture: "Yichang"
			},
			{
				city_en: "Danjiangkou",
				city_zh: "丹江口市",
				city_tc: "丹江口市",
				prefecture: "Shiyan"
			},
			{
				city_en: "Daye",
				city_zh: "大冶市",
				city_tc: "大冶市",
				prefecture: "Huangshi"
			},
			{
				city_en: "Enshi",
				city_zh: "恩施市",
				city_tc: "恩施市",
				prefecture: "Enshi"
			},
			{
				city_en: "Ezhou",
				city_zh: "鄂州市",
				city_tc: "鄂州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Guangshui",
				city_zh: "广水市",
				city_tc: "廣水市",
				prefecture: "Suizhou"
			},
			{
				city_en: "Hanchuan",
				city_zh: "汉川市",
				city_tc: "漢川市",
				prefecture: "Xiaogan"
			},
			{
				city_en: "Honghu",
				city_zh: "洪湖市",
				city_tc: "洪湖市",
				prefecture: "Jingzhou"
			},
			{
				city_en: "Huanggang",
				city_zh: "黄冈市",
				city_tc: "黃岡市",
				prefecture: "direct administration"
			},
			{
				city_en: "Huangshi",
				city_zh: "黄石市",
				city_tc: "黃石市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jingmen",
				city_zh: "荆门市",
				city_tc: "荊門市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jingzhou",
				city_zh: "荆州市",
				city_tc: "荊州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Laohekou",
				city_zh: "老河口市",
				city_tc: "老河口市",
				prefecture: "Xiangyang"
			},
			{
				city_en: "Lichuan",
				city_zh: "利川市",
				city_tc: "利川市",
				prefecture: "Enshi"
			},
			{
				city_en: "Macheng",
				city_zh: "麻城市",
				city_tc: "麻城市",
				prefecture: "Huanggang"
			},
			{
				city_en: "Qianjiang",
				city_zh: "潜江市",
				city_tc: "潛江市",
				prefecture: "none"
			},
			{
				city_en: "Shishou",
				city_zh: "石首市",
				city_tc: "石首市",
				prefecture: "Jingzhou"
			},
			{
				city_en: "Shiyan",
				city_zh: "十堰市",
				city_tc: "十堰市",
				prefecture: "direct administration"
			},
			{
				city_en: "Suizhou",
				city_zh: "随州市",
				city_tc: "隨州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Songzi",
				city_zh: "松滋市",
				city_tc: "松滋市",
				prefecture: "Jingzhou"
			},
			{
				city_en: "Tianmen",
				city_zh: "天门市",
				city_tc: "天門市",
				prefecture: "none"
			},
			{
				city_en: "Wuhan",
				city_zh: "武汉市",
				city_tc: "武漢市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wuxue",
				city_zh: "武穴市",
				city_tc: "武穴市",
				prefecture: "Huanggang"
			},
			{
				city_en: "Xiangyang",
				city_zh: "襄阳市",
				city_tc: "襄陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xianning",
				city_zh: "咸宁市",
				city_tc: "咸寧市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xiantao",
				city_zh: "仙桃市",
				city_tc: "仙桃市",
				prefecture: "none"
			},
			{
				city_en: "Xiaogan",
				city_zh: "孝感市",
				city_tc: "孝感市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yichang",
				city_zh: "宜昌市",
				city_tc: "宜昌市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yicheng",
				city_zh: "宜城市",
				city_tc: "宜城市",
				prefecture: "Xiangyang"
			},
			{
				city_en: "Yidu",
				city_zh: "宜都市",
				city_tc: "宜都市",
				prefecture: "Yichang"
			},
			{
				city_en: "Yingcheng",
				city_zh: "应城市",
				city_tc: "應城市",
				prefecture: "Xiaogan"
			},
			{
				city_en: "Zaoyang",
				city_zh: "枣阳市",
				city_tc: "棗陽市",
				prefecture: "Xiangyang"
			},
			{
				city_en: "Zhijiang",
				city_zh: "枝江市",
				city_tc: "枝江市",
				prefecture: "Yichang"
			},
			{
				city_en: "Zhongxiang",
				city_zh: "钟祥市",
				city_tc: "鐘祥市",
				prefecture: "Jingmen"
			}
		]
	},
	{
		province_en: "Hunan",
		province_zh: "湖南省",
		province_tc: "湖南省",
		cities: [
			{
				city_en: "Changde",
				city_zh: "常德市",
				city_tc: "常德市",
				prefecture: "direct administration"
			},
			{
				city_en: "Changning",
				city_zh: "常宁市",
				city_tc: "常寧市",
				prefecture: "Hengyang"
			},
			{
				city_en: "Changsha",
				city_zh: "长沙市",
				city_tc: "長沙市",
				prefecture: "direct administration"
			},
			{
				city_en: "Chenzhou",
				city_zh: "郴州市",
				city_tc: "郴州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Hengyang",
				city_zh: "衡阳市",
				city_tc: "衡陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Hongjiang",
				city_zh: "洪江市",
				city_tc: "洪江市",
				prefecture: "Huaihua"
			},
			{
				city_en: "Huaihua",
				city_zh: "怀化市",
				city_tc: "懷化市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jinshi",
				city_zh: "津市市",
				city_tc: "津市市",
				prefecture: "Changde"
			},
			{
				city_en: "Jishou",
				city_zh: "吉首市",
				city_tc: "吉首市",
				prefecture: "Xiangxi"
			},
			{
				city_en: "Leiyang",
				city_zh: "耒阳市",
				city_tc: "耒陽市",
				prefecture: "Hengyang"
			},
			{
				city_en: "Lengshuijiang",
				city_zh: "冷水江市",
				city_tc: "冷水江市",
				prefecture: "Loudi"
			},
			{
				city_en: "Lianyuan",
				city_zh: "涟源市",
				city_tc: "漣源市",
				prefecture: "Loudi"
			},
			{
				city_en: "Liling",
				city_zh: "醴陵市",
				city_tc: "醴陵市",
				prefecture: "Zhuzhou"
			},
			{
				city_en: "Linxiang",
				city_zh: "临湘市",
				city_tc: "臨湘市",
				prefecture: "Yueyang"
			},
			{
				city_en: "Liuyang",
				city_zh: "浏阳市",
				city_tc: "瀏陽市",
				prefecture: "Changsha"
			},
			{
				city_en: "Loudi",
				city_zh: "娄底市",
				city_tc: "婁底市",
				prefecture: "direct administration"
			},
			{
				city_en: "Miluo",
				city_zh: "汨罗市",
				city_tc: "汨羅市",
				prefecture: "Yueyang"
			},
			{
				city_en: "Ningxiang",
				city_zh: "宁乡市",
				city_tc: "寧鄉市",
				prefecture: "Changsha"
			},
			{
				city_en: "Shaoshan",
				city_zh: "韶山市",
				city_tc: "韶山市",
				prefecture: "Xiangtan"
			},
			{
				city_en: "Shaoyang",
				city_zh: "邵阳市",
				city_tc: "邵陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wugang",
				city_zh: "武冈市",
				city_tc: "武岡市",
				prefecture: "Shaoyang"
			},
			{
				city_en: "Xiangtan",
				city_zh: "湘潭市",
				city_tc: "湘潭市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xiangxiang",
				city_zh: "湘乡市",
				city_tc: "湘鄉市",
				prefecture: "Xiangtan"
			},
			{
				city_en: "Yiyang",
				city_zh: "益阳市",
				city_tc: "益陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yongzhou",
				city_zh: "永州市",
				city_tc: "永州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yuanjiang",
				city_zh: "沅江市",
				city_tc: "沅江市",
				prefecture: "Yiyang"
			},
			{
				city_en: "Yueyang",
				city_zh: "岳阳市",
				city_tc: "岳陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhangjiajie",
				city_zh: "张家界市",
				city_tc: "張家界市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhuzhou",
				city_zh: "株洲市",
				city_tc: "株洲市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zixing",
				city_zh: "资兴市",
				city_tc: "資興市",
				prefecture: "Chenzhou"
			}
		]
	},
	{
		province_en: "Inner Mongolia",
		province_zh: "内蒙古自治区",
		province_tc: "內蒙古自治區",
		cities: [
			{
				city_en: "Arxan",
				city_zh: "阿尔山市",
				city_tc: "阿爾山市",
				prefecture: "Hinggan"
			},
			{
				city_en: "Baotou",
				city_zh: "包头市",
				city_tc: "包頭市",
				prefecture: "direct administration"
			},
			{
				city_en: "Bayannur",
				city_zh: "巴彦淖尔市",
				city_tc: "巴彥淖爾市",
				prefecture: "direct administration"
			},
			{
				city_en: "Chifeng",
				city_zh: "赤峰市",
				city_tc: "赤峰市",
				prefecture: "direct administration"
			},
			{
				city_en: "Erenhot",
				city_zh: "二连浩特市",
				city_tc: "二連浩特市",
				prefecture: "Xilingol"
			},
			{
				city_en: "Ergun",
				city_zh: "额尔古纳市",
				city_tc: "額爾古納市",
				prefecture: "Hulunbuir"
			},
			{
				city_en: "Fengzhen",
				city_zh: "丰镇市",
				city_tc: "豐鎮市",
				prefecture: "Ulanqab"
			},
			{
				city_en: "Genhe",
				city_zh: "根河市",
				city_tc: "根河市",
				prefecture: "Hulunbuir"
			},
			{
				city_en: "Hohhot",
				city_zh: "呼和浩特市",
				city_tc: "呼和浩特市",
				prefecture: "direct administration"
			},
			{
				city_en: "Holingol",
				city_zh: "霍林郭勒市",
				city_tc: "霍林郭勒市",
				prefecture: "Tongliao"
			},
			{
				city_en: "Hulunbuir",
				city_zh: "呼伦贝尔市",
				city_tc: "呼倫貝爾市",
				prefecture: "direct administration"
			},
			{
				city_en: "Manzhouli",
				city_zh: "满洲里市",
				city_tc: "滿洲里市",
				prefecture: "Hulunbuir"
			},
			{
				city_en: "Ordos",
				city_zh: "鄂尔多斯市",
				city_tc: "鄂爾多斯市",
				prefecture: "direct administration"
			},
			{
				city_en: "Tongliao",
				city_zh: "通辽市",
				city_tc: "通遼市",
				prefecture: "direct administration"
			},
			{
				city_en: "Ulanhot",
				city_zh: "乌兰浩特市",
				city_tc: "烏蘭浩特市",
				prefecture: "Hinggan"
			},
			{
				city_en: "Ulanqab",
				city_zh: "乌兰察布市",
				city_tc: "烏蘭察布市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wuhai",
				city_zh: "乌海市",
				city_tc: "烏海市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xilinhot",
				city_zh: "锡林浩特市",
				city_tc: "錫林浩特市",
				prefecture: "Xilingol"
			},
			{
				city_en: "Yakeshi",
				city_zh: "牙克石市",
				city_tc: "牙克石市",
				prefecture: "Hulunbuir"
			},
			{
				city_en: "Zhalantun",
				city_zh: "扎兰屯市",
				city_tc: "紮蘭屯市",
				prefecture: "Hulunbuir"
			}
		]
	},
	{
		province_en: "Jiangsu",
		province_zh: "江苏省",
		province_tc: "江蘇省",
		cities: [
			{
				city_en: "Changshu",
				city_zh: "常熟市",
				city_tc: "常熟市",
				prefecture: "Suzhou"
			},
			{
				city_en: "Changzhou",
				city_zh: "常州市",
				city_tc: "常州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Danyang",
				city_zh: "丹阳市",
				city_tc: "丹陽市",
				prefecture: "Zhenjiang"
			},
			{
				city_en: "Dongtai",
				city_zh: "东台市",
				city_tc: "東臺市",
				prefecture: "Yancheng"
			},
			{
				city_en: "Gaoyou",
				city_zh: "高邮市",
				city_tc: "高郵市",
				prefecture: "Yangzhou"
			},
			{
				city_en: "Haimen",
				city_zh: "海门市",
				city_tc: "海門市",
				prefecture: "Nantong"
			},
			{
				city_en: "Huai'an",
				city_zh: "淮安市",
				city_tc: "淮安市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jiangyin",
				city_zh: "江阴市",
				city_tc: "江陰市",
				prefecture: "Wuxi"
			},
			{
				city_en: "Jingjiang",
				city_zh: "靖江市",
				city_tc: "靖江市",
				prefecture: "Taizhou"
			},
			{
				city_en: "Jurong",
				city_zh: "句容市",
				city_tc: "句容市",
				prefecture: "Zhenjiang"
			},
			{
				city_en: "Liyang",
				city_zh: "溧阳市",
				city_tc: "溧陽市",
				prefecture: "Changzhou"
			},
			{
				city_en: "Lianyungang",
				city_zh: "连云港市",
				city_tc: "連雲港市",
				prefecture: "direct administration"
			},
			{
				city_en: "Kunshan",
				city_zh: "昆山市",
				city_tc: "昆山市",
				prefecture: "Suzhou"
			},
			{
				city_en: "Nanjing",
				city_zh: "南京市",
				city_tc: "南京市",
				prefecture: "direct administration"
			},
			{
				city_en: "Nantong",
				city_zh: "南通市",
				city_tc: "南通市",
				prefecture: "direct administration"
			},
			{
				city_en: "Pizhou",
				city_zh: "邳州市",
				city_tc: "邳州市",
				prefecture: "Xuzhou"
			},
			{
				city_en: "Qidong",
				city_zh: "启东市",
				city_tc: "啟東市",
				prefecture: "Nantong"
			},
			{
				city_en: "Rugao",
				city_zh: "如皋市",
				city_tc: "如皋市",
				prefecture: "Nantong"
			},
			{
				city_en: "Suqian",
				city_zh: "宿迁市",
				city_tc: "宿遷市",
				prefecture: "direct administration"
			},
			{
				city_en: "Suzhou",
				city_zh: "苏州市",
				city_tc: "蘇州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Taicang",
				city_zh: "太仓市",
				city_tc: "太倉市",
				prefecture: "Suzhou"
			},
			{
				city_en: "Taixing",
				city_zh: "泰兴市",
				city_tc: "泰興市",
				prefecture: "Taizhou"
			},
			{
				city_en: "Taizhou",
				city_zh: "泰州市",
				city_tc: "泰州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wuxi",
				city_zh: "无锡市",
				city_tc: "無錫市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xinghua",
				city_zh: "兴化市",
				city_tc: "興化市",
				prefecture: "Taizhou"
			},
			{
				city_en: "Xinyi",
				city_zh: "新沂市",
				city_tc: "新彌市",
				prefecture: "Xuzhou"
			},
			{
				city_en: "Xuzhou",
				city_zh: "徐州市",
				city_tc: "徐州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yancheng",
				city_zh: "盐城市",
				city_tc: "鹽城市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yangzhong",
				city_zh: "扬中市",
				city_tc: "揚中市",
				prefecture: "Zhenjiang"
			},
			{
				city_en: "Yangzhou",
				city_zh: "扬州市",
				city_tc: "揚州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yixing",
				city_zh: "宜兴市",
				city_tc: "宜興市",
				prefecture: "Wuxi"
			},
			{
				city_en: "Yizheng",
				city_zh: "仪征市",
				city_tc: "儀徵市",
				prefecture: "Yangzhou"
			},
			{
				city_en: "Zhangjiagang",
				city_zh: "张家港市",
				city_tc: "張家港市",
				prefecture: "Suzhou"
			},
			{
				city_en: "Zhenjiang",
				city_zh: "镇江市",
				city_tc: "鎮江市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Jiangxi",
		province_zh: "江西省",
		province_tc: "江西省",
		cities: [
			{
				city_en: "Dexing",
				city_zh: "德兴市",
				city_tc: "德興市",
				prefecture: "Shangrao"
			},
			{
				city_en: "Fengcheng",
				city_zh: "丰城市",
				city_tc: "豐城市",
				prefecture: "Yichun"
			},
			{
				city_en: "Fuzhou",
				city_zh: "抚州市",
				city_tc: "撫州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Ganzhou",
				city_zh: "赣州市",
				city_tc: "贛州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Gao'an",
				city_zh: "高安市",
				city_tc: "高安市",
				prefecture: "Yichun"
			},
			{
				city_en: "Gongqingcheng",
				city_zh: "共青城市",
				city_tc: "共青城市",
				prefecture: "Jiujiang"
			},
			{
				city_en: "Guixi",
				city_zh: "贵溪市",
				city_tc: "貴溪市",
				prefecture: "Yingtan"
			},
			{
				city_en: "Ji'an",
				city_zh: "吉安市",
				city_tc: "吉安市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jingdezhen",
				city_zh: "景德镇市",
				city_tc: "景德鎮市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jinggangshan",
				city_zh: "井冈山市",
				city_tc: "井崗山市",
				prefecture: "Ji'an"
			},
			{
				city_en: "Jiujiang",
				city_zh: "九江市",
				city_tc: "九江市",
				prefecture: "direct administration"
			},
			{
				city_en: "Leping",
				city_zh: "乐平市",
				city_tc: "樂平市",
				prefecture: "Jingdezhen"
			},
			{
				city_en: "Lushan",
				city_zh: "庐山市",
				city_tc: "廬山市",
				prefecture: "Jiujiang"
			},
			{
				city_en: "Nanchang",
				city_zh: "南昌市",
				city_tc: "南昌市",
				prefecture: "direct administration"
			},
			{
				city_en: "Pingxiang",
				city_zh: "萍乡市",
				city_tc: "萍鄉市",
				prefecture: "direct administration"
			},
			{
				city_en: "Ruichang",
				city_zh: "瑞昌市",
				city_tc: "瑞昌市",
				prefecture: "Jiujiang"
			},
			{
				city_en: "Ruijin",
				city_zh: "瑞金市",
				city_tc: "瑞金市",
				prefecture: "Ganzhou"
			},
			{
				city_en: "Shangrao",
				city_zh: "上饶市",
				city_tc: "上饒市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xinyu",
				city_zh: "新余市",
				city_tc: "新余市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yichun",
				city_zh: "宜春市",
				city_tc: "宜春市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yingtan",
				city_zh: "鹰潭市",
				city_tc: "鷹潭市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhangshu",
				city_zh: "樟树市",
				city_tc: "樟樹市",
				prefecture: "Yichun"
			}
		]
	},
	{
		province_en: "Jilin",
		province_zh: "吉林省",
		province_tc: "吉林省",
		cities: [
			{
				city_en: "Baicheng",
				city_zh: "白城市",
				city_tc: "白城市",
				prefecture: "direct administration"
			},
			{
				city_en: "Baishan",
				city_zh: "白山市",
				city_tc: "白山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Changchun",
				city_zh: "长春市",
				city_tc: "長春市",
				prefecture: "direct administration"
			},
			{
				city_en: "Da'an",
				city_zh: "大安市",
				city_tc: "大安市",
				prefecture: "Baicheng"
			},
			{
				city_en: "Dehui",
				city_zh: "德惠市",
				city_tc: "德惠市",
				prefecture: "Changchun"
			},
			{
				city_en: "Dunhua",
				city_zh: "敦化市",
				city_tc: "敦化市",
				prefecture: "Yanbian"
			},
			{
				city_en: "Fuyu",
				city_zh: "扶余市",
				city_tc: "扶餘市",
				prefecture: "Songyuan"
			},
			{
				city_en: "Gongzhuling",
				city_zh: "公主岭市",
				city_tc: "公主嶺市",
				prefecture: "Siping"
			},
			{
				city_en: "Helong",
				city_zh: "和龙市",
				city_tc: "和龍市",
				prefecture: "Yanbian"
			},
			{
				city_en: "Huadian",
				city_zh: "桦甸市",
				city_tc: "樺甸市",
				prefecture: "Jilin"
			},
			{
				city_en: "Hunchun",
				city_zh: "珲春市",
				city_tc: "琿春市",
				prefecture: "Yanbian"
			},
			{
				city_en: "Ji'an",
				city_zh: "集安市",
				city_tc: "集安市",
				prefecture: "Tonghua"
			},
			{
				city_en: "Jiaohe",
				city_zh: "蛟河市",
				city_tc: "蛟河市",
				prefecture: "Jilin"
			},
			{
				city_en: "Jilin",
				city_zh: "吉林市",
				city_tc: "吉林市",
				prefecture: "direct administration"
			},
			{
				city_en: "Liaoyuan",
				city_zh: "辽源市",
				city_tc: "遼源市",
				prefecture: "direct administration"
			},
			{
				city_en: "Linjiang",
				city_zh: "临江市",
				city_tc: "臨江市",
				prefecture: "Baishan"
			},
			{
				city_en: "Longjing",
				city_zh: "龙井市",
				city_tc: "龍井市",
				prefecture: "Yanbian"
			},
			{
				city_en: "Meihekou",
				city_zh: "梅河口市",
				city_tc: "梅河口市",
				prefecture: "Tonghua"
			},
			{
				city_en: "Panshi",
				city_zh: "磐石市",
				city_tc: "磐石市",
				prefecture: "Jilin"
			},
			{
				city_en: "Shuangliao",
				city_zh: "双辽市",
				city_tc: "雙遼市",
				prefecture: "Siping"
			},
			{
				city_en: "Shulan",
				city_zh: "舒兰市",
				city_tc: "舒蘭市",
				prefecture: "Jilin"
			},
			{
				city_en: "Siping",
				city_zh: "四平市",
				city_tc: "四平市",
				prefecture: "direct administration"
			},
			{
				city_en: "Songyuan",
				city_zh: "松原市",
				city_tc: "松原市",
				prefecture: "direct administration"
			},
			{
				city_en: "Taonan",
				city_zh: "洮南市",
				city_tc: "洮南市",
				prefecture: "Baicheng"
			},
			{
				city_en: "Tonghua",
				city_zh: "通化市",
				city_tc: "通化市",
				prefecture: "direct administration"
			},
			{
				city_en: "Tumen",
				city_zh: "图们市",
				city_tc: "圖們市",
				prefecture: "Yanbian"
			},
			{
				city_en: "Yanji",
				city_zh: "延吉市",
				city_tc: "延吉市",
				prefecture: "Yanbian"
			},
			{
				city_en: "Yushu",
				city_zh: "榆树市",
				city_tc: "榆樹市",
				prefecture: "Changchun"
			}
		]
	},
	{
		province_en: "Liaoning",
		province_zh: "辽宁省",
		province_tc: "遼寧省",
		cities: [
			{
				city_en: "Anshan",
				city_zh: "鞍山市",
				city_tc: "鞍山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Benxi",
				city_zh: "本溪市",
				city_tc: "本溪市",
				prefecture: "direct administration"
			},
			{
				city_en: "Beipiao",
				city_zh: "北票市",
				city_tc: "北票市",
				prefecture: "Chaoyang"
			},
			{
				city_en: "Beizhen",
				city_zh: "北镇市",
				city_tc: "北鎮市",
				prefecture: "Jinzhou"
			},
			{
				city_en: "Chaoyang",
				city_zh: "朝阳市",
				city_tc: "朝陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dalian",
				city_zh: "大连市",
				city_tc: "大連市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dandong",
				city_zh: "丹东市",
				city_tc: "丹東市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dashiqiao",
				city_zh: "大石桥市",
				city_tc: "大石橋市",
				prefecture: "Yingkou"
			},
			{
				city_en: "Dengta",
				city_zh: "灯塔市",
				city_tc: "燈塔市",
				prefecture: "Liaoyang"
			},
			{
				city_en: "Diaobingshan",
				city_zh: "调兵山市",
				city_tc: "調兵山市",
				prefecture: "Tieling"
			},
			{
				city_en: "Donggang",
				city_zh: "东港市",
				city_tc: "東港市",
				prefecture: "Dandong"
			},
			{
				city_en: "Fengcheng",
				city_zh: "凤城市",
				city_tc: "鳳城市",
				prefecture: "Dandong"
			},
			{
				city_en: "Fushun",
				city_zh: "抚顺市",
				city_tc: "撫順市",
				prefecture: "direct administration"
			},
			{
				city_en: "Fuxin",
				city_zh: "阜新市",
				city_tc: "阜新市",
				prefecture: "direct administration"
			},
			{
				city_en: "Gaizhou",
				city_zh: "盖州市",
				city_tc: "蓋州市",
				prefecture: "Yingkou"
			},
			{
				city_en: "Haicheng",
				city_zh: "海城市",
				city_tc: "海城市",
				prefecture: "Anshan"
			},
			{
				city_en: "Huludao",
				city_zh: "葫芦岛市",
				city_tc: "葫蘆島市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jinzhou",
				city_zh: "锦州市",
				city_tc: "錦州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Kaiyuan",
				city_zh: "开原市",
				city_tc: "開原市",
				prefecture: "Tieling"
			},
			{
				city_en: "Liaoyang",
				city_zh: "辽阳市",
				city_tc: "遼陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Linghai",
				city_zh: "凌海市",
				city_tc: "淩海市",
				prefecture: "Jinzhou"
			},
			{
				city_en: "Lingyuan",
				city_zh: "凌源市",
				city_tc: "淩源市",
				prefecture: "Chaoyang"
			},
			{
				city_en: "Panjin",
				city_zh: "盘锦市",
				city_tc: "盤錦市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shenyang",
				city_zh: "沈阳市",
				city_tc: "瀋陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Tieling",
				city_zh: "铁岭市",
				city_tc: "鐵嶺市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wafangdian",
				city_zh: "瓦房店市",
				city_tc: "瓦房店市",
				prefecture: "Dalian"
			},
			{
				city_en: "Xingcheng",
				city_zh: "兴城市",
				city_tc: "興城市",
				prefecture: "Huludao"
			},
			{
				city_en: "Xinmin",
				city_zh: "新民市",
				city_tc: "新民市",
				prefecture: "Shenyang"
			},
			{
				city_en: "Yingkou",
				city_zh: "营口市",
				city_tc: "營口市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhuanghe",
				city_zh: "庄河市",
				city_tc: "莊河市",
				prefecture: "Dalian"
			}
		]
	},
  {
    province_en: "Ningxia",
    province_zh: "宁夏回族自治区",
    province_tc: "寧夏回族自治區",
    cities: [
      {
        city_en: "Guyuan",
        city_zh: "固原市",
        city_tc: "固原市",
        prefecture: "direct administration"
      },
      {
        city_en: "Lingwu",
        city_zh: "灵武市",
        city_tc: "靈武市",
        prefecture: "Yinchuan"
      },
      {
        city_en: "Qingtongxia",
        city_zh: "青铜峡市",
        city_tc: "青銅峽市",
        prefecture: "Wuzhong"
      },
      {
        city_en: "Shizuishan",
        city_zh: "石嘴山市",
        city_tc: "石嘴山市",
        prefecture: "direct administration"
      },
      {
        city_en: "Wuzhong",
        city_zh: "吴忠市",
        city_tc: "吳忠市",
        prefecture: "direct administration"
      },
      {
        city_en: "Yinchuan",
        city_zh: "银川市",
        city_tc: "銀川市",
        prefecture: "direct administration"
      },
      {
        city_en: "Zhongwei",
        city_zh: "中卫市",
        city_tc: "中衛市",
        prefecture: "direct administration"
      }
    ]
  },
	{
		province_en: "Qinghai",
		province_zh: "青海省",
		province_tc: "青海省",
		cities: [
			{
				city_en: "Delingha",
				city_zh: "德令哈市",
				city_tc: "德令哈市",
				prefecture: "Haixi"
			},
			{
				city_en: "Golmud",
				city_zh: "格尔木市",
				city_tc: "格爾木市",
				prefecture: "Haixi"
			},
			{
				city_en: "Haidong",
				city_zh: "海东市",
				city_tc: "海東市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xining",
				city_zh: "西宁市",
				city_tc: "西寧市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yushu",
				city_zh: "玉树市",
				city_tc: "玉樹市",
				prefecture: "Yushu"
			}
		]
	},
	{
		province_en: "Shaanxi",
		province_zh: "陕西省",
		province_tc: "陝西省",
		cities: [
			{
				city_en: "Ankang",
				city_zh: "安康市",
				city_tc: "安康市",
				prefecture: "direct administration"
			},
			{
				city_en: "Baoji",
				city_zh: "宝鸡市",
				city_tc: "寶雞市",
				prefecture: "direct administration"
			},
			{
				city_en: "Hancheng",
				city_zh: "韩城市",
				city_tc: "韓城市",
				prefecture: "Weinan"
			},
			{
				city_en: "Hanzhong",
				city_zh: "汉中市",
				city_tc: "漢中市",
				prefecture: "direct administration"
			},
			{
				city_en: "Huayin",
				city_zh: "华阴市",
				city_tc: "華陰市",
				prefecture: "Weinan"
			},
			{
				city_en: "Shangluo",
				city_zh: "商洛市",
				city_tc: "商洛市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shenmu",
				city_zh: "神木市",
				city_tc: "神木市",
				prefecture: "Yulin"
			},
			{
				city_en: "Tongchuan",
				city_zh: "铜川市",
				city_tc: "銅川市",
				prefecture: "direct administration"
			},
			{
				city_en: "Weinan",
				city_zh: "渭南市",
				city_tc: "渭南市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xi'an",
				city_zh: "西安市",
				city_tc: "西安市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xianyang",
				city_zh: "咸阳市",
				city_tc: "咸陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xingping",
				city_zh: "兴平市",
				city_tc: "興平市",
				prefecture: "Xianyang"
			},
			{
				city_en: "Yan'an",
				city_zh: "延安市",
				city_tc: "延安市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yulin",
				city_zh: "榆林市",
				city_tc: "榆林市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Shandong",
		province_zh: "山东省",
		province_tc: "山東省",
		cities: [
			{
				city_en: "Anqiu",
				city_zh: "安丘市",
				city_tc: "安丘市",
				prefecture: "Weifang"
			},
			{
				city_en: "Binzhou",
				city_zh: "滨州市",
				city_tc: "濱州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Changyi",
				city_zh: "昌邑市",
				city_tc: "昌邑市",
				prefecture: "Weifang"
			},
			{
				city_en: "Dezhou",
				city_zh: "德州市",
				city_tc: "德州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dongying",
				city_zh: "东营市",
				city_tc: "東營市",
				prefecture: "direct administration"
			},
			{
				city_en: "Feicheng",
				city_zh: "肥城市",
				city_tc: "肥城市",
				prefecture: "Tai'an"
			},
			{
				city_en: "Gaomi",
				city_zh: "高密市",
				city_tc: "高密市",
				prefecture: "Weifang"
			},
			{
				city_en: "Haiyang",
				city_zh: "海阳市",
				city_tc: "海陽市",
				prefecture: "Yantai"
			},
			{
				city_en: "Heze",
				city_zh: "菏泽市",
				city_tc: "菏澤市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jiaozhou",
				city_zh: "胶州市",
				city_tc: "膠州市",
				prefecture: "Qingdao"
			},
			{
				city_en: "Jinan",
				city_zh: "济南市",
				city_tc: "濟南市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jining",
				city_zh: "济宁市",
				city_tc: "濟寧市",
				prefecture: "direct administration"
			},
			{
				city_en: "Laiwu",
				city_zh: "莱芜市",
				city_tc: "萊蕪市",
				prefecture: "direct administration"
			},
			{
				city_en: "Laixi",
				city_zh: "莱西市",
				city_tc: "萊西市",
				prefecture: "Qingdao"
			},
			{
				city_en: "Laiyang",
				city_zh: "莱阳市",
				city_tc: "萊陽市",
				prefecture: "Yantai"
			},
			{
				city_en: "Laizhou",
				city_zh: "莱州市",
				city_tc: "萊州市",
				prefecture: "Yantai"
			},
			{
				city_en: "Leling",
				city_zh: "乐陵市",
				city_tc: "樂陵市",
				prefecture: "Dezhou"
			},
			{
				city_en: "Liaocheng",
				city_zh: "聊城市",
				city_tc: "聊城市",
				prefecture: "direct administration"
			},
			{
				city_en: "Linqing",
				city_zh: "临清市",
				city_tc: "臨清市",
				prefecture: "Liaocheng"
			},
			{
				city_en: "Linyi",
				city_zh: "临沂市",
				city_tc: "臨沂市",
				prefecture: "direct administration"
			},
			{
				city_en: "Longkou",
				city_zh: "龙口市",
				city_tc: "龍口市",
				prefecture: "Yantai"
			},
			{
				city_en: "Penglai",
				city_zh: "蓬莱市",
				city_tc: "蓬萊市",
				prefecture: "Yantai"
			},
			{
				city_en: "Pingdu",
				city_zh: "平度市",
				city_tc: "平度市",
				prefecture: "Qingdao"
			},
			{
				city_en: "Qingdao",
				city_zh: "青岛市",
				city_tc: "青島市",
				prefecture: "direct administration"
			},
			{
				city_en: "Qingzhou",
				city_zh: "青州市",
				city_tc: "青州市",
				prefecture: "Weifang"
			},
			{
				city_en: "Qixia",
				city_zh: "栖霞市",
				city_tc: "棲霞市",
				prefecture: "Yantai"
			},
			{
				city_en: "Qufu",
				city_zh: "曲阜市",
				city_tc: "曲阜市",
				prefecture: "Jining"
			},
			{
				city_en: "Rizhao",
				city_zh: "日照市",
				city_tc: "日照市",
				prefecture: "direct administration"
			},
			{
				city_en: "Rongcheng",
				city_zh: "荣成市",
				city_tc: "榮成市",
				prefecture: "Weihai"
			},
			{
				city_en: "Rushan",
				city_zh: "乳山市",
				city_tc: "乳山市",
				prefecture: "Weihai"
			},
			{
				city_en: "Shouguang",
				city_zh: "寿光市",
				city_tc: "壽光市",
				prefecture: "Weifang"
			},
			{
				city_en: "Tai'an",
				city_zh: "泰安市",
				city_tc: "泰安市",
				prefecture: "direct administration"
			},
			{
				city_en: "Tengzhou",
				city_zh: "滕州市",
				city_tc: "滕州市",
				prefecture: "Zaozhuang"
			},
			{
				city_en: "Weifang",
				city_zh: "潍坊市",
				city_tc: "濰坊市",
				prefecture: "direct administration"
			},
			{
				city_en: "Weihai",
				city_zh: "威海市",
				city_tc: "威海市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xintai",
				city_zh: "新泰市",
				city_tc: "新泰市",
				prefecture: "Tai'an"
			},
			{
				city_en: "Yantai",
				city_zh: "烟台市",
				city_tc: "煙台市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yucheng",
				city_zh: "禹城市",
				city_tc: "禹城市",
				prefecture: "Dezhou"
			},
			{
				city_en: "Zaozhuang",
				city_zh: "枣庄市",
				city_tc: "棗莊市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhaoyuan",
				city_zh: "招远市",
				city_tc: "招遠市",
				prefecture: "Yantai"
			},
			{
				city_en: "Zhucheng",
				city_zh: "诸城市",
				city_tc: "諸城市",
				prefecture: "Weifang"
			},
			{
				city_en: "Zibo",
				city_zh: "淄博市",
				city_tc: "淄博市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zoucheng",
				city_zh: "邹城市",
				city_tc: "鄒城市",
				prefecture: "Jining"
			}
		]
	},
	{
		province_en: "Shanxi",
		province_zh: "山西省",
		province_tc: "山西省",
		cities: [
			{
				city_en: "Changzhi",
				city_zh: "长治市",
				city_tc: "長治市",
				prefecture: "direct administration"
			},
			{
				city_en: "Datong",
				city_zh: "大同市",
				city_tc: "大同市",
				prefecture: "direct administration"
			},
			{
				city_en: "Fenyang",
				city_zh: "汾阳市",
				city_tc: "汾陽市",
				prefecture: "Lüliang"
			},
			{
				city_en: "Gaoping",
				city_zh: "高平市",
				city_tc: "高平市",
				prefecture: "Jincheng"
			},
			{
				city_en: "Gujiao",
				city_zh: "古交市",
				city_tc: "古交市",
				prefecture: "Taiyuan"
			},
			{
				city_en: "Hejin",
				city_zh: "河津市",
				city_tc: "河津市",
				prefecture: "Yuncheng"
			},
			{
				city_en: "Houma",
				city_zh: "侯马市",
				city_tc: "侯馬市",
				prefecture: "Linfen"
			},
			{
				city_en: "Huozhou",
				city_zh: "霍州市",
				city_tc: "霍州市",
				prefecture: "Linfen"
			},
			{
				city_en: "Jiexiu",
				city_zh: "介休市",
				city_tc: "介休市",
				prefecture: "Jinzhong"
			},
			{
				city_en: "Jincheng",
				city_zh: "晋城市",
				city_tc: "晉城市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jinzhong",
				city_zh: "晋中市",
				city_tc: "晉中市",
				prefecture: "direct administration"
			},
			{
				city_en: "Linfen",
				city_zh: "临汾市",
				city_tc: "臨汾市",
				prefecture: "direct administration"
			},
			{
				city_en: "Lucheng",
				city_zh: "潞城市",
				city_tc: "潞城市",
				prefecture: "Changzhi"
			},
			{
				city_en: "Lüliang",
				city_zh: "吕梁市",
				city_tc: "呂梁市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shuozhou",
				city_zh: "朔州市",
				city_tc: "朔州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Taiyuan",
				city_zh: "太原市",
				city_tc: "太原市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xiaoyi",
				city_zh: "孝义市",
				city_tc: "孝義市",
				prefecture: "Lüliang"
			},
			{
				city_en: "Xinzhou",
				city_zh: "忻州市",
				city_tc: "忻州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yangquan",
				city_zh: "阳泉市",
				city_tc: "陽泉市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yongji",
				city_zh: "永济市",
				city_tc: "永濟市",
				prefecture: "Yuncheng"
			},
			{
				city_en: "Yuncheng",
				city_zh: "运城市",
				city_tc: "運城市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yuanping",
				city_zh: "原平市",
				city_tc: "原平市",
				prefecture: "Xinzhou"
			}
		]
	},
	{
		province_en: "Sichuan",
		province_zh: "四川省",
		province_tc: "四川省",
		cities: [
			{
				city_en: "Barkam",
				city_zh: "马尔康市",
				city_tc: "馬爾康市",
				prefecture: "Ngawa"
			},
			{
				city_en: "Bazhong",
				city_zh: "巴中市",
				city_tc: "巴中市",
				prefecture: "direct administration"
			},
			{
				city_en: "Chengdu",
				city_zh: "成都市",
				city_tc: "成都市",
				prefecture: "direct administration"
			},
			{
				city_en: "Chongzhou",
				city_zh: "崇州市",
				city_tc: "崇州市",
				prefecture: "Chengdu"
			},
			{
				city_en: "Dazhou",
				city_zh: "达州市",
				city_tc: "達州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Deyang",
				city_zh: "德阳市",
				city_tc: "德陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Dujiangyan",
				city_zh: "都江堰市",
				city_tc: "都江堰市",
				prefecture: "Chengdu"
			},
			{
				city_en: "Emeishan",
				city_zh: "峨眉山市",
				city_tc: "峨眉山市",
				prefecture: "Leshan"
			},
			{
				city_en: "Guang'an",
				city_zh: "广安市",
				city_tc: "廣安市",
				prefecture: "direct administration"
			},
			{
				city_en: "Guanghan",
				city_zh: "广汉市",
				city_tc: "廣漢市",
				prefecture: "Deyang"
			},
			{
				city_en: "Guangyuan",
				city_zh: "广元市",
				city_tc: "廣元市",
				prefecture: "direct administration"
			},
			{
				city_en: "Huaying",
				city_zh: "华蓥市",
				city_tc: "華瑛市",
				prefecture: "Guang'an"
			},
			{
				city_en: "Jiangyou",
				city_zh: "江油市",
				city_tc: "江油市",
				prefecture: "Mianyang"
			},
			{
				city_en: "Jianyang",
				city_zh: "简阳市",
				city_tc: "簡陽市",
				prefecture: "Chengdu"
			},
			{
				city_en: "Kangding",
				city_zh: "康定市",
				city_tc: "康定市",
				prefecture: "Garzê"
			},
			{
				city_en: "Langzhong",
				city_zh: "阆中市",
				city_tc: "閬中市",
				prefecture: "Nanchong"
			},
			{
				city_en: "Leshan",
				city_zh: "乐山市",
				city_tc: "樂山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Longchang",
				city_zh: "隆昌市",
				city_tc: "隆昌市",
				prefecture: "Neijiang"
			},
			{
				city_en: "Luzhou",
				city_zh: "泸州市",
				city_tc: "瀘州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Mianzhu",
				city_zh: "绵竹市",
				city_tc: "綿竹市",
				prefecture: "Deyang"
			},
			{
				city_en: "Meishan",
				city_zh: "眉山市",
				city_tc: "眉山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Mianyang",
				city_zh: "绵阳市",
				city_tc: "綿陽市",
				prefecture: "direct administration"
			},
			{
				city_en: "Nanchong",
				city_zh: "南充市",
				city_tc: "南充市",
				prefecture: "direct administration"
			},
			{
				city_en: "Neijiang",
				city_zh: "内江市",
				city_tc: "內江市",
				prefecture: "direct administration"
			},
			{
				city_en: "Panzhihua",
				city_zh: "攀枝花市",
				city_tc: "攀枝花市",
				prefecture: "direct administration"
			},
			{
				city_en: "Pengzhou",
				city_zh: "彭州市",
				city_tc: "彭州市",
				prefecture: "Chengdu"
			},
			{
				city_en: "Qionglai",
				city_zh: "邛崃市",
				city_tc: "邛崃市",
				prefecture: "Chengdu"
			},
			{
				city_en: "Shifang",
				city_zh: "什邡市",
				city_tc: "什邡市",
				prefecture: "Deyang"
			},
			{
				city_en: "Suining",
				city_zh: "遂宁市",
				city_tc: "遂寧市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wanyuan",
				city_zh: "万源市",
				city_tc: "萬源市",
				prefecture: "Dazhou"
			},
			{
				city_en: "Xichang",
				city_zh: "西昌市",
				city_tc: "西昌市",
				prefecture: "Liangshan"
			},
			{
				city_en: "Ya'an",
				city_zh: "雅安市",
				city_tc: "雅安市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yibin",
				city_zh: "宜宾市",
				city_tc: "宜賓市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zigong",
				city_zh: "自贡市",
				city_tc: "自貢市",
				prefecture: "direct administration"
			},
			{
				city_en: "Ziyang",
				city_zh: "资阳市",
				city_tc: "資陽市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Tibet",
		province_zh: "西藏自治区",
		province_tc: "西藏自治區",
		cities: [
			{
				city_en: "Lhasa",
				city_zh: "拉萨市",
				city_tc: "拉薩市",
				prefecture: "direct administration"
			},
			{
				city_en: "Nagqu",
				city_zh: "那曲市",
				city_tc: "那曲市",
				prefecture: "direct administration"
			},
			{
				city_en: "Nyingchi",
				city_zh: "林芝市",
				city_tc: "林芝市",
				prefecture: "direct administration"
			},
			{
				city_en: "Qamdo",
				city_zh: "昌都市",
				city_tc: "昌都市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shannan",
				city_zh: "山南市",
				city_tc: "山南市",
				prefecture: "direct administration"
			},
			{
				city_en: "Xigazê",
				city_zh: "日喀则市",
				city_tc: "日喀則市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Xinjiang",
		province_zh: "新疆维吾尔自治区",
		province_tc: "新疆維吾爾自治區",
		cities: [
			{
				city_en: "Aksu",
				city_zh: "阿克苏市",
				city_tc: "阿克蘇市",
				prefecture: "Aksu"
			},
			{
				city_en: "Alashankou",
				city_zh: "阿拉山口市",
				city_tc: "阿拉山口市",
				prefecture: "Bortala"
			},
			{
				city_en: "Altay",
				city_zh: "阿勒泰市",
				city_tc: "阿勒泰市",
				prefecture: "Altay"
			},
			{
				city_en: "Aral",
				city_zh: "阿拉尔市",
				city_tc: "阿拉爾市",
				prefecture: "none"
			},
			{
				city_en: "Artux",
				city_zh: "阿图什市",
				city_tc: "阿圖什市",
				prefecture: "Kizilsu"
			},
			{
				city_en: "Beitun",
				city_zh: "北屯市",
				city_tc: "北屯市",
				prefecture: "none"
			},
			{
				city_en: "Bole",
				city_zh: "博乐市",
				city_tc: "博樂市",
				prefecture: "Bortala"
			},
			{
				city_en: "Changji",
				city_zh: "昌吉市",
				city_tc: "昌吉市",
				prefecture: "Changji"
			},
			{
				city_en: "Fukang",
				city_zh: "阜康市",
				city_tc: "阜康市",
				prefecture: "Changji"
			},
			{
				city_en: "Hami",
				city_zh: "哈密市",
				city_tc: "哈密市",
				prefecture: "direct administration"
			},
			{
				city_en: "Hotan",
				city_zh: "和田市",
				city_tc: "和田市",
				prefecture: "Hotan"
			},
			{
				city_en: "Karamay",
				city_zh: "克拉玛依市",
				city_tc: "克拉瑪依市",
				prefecture: "direct administration"
			},
			{
				city_en: "Kashgar",
				city_zh: "喀什市",
				city_tc: "喀什市",
				prefecture: "Kashgar"
			},
			{
				city_en: "Khorgas",
				city_zh: "霍尔果斯市",
				city_tc: "霍爾果斯市",
				prefecture: "Ili"
			},
			{
				city_en: "Kokdala",
				city_zh: "可克达拉市",
				city_tc: "可克達拉市",
				prefecture: "none"
			},
			{
				city_en: "Korla",
				city_zh: "库尔勒市",
				city_tc: "庫爾勒市",
				prefecture: "Bayingolin"
			},
			{
				city_en: "Kuytun",
				city_zh: "奎屯市",
				city_tc: "奎屯市",
				prefecture: "Ili"
			},
			{
				city_en: "Kunyu",
				city_zh: "昆玉市",
				city_tc: "昆玉市",
				prefecture: "none"
			},
			{
				city_en: "Shihezi",
				city_zh: "石河子市",
				city_tc: "石河子市",
				prefecture: "none"
			},
			{
				city_en: "Shuanghe",
				city_zh: "双河市",
				city_tc: "雙河市",
				prefecture: "none"
			},
			{
				city_en: "Tacheng",
				city_zh: "塔城市",
				city_tc: "塔城市",
				prefecture: "Tacheng"
			},
			{
				city_en: "Tiemenguan",
				city_zh: "铁门关市",
				city_tc: "鐵門關市",
				prefecture: "none"
			},
			{
				city_en: "Tumxuk",
				city_zh: "图木舒克市",
				city_tc: "圖木舒克市",
				prefecture: "none"
			},
			{
				city_en: "Turpan",
				city_zh: "吐鲁番市",
				city_tc: "吐魯番市",
				prefecture: "direct administration"
			},
			{
				city_en: "Ürümqi",
				city_zh: "乌鲁木齐市",
				city_tc: "烏魯木齊市",
				prefecture: "direct administration"
			},
			{
				city_en: "Wujiaqu",
				city_zh: "五家渠市",
				city_tc: "五家渠市",
				prefecture: "none"
			},
			{
				city_en: "Wusu",
				city_zh: "乌苏市",
				city_tc: "烏蘇市",
				prefecture: "Tacheng"
			},
			{
				city_en: "Yining",
				city_zh: "伊宁市",
				city_tc: "伊寧市",
				prefecture: "Ili"
			}
		]
	},
	{
		province_en: "Yunnan",
		province_zh: "云南省",
		province_tc: "雲南省",
		cities: [
			{
				city_en: "Anning",
				city_zh: "安宁市",
				city_tc: "安寧市",
				prefecture: "Kunming"
			},
			{
				city_en: "Baoshan",
				city_zh: "保山市",
				city_tc: "保山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Chuxiong",
				city_zh: "楚雄市",
				city_tc: "楚雄市",
				prefecture: "Chuxiong"
			},
			{
				city_en: "Dali",
				city_zh: "大理市",
				city_tc: "大理市",
				prefecture: "Dali"
			},
			{
				city_en: "Gejiu",
				city_zh: "个旧市",
				city_tc: "個舊市",
				prefecture: "Honghe"
			},
			{
				city_en: "Jinghong",
				city_zh: "景洪市",
				city_tc: "景洪市",
				prefecture: "Xishuangbanna"
			},
			{
				city_en: "Kaiyuan",
				city_zh: "开远市",
				city_tc: "開遠市",
				prefecture: "Honghe"
			},
			{
				city_en: "Kunming",
				city_zh: "昆明市",
				city_tc: "昆明市",
				prefecture: "direct administration"
			},
			{
				city_en: "Lincang",
				city_zh: "临沧市",
				city_tc: "臨滄市",
				prefecture: "direct administration"
			},
			{
				city_en: "Lijiang",
				city_zh: "丽江市",
				city_tc: "麗江市",
				prefecture: "direct administration"
			},
			{
				city_en: "Lushui",
				city_zh: "泸水市",
				city_tc: "瀘水市",
				prefecture: "Nujiang"
			},
			{
				city_en: "Mang",
				city_zh: "芒市",
				city_tc: "芒市",
				prefecture: "Dehong"
			},
			{
				city_en: "Mengzi",
				city_zh: "蒙自市",
				city_tc: "蒙自市",
				prefecture: "Honghe"
			},
			{
				city_en: "Mile",
				city_zh: "弥勒市",
				city_tc: "彌勒市",
				prefecture: "Honghe"
			},
			{
				city_en: "Pu'er",
				city_zh: "普洱市",
				city_tc: "普洱市",
				prefecture: "direct administration"
			},
			{
				city_en: "Qujing",
				city_zh: "曲靖市",
				city_tc: "曲靖市",
				prefecture: "direct administration"
			},
			{
				city_en: "Ruili",
				city_zh: "瑞丽市",
				city_tc: "瑞麗市",
				prefecture: "Dehong"
			},
			{
				city_en: "Shangri-La",
				city_zh: "香格里拉市",
				city_tc: "香格里拉市",
				prefecture: "Dêqên"
			},
			{
				city_en: "Tengchong",
				city_zh: "腾冲市",
				city_tc: "騰衝市",
				prefecture: "Baoshan"
			},
			{
				city_en: "Wenshan",
				city_zh: "文山市",
				city_tc: "文山市",
				prefecture: "Wenshan"
			},
			{
				city_en: "Xuanwei",
				city_zh: "宣威市",
				city_tc: "宣威市",
				prefecture: "Qujing"
			},
			{
				city_en: "Yuxi",
				city_zh: "玉溪市",
				city_tc: "玉溪市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhaotong",
				city_zh: "昭通市",
				city_tc: "昭通市",
				prefecture: "direct administration"
			}
		]
	},
	{
		province_en: "Zhejiang",
		province_zh: "浙江省",
		province_tc: "浙江省",
		cities: [
			{
				city_en: "Cixi",
				city_zh: "慈溪市",
				city_tc: "慈溪市",
				prefecture: "Ningbo"
			},
			{
				city_en: "Dongyang",
				city_zh: "东阳市",
				city_tc: "東陽市",
				prefecture: "Jinhua"
			},
			{
				city_en: "Haining",
				city_zh: "海宁市",
				city_tc: "海寧市",
				prefecture: "Jiaxing"
			},
			{
				city_en: "Hangzhou",
				city_zh: "杭州市",
				city_tc: "杭州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Huzhou",
				city_zh: "湖州市",
				city_tc: "湖州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jiande",
				city_zh: "建德市",
				city_tc: "建德市",
				prefecture: "Hangzhou"
			},
			{
				city_en: "Jiangshan",
				city_zh: "江山市",
				city_tc: "江山市",
				prefecture: "Quzhou"
			},
			{
				city_en: "Jiaxing",
				city_zh: "嘉兴市",
				city_tc: "嘉興市",
				prefecture: "direct administration"
			},
			{
				city_en: "Jinhua",
				city_zh: "金华市",
				city_tc: "金華市",
				prefecture: "direct administration"
			},
			{
				city_en: "Lanxi",
				city_zh: "兰溪市",
				city_tc: "蘭溪市",
				prefecture: "Jinhua"
			},
			{
				city_en: "Linhai",
				city_zh: "临海市",
				city_tc: "臨海市",
				prefecture: "Taizhou"
			},
			{
				city_en: "Lishui",
				city_zh: "丽水市",
				city_tc: "麗水市",
				prefecture: "direct administration"
			},
			{
				city_en: "Longquan",
				city_zh: "龙泉市",
				city_tc: "龍泉市",
				prefecture: "Lishui"
			},
			{
				city_en: "Ningbo",
				city_zh: "宁波市",
				city_tc: "寧波市",
				prefecture: "direct administration"
			},
			{
				city_en: "Pinghu",
				city_zh: "平湖市",
				city_tc: "平湖市",
				prefecture: "Jiaxing"
			},
			{
				city_en: "Quzhou",
				city_zh: "衢州市",
				city_tc: "衢州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Ruian",
				city_zh: "瑞安市",
				city_tc: "瑞安市",
				prefecture: "Wenzhou"
			},
			{
				city_en: "Shaoxing",
				city_zh: "绍兴市",
				city_tc: "紹興市",
				prefecture: "direct administration"
			},
			{
				city_en: "Shengzhou",
				city_zh: "嵊州市",
				city_tc: "嵊州市",
				prefecture: "Shaoxing"
			},
			{
				city_en: "Taizhou",
				city_zh: "台州市",
				city_tc: "臺州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Tongxiang",
				city_zh: "桐乡市",
				city_tc: "桐鄉市",
				prefecture: "Jiaxing"
			},
			{
				city_en: "Wenling",
				city_zh: "温岭市",
				city_tc: "溫嶺市",
				prefecture: "Taizhou"
			},
			{
				city_en: "Wenzhou",
				city_zh: "温州市",
				city_tc: "溫州市",
				prefecture: "direct administration"
			},
			{
				city_en: "Yiwu",
				city_zh: "义乌市",
				city_tc: "義烏市",
				prefecture: "Jinhua"
			},
			{
				city_en: "Yongkang",
				city_zh: "永康市",
				city_tc: "永康市",
				prefecture: "Jinhua"
			},
			{
				city_en: "Yueqing",
				city_zh: "乐清市",
				city_tc: "樂清市",
				prefecture: "Wenzhou"
			},
			{
				city_en: "Yuhuan",
				city_zh: "玉环市",
				city_tc: "玉環市",
				prefecture: "Taizhou"
			},
			{
				city_en: "Yuyao",
				city_zh: "余姚市",
				city_tc: "余姚市",
				prefecture: "Ningbo"
			},
			{
				city_en: "Zhoushan",
				city_zh: "舟山市",
				city_tc: "舟山市",
				prefecture: "direct administration"
			},
			{
				city_en: "Zhuji",
				city_zh: "诸暨市",
				city_tc: "諸暨市",
				prefecture: "Shaoxing"
			}
		]
	}
];

const specialRegion: string[] = [
	'香港', 
	'澳门',
	'台湾',
	'北京市',
	'天津市',
	'上海市',
	'重庆市'

]

const notInCNRegion: string[] = [
	'香港', 
	'澳门',
	'台湾',
]

export { cnRegion, specialRegion, notInCNRegion };

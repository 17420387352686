import { useEffect, useState } from 'react';
import PageContainer from '@/components/Container/PageContainer';
import { useTranslation } from 'react-i18next';
import { CompanyInfoLine, PersonalInfoLine } from '@/components/RegistrationStepLine/StepLine';
import PersonalInfo from '@/components/Account/PersonalInfo';
import { Box, Button as DefaultBtn } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '../style';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import useUserDetail from '@/hooks/useUserDetail';

const PersonalInfoEdit = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const auth = useAppSelector(getAuthSelector);
  const userDetail = useUserDetail({ activeStep: activeStep });

  useEffect(() => {
    if (auth.userData.role === 1) {
      setActiveStep(CompanyInfoLine.findIndex(item => 'Personal Information' === item))
    }else{
      setActiveStep(PersonalInfoLine.findIndex(item => 'Personal Information' === item))
    }
  }, [])

  const ButtonGroup = (
    <Box style={{ marginTop: 30 }}>
      <Button>
        {t('buttons.submit')}
      </Button>
      <DefaultBtn type='button' color="primary" onClick={() => navigate('/profile/edit')} style={{ fontSize: 20, color: '#0B319D', padding: '1px 40px', border: '1px solid #0B319D', marginLeft: 15 }}>
        {t('buttons.back')}
      </DefaultBtn>
    </Box>
  )

  return (
    <PageContainer title={'Personal Information'}>
      <div style={{ display: 'flex' }}>
        <PersonalInfo ButtonGroup={ButtonGroup} userDetail={userDetail} />
      </div>
    </PageContainer>
  );
};

export default PersonalInfoEdit;

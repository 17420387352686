import { useState, useEffect } from 'react';
import { isRouteErrorResponse, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../styles';
import { Box, Flex, LoadingPage } from '@/layout';
import { Button } from '@/components/UI/Button';
import { InputWrapper, InputFileWrapper, InputFileLabel } from '@/components/Container/AuthContainer/styles';
import { ErrorMessage, Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { CloudUpload } from '@/components/Icon';
import { FormSelect } from '@/components/UI/Select';
import { Modal, TextField } from '@mui/material';
import { casCreateCertApplyOrder, sendCertApplyOrder } from '@/api/eCert.api';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import useResize from '@/hooks/useResize';
import { TCTermsMarkdown } from '@/components/UI/Terms';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { H2 } from '@/components/UI/Typography/Heading';
import { errorHandler } from '@/utils/toastHandler';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setStatus: React.Dispatch<React.SetStateAction<number>>;
  checkLevel: () => boolean;
  previousData?: ECertApplys;
}

const RegistrationForm = ({ previousData, checkLevel, setCurrentStep, setActiveStep, setStatus }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { userData } = useAppSelector(getAuthSelector);
  const [searchParmas, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTCModal, setShowTCModal] = useState(false);
  const [TC, setTC] = useState('');
  const [termsInfo, setTermsInfo] = useState(true);
  const { isMobile } = useResize();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    setError,
    formState: { errors },
  } = useForm<ECertAllplicationRequest>();

  const prevHandler = () => {
    const type = searchParmas.get('type');
    setCurrentStep('2');
    setActiveStep(1);
    setSearchParams({ step: '2', type: type ? type : 'new' });
  };

  const nextHandler = () => {
    const type = searchParmas.get('type');
    setCurrentStep('5');
    setActiveStep(4);
    setSearchParams({ step: '5', type: type ? type : 'new' });
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    setShowTCModal(false);

    // 上傳前先確認data.applyFile是否為空, 若為空且previousData陣列不為空則代表使用者已經填過資料
    if (previousData) {
      sendCertApply(previousData.id.toString());
      return;
    }

    try {
      const res = (await casCreateCertApplyOrder(data)).data;
      if (res.success) {
        sendCertApply(res.data.id);
      }
    } catch (error) {
      console.log(error);
      errorHandler(t('error.server'));
    }
  });

  const sendCertApply = async (applyId: string) => {
    // 檢查當前使用者的user_feature.ECertApplicantTimes是否為0, 若為0則導向至"/cart?item=14"頁面進行購買
    const { ECertApplicantTimes } = userData.user_feature;
    if (ECertApplicantTimes === 0) {
      // 取得當前網址
      const currentUrl = `${location.pathname}${location.search}`;
      navigate(`/cart?item=14&certId=${applyId}`, { state: { from: currentUrl } });
      return false;
    }

    try {
      await sendCertApplyOrder({ applyId });
      nextHandler();
    } catch (err) {
      errorHandler(t('error.server'));
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!checkLevel()) return;
    if (previousData) {
      setValue('orgEnglishName', previousData.orgEnglishName);
      setValue('orgChineseName', previousData.orgChineseName);
      setValue('contactEnglishName', previousData.contactEnglishName);
      setValue('contactChineseName', previousData.contactChineseName);
      setValue('contactAddress', previousData.contactAddress);
      setValue('emailAddress', previousData.emailAddress);
      setValue('phoneNumber', previousData.phoneNumber);
    }
    fetch(TCTermsMarkdown)
      .then((res) => res.text())
      .then((md) => {
        setTC(md);
      });
  }, [t]);

  const termHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { currentTarget } = e;

    if (currentTarget.scrollHeight - currentTarget.scrollTop <= currentTarget.clientHeight + 2) {
      setTermsInfo(false);
    }
  };

  return (
    <Wrapper>
      <LoadingPage isLoading={isLoading} />
      <Modal
        open={showTCModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: 'flex' }}
      >
        <div
          style={{
            margin: 'auto',
            width: '100%',
            maxWidth: '930px',
            backgroundColor: '#fff',
            padding: isMobile ? '15px 25px' : '30px 50px',
            borderRadius: '40px 40px 40px 0px',
          }}
        >
          <H2>{t('pages.auth.signUp.modal.terms.t&c.title')}</H2>
          <span style={{ font: 'Roboto 400 16px', lineHeight: '30px', color: 'red' }}>
            {t('pages.auth.signUp.modal.terms.t&c.title')}
          </span>
          <div
            className="content"
            style={{
              marginTop: '25px',
              height: '40vh',
              overflowY: 'auto',
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: isMobile ? '16px' : '20px',
              color: '#616161',
              lineHeight: '30px',
            }}
            onScroll={termHandler}
          >
            <ReactMarkdown children={TC} remarkPlugins={[remarkGfm]} />
          </div>
          <Flex
            gridGap={isMobile ? 20 : 40}
            marginTop={'28px'}
            alignItems={'center'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Button
              width={isMobile ? '100%' : 'unset'}
              type="button"
              onClick={onSubmit}
              size={'lg'}
              variant={'primary'}
              rounded={false}
              disabled={termsInfo}
            >
              {t('buttons.agree')}
            </Button>
            <Button
              width={isMobile ? '100%' : 'unset'}
              color="primary"
              onClick={() => setShowTCModal(false)}
              style={{ textDecoration: 'underline' }}
            >
              {t('buttons.back')}
            </Button>
          </Flex>
        </div>
      </Modal>
      <Box>
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'orgEnglishName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={`${t('form.orgEnglishName.label')} *`}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={{ required: t('required.input', { input: t('form.orgEnglishName.label') }) }}
          />
        </InputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'orgChineseName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={`${t('form.orgChineseName.label')} *`}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={{ required: t('required.input', { input: t('form.orgChineseName.label') }) }}
          />
        </InputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'contactEnglishName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={`${t('form.contactEnglishName.label')} *`}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={{ required: t('required.input', { input: t('form.contactEnglishName.label') }) }}
          />
        </InputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'contactChineseName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={`${t('form.contactChineseName.label')} *`}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={{ required: t('required.input', { input: t('form.contactChineseName.label') }) }}
          />
        </InputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'contactAddress'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={`${t('form.contactAddress.label')} *`}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={{ required: t('required.input', { input: t('form.contactAddress.label') }) }}
          />
        </InputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'emailAddress'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={`${t('form.emailAddress.label')} *`}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={{ required: t('required.input', { input: t('form.emailAddress.label') }) }}
          />
        </InputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'phoneNumber'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={`${t('form.phoneNumber.label')} *`}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={{ required: t('required.input', { input: t('form.phoneNumber.label') }) }}
          />
        </InputWrapper>
      </Box>

      <Flex marginTop={50}>
        <Button
          size={'lg'}
          type="button"
          onClick={() => setShowTCModal(true)}
          variant={'primary'}
          rounded={false}
          marginRight={40}
        >
          {t('buttons.next')}
        </Button>
        <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
          {t('buttons.back')}
        </Button>
      </Flex>
    </Wrapper>
  );
};

export default RegistrationForm;

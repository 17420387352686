import type { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useLEIValidation = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const basicInfo = ({ legalNameLang = 'en' }: { legalNameLang?: string }) => {
    const legalNameLanguage: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.basicInfo.country.label') }),
    };

    const legalName: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.basicInfo.legalName.label') }),
      maxLength: {
        value: 200,
        message: t('required.maxLength', { input: t('LEIForm.basicInfo.legalName.label'), length: 200 }),
      },
      pattern: {
        value: legalNameLang === 'en' ? /[a-zA-Z]/ : /[\u4E00-\u9FA5]+/,
        message: t('pattern.legalNameLanguage'),
      },
    };

    const entityLegalCategory: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.basicInfo.entityLegalCategory.label') }),
    };

    const headerLEI: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.basicInfo.headerQuarterLEI.label') }),
      maxLength: {
        value: 20,
        message: t('required.maxLength', { input: t('LEIForm.basicInfo.headerQuarterLEI.label'), length: 200 }),
      },
    };

    const entityCreationDate: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.basicInfo.entityCreationDate.label') }),
    };

    const legalUseCategory: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.basicInfo.legalUseCategory.label') }),
    };

    const usePurpose: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.basicInfo.usePurpose.label') }),
      maxLength: {
        value: 20,
        message: t('required.maxLength', { input: t('LEIForm.basicInfo.usePurpose.label'), length: 20 }),
      },
    };

    const corporation: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.basicInfo.corporation.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.basicInfo.corporation.label'), length: 100 }),
      },
    };

    const contactName: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.contactInfo.contactName.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.contactInfo.contactName.label'), length: 100 }),
      },
    };

    const contactPhone: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.contactInfo.contactPhone.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.contactInfo.contactPhone.label'), length: 100 }),
      },
    };

    const contactMail: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.contactInfo.contactMail.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.contactInfo.contactMail.label'), length: 100 }),
      },
    };

    const contactDepartment: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.contactInfo.contactDepartment.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.contactInfo.contactDepartment.label'), length: 100 }),
      },
    };

    const contactFix: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.contactInfo.contactFix.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.contactInfo.contactFix.label'), length: 100 }),
      },
    };

    const countryCode: RegisterOptions = {
      required: t('required.input', { input: t('info.countryCode') }),
    };

    const contactPosition: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.contactInfo.contactPosition.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.contactInfo.contactPosition.label'), length: 100 }),
      },
    };

    const otherLegalName: RegisterOptions = {
      maxLength: {
        value: 200,
        message: t('required.maxLength', { input: t('LEIForm.basicInfo.otherLegalName.label'), length: 200 }),
      },
    };

    return {
      legalNameLanguage,
      legalName,
      entityLegalCategory,
      headerLEI,
      entityCreationDate,
      legalUseCategory,
      usePurpose,
      corporation,
      contactName,
      contactPhone,
      contactMail,
      contactDepartment,
      contactFix,
      contactPosition,
      otherLegalName,
      countryCode,
    };
  };

  const addressForm = () => {
    const language: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.Address.language.label') }),
      maxLength: {
        value: 20,
        message: t('required.maxLength', { input: t('LEIForm.Address.language.label'), length: 20 }),
      },
    };

    const postCode: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.Address.postCode.label') }),
      maxLength: {
        value: 10,
        message: t('required.maxLength', { input: t('LEIForm.Address.postCode.label'), length: 10 }),
      },
    };

    const country: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.Address.country.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.Address.country.label'), length: 100 }),
      },
    };

    const province: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.Address.province.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.Address.province.label'), length: 100 }),
      },
    };

    const city: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.Address.city.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.Address.city.label'), length: 100 }),
      },
    };

    const address: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.Address.address.label') }),
      maxLength: {
        value: 300,
        message: t('required.maxLength', { input: t('LEIForm.Address.address.label'), length: 300 }),
      },
    };

    return {
      language,
      postCode,
      country,
      province,
      city,
      address,
    };
  };

  const legalForm = () => {
    const legalFormCountry: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.legalForm.legalFormCountry.label') }),
      maxLength: {
        value: 100,
        message: t('required.maxLength', { input: t('LEIForm.legalForm.legalFormCountry.label'), length: 100 }),
      },
    };

    const legalFormLanguage: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.legalForm.legalFormLanguage.label') }),
      maxLength: {
        value: 10,
        message: t('required.maxLength', { input: t('LEIForm.legalForm.legalFormLanguage.label'), length: 10 }),
      },
    };

    const legalFormCode: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.legalForm.otherEntityLegalForm.label') }),
      maxLength: {
        value: 50,
        message: t('required.maxLength', { input: t('LEIForm.legalForm.otherEntityLegalForm.label'), length: 50 }),
      },
    };

    return {
      legalFormCountry,
      legalFormLanguage,
      legalFormCode,
    };
  };

  const businessInfo = () => {
    const registrationAuthorityCode: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.businessInfo.registrationAuthorityCode.label') }),
      maxLength: {
        value: 50,
        message: t('required.maxLength', {
          input: t('LEIForm.businessInfo.registrationAuthorityCode.label'),
          length: 50,
        }),
      },
    };

    const unifiedSocialCreditIdentifier: RegisterOptions = {
      required: t('required.input', {
        input: t('LEIForm.businessInfo.unifiedSocialCreditIdentifier.label'),
      })
    };

    const businessRegistrationCertificateNumber: RegisterOptions = {
      required: t('required.input', {
        input: t('LEIForm.businessInfo.businessRegistrationCertificateNumber.label'),
      }),
      maxLength: {
        value: 50,
        message: t('required.maxLength', {
          input: t('LEIForm.businessInfo.businessRegistrationCertificateNumber.label'),
          length: 50,
        }),
      },
    };

    const businessRegistrationOffice: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.businessInfo.businessRegistrationOffice.label') }),
      maxLength: {
        value: 200,
        message: t('required.maxLength', {
          input: t('LEIForm.businessInfo.businessRegistrationOffice.label'),
          length: 200,
        }),
      },
    };

    const effectiveDateOfBusinessRegistrationCertificate: RegisterOptions = {
      required: t('required.input', {
        input: t('LEIForm.businessInfo.effectiveDateOfBusinessRegistrationCertificate.label'),
      }),
    };

    const financialInstitutionCode: RegisterOptions = {
      maxLength: {
        value: 14,
        message: t('required.maxLength', {
          input: t('LEIForm.financialInstitutionCode.financialInstitutionCode.label'),
          length: 14,
        }),
      },
      minLength: {
        value: 14,
        message: t('required.length', {
          input: t('LEIForm.financialInstitutionCode.financialInstitutionCode.label'),
          length: 14,
        }),
      },
    };

    return {
      registrationAuthorityCode,
      unifiedSocialCreditIdentifier,
      businessRegistrationCertificateNumber,
      businessRegistrationOffice,
      effectiveDateOfBusinessRegistrationCertificate,
      financialInstitutionCode,
    };
  };

  const relationshipForm = () => {
    const parentLEICode: RegisterOptions = {
      required: t('required.input', { input: t('LEI') }),
      maxLength: {
        value: 20,
        message: t('required.maxLength', {
          input: t('LEI'),
          length: 20,
        }),
      },
    };

    const checkFileType: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.parent.checkFileType.label') }),
    };

    const criterionType: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.parent.criterionType.label') }),
    };

    const relationShipStartDate: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.parent.relationShipStartDate.label') }),
    };

    const accountStartTime: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.parent.accountStartTime.label') }),
    };

    const accountEndTime: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.parent.accountEndTime.label') }),
    };

    const docStartTime: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.parent.docStartTime.label') }),
    };

    const docEndTime: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.parent.docEndTime.label') }),
    };

    const fileCheckURL: RegisterOptions = {
      required: t('required.input', { input: t('LEIForm.parent.fileCheckUrl.label') }),
      maxLength: {
        value: 200,
        message: t('required.maxLength', {
          input: t('LEIForm.parent.fileCheckUrl.label'),
          length: 200,
        }),
      },
    };

    const quantitativeAttributes: RegisterOptions = {
      maxLength: {
        value: 200,
        message: t('required.maxLength', {
          input: t('LEIForm.parent.quantitativeAttributes.label'),
          length: 200,
        }),
      },
    };

    return {
      parentLEICode,
      checkFileType,
      criterionType,
      relationShipStartDate,
      accountStartTime,
      accountEndTime,
      docStartTime,
      docEndTime,
      fileCheckURL,
      quantitativeAttributes,
    };
  };

  return {
    basicInfo,
    addressForm,
    legalForm,
    businessInfo,
    relationshipForm,
  };
};

export default useLEIValidation;

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { InputWrapper, MultiInputWrapper, FormTitle } from '@/components/Container/AuthContainer/styles';
import { FormSelect } from '@/components/UI/Select';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Wrapper } from '../../styles';
import { Text } from '@/components/UI/Typography/Text';
import { useSearchParams } from 'react-router-dom';
import TabsButton from '../../TabsButton';
import { convertChinaArea, convertChinaCity, convertLEIOptions, convertLocations } from '@/utils/convert';
import useLEIValidation from '@/hooks/requireSchema/useLEIValidation';
import useCurrentLang from '@/hooks/useCurrentLang';
import { Box, Flex } from '@/layout';
import { theme } from '@/theme/Theme';
import LEICountryData from '../LEICountryData';

interface ProvinceCityOptions {
  registerAddress: {
    province: string[];
    city: string[];
  };
  officeAddress: {
    province: string[];
    city: string[];
  };
  otherRegisterAddress: {
    province: string[];
    city: string[];
  };
  otherOfficeAddress: {
    province: string[];
    city: string[];
  };
}

const Address = ({ data, setData, userDetail, setSelectedTab, options }: TabFormRequire) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const [searchParmas, setSearchParams] = useSearchParams();
  const { addressForm } = useLEIValidation();
  const [registerAddressLock, setRegisterAddressLock] = useState<boolean>(true);
  const [officeAddressLock, setOfficeAddressLock] = useState<boolean>(true);
  const [otherRegisterAddressLock, setOtherRegisterAddressLock] = useState<boolean>(true);
  const [otherOfficeAddressLock, setOtherOfficeAddressLock] = useState<boolean>(true);

  // 只取convertLEIOptions(lang, options?.Language)的第一和第二個資料
  const currentAllowLanguage = convertLEIOptions(lang, options?.Language).slice(0, 2);

  const {
    reset,
    resetField,
    handleSubmit,
    control,
    watch,
    formState: { dirtyFields },
    setValue,
    register,
    setError,
    formState: { errors },
  } = useForm<AddressFormData>();

  const onSubmit = handleSubmit(async (formData) => {
    setData({ ...data, address: formData });
    setSelectedTab(3);
    setSearchParams({ step: '2', tab: '3' });
  });
  
  const [otherRegisterAvaliavle, setOtherRegisterAvaliavle] = useState<boolean>(false);
  const [sameAsRegisterAddress, setSameAsRegisterAddress] = useState<boolean>(false);
  const [otherOfficeAddressAvaliavle, setOtherOfficeAddressAvaliavle] = useState<boolean>(false);

  register('otherOfficeAddress', { disabled: false });
  register('otherRegisterAddress', { disabled: false });

  useEffect(() => {
    if (otherOfficeAddressAvaliavle) {
      register('otherOfficeAddress.Language', addressForm().language);
      register('otherOfficeAddress.PostCode', addressForm().postCode);
      register('otherOfficeAddress.Country', addressForm().country);
      register('otherOfficeAddress.Province', addressForm().province);
      register('otherOfficeAddress.City', addressForm().city);
      register('otherOfficeAddress.Address', addressForm().address);
    } else {
      register('otherOfficeAddress.Language', { required: false });
      register('otherOfficeAddress.PostCode', { required: false });
      register('otherOfficeAddress.Country', { required: false });
      register('otherOfficeAddress.Province', { required: false });
      register('otherOfficeAddress.City', { required: false });
      register('otherOfficeAddress.Address', { required: false });
      resetField('otherOfficeAddress');
    }
  }, [otherOfficeAddressAvaliavle]);

  useEffect(() => {
    if (otherRegisterAvaliavle) {
      register('otherRegisterAddress.Language', addressForm().language);
      register('otherRegisterAddress.PostCode', addressForm().postCode);
      register('otherRegisterAddress.Country', addressForm().country);
      register('otherRegisterAddress.Province', addressForm().province);
      register('otherRegisterAddress.City', addressForm().city);
      register('otherRegisterAddress.Address', addressForm().address);
    } else {
      register('otherRegisterAddress.Language', { required: false });
      register('otherRegisterAddress.PostCode', { required: false });
      register('otherRegisterAddress.Country', { required: false });
      register('otherRegisterAddress.Province', { required: false });
      register('otherRegisterAddress.City', { required: false });
      register('otherRegisterAddress.Address', { required: false });
      resetField('otherRegisterAddress');
    }
  }, [otherRegisterAvaliavle]);

  const registerCountry = watch('registerAddress.Country');
  const officeCountry = watch('officeAddress.Country');
  const otherRegisterCountry = watch('otherRegisterAddress.Country');
  const otherOfficeCountry = watch('otherOfficeAddress.Country');

  // 監測如果Country是China的話, Province和City都要變成必填, 否則不必填
  useEffect(() => {
    // 若資料為初次回填則不執行
    if (Object.keys(dirtyFields).length === 0) return;

    const registerAddressData = watch('registerAddress');

    if (otherRegisterAvaliavle) {
      setValue('otherRegisterAddress.Country', registerAddressData.Country);
    }

    if (registerCountry === 'HK') {
      setValue(
        'registerAddress.Province',
        LEICountryData.HK[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].Province
      );
      setValue(
        'registerAddress.City',
        LEICountryData.HK[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].City
      );
      setValue(
        'registerAddress.PostCode',
        LEICountryData.HK[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].PostCode
      );
      setRegisterAddressLock(true);
      return;
    }

    if (registerCountry === 'MO') {
      setValue(
        'registerAddress.Province',
        LEICountryData.MO[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].Province
      );
      setValue(
        'registerAddress.City',
        LEICountryData.MO[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].City
      );
      setValue(
        'registerAddress.PostCode',
        LEICountryData.MO[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].PostCode
      );
      setRegisterAddressLock(true);
      return;
    }

    setRegisterAddressLock(false);
    setValue('registerAddress.Province', '');
    setValue('registerAddress.City', '');
  }, [registerCountry]);

  useEffect(() => {
    if (Object.keys(dirtyFields).length === 0) return;
    if (sameAsRegisterAddress) return;

    const officeAddressData = watch('officeAddress');

    if (otherOfficeAddressAvaliavle) {
      setValue('otherOfficeAddress.Country', officeAddressData.Country);
    }

    if (officeCountry === 'HK') {
      setValue(
        'officeAddress.Province',
        LEICountryData.HK[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].Province
      );
      setValue(
        'officeAddress.City',
        LEICountryData.HK[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].City
      );
      setValue(
        'officeAddress.PostCode',
        LEICountryData.HK[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].PostCode
      );
      setOfficeAddressLock(true);
      return;
    }

    if (officeCountry === 'MO') {
      setValue(
        'officeAddress.Province',
        LEICountryData.MO[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].Province
      );
      setValue(
        'officeAddress.City',
        LEICountryData.MO[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].City
      );
      setValue(
        'officeAddress.PostCode',
        LEICountryData.MO[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].PostCode
      );
      setOfficeAddressLock(true);
      return;
    }

    setOfficeAddressLock(false);

    setValue('officeAddress.Province', '');
    setValue('officeAddress.City', '');
  }, [officeCountry]);

  useEffect(() => {
    if (!otherRegisterAvaliavle) return;

    const registerAddressData = watch('registerAddress');

    if (registerAddressData.Country === 'HK' || registerAddressData.Country === 'MO') {
      setValue('otherRegisterAddress.PostCode', registerAddressData.PostCode);
      setValue(
        'otherRegisterAddress.Province',
        LEICountryData[registerAddressData.Country][registerAddressData.Language === 'zh' ? 'en' : 'sc'].Province
      );
      setValue(
        'otherRegisterAddress.City',
        LEICountryData[registerAddressData.Country][registerAddressData.Language === 'zh' ? 'en' : 'sc'].City
      );
      return;
    }

    setValue('otherRegisterAddress.Province', registerAddressData.Province);
    setValue('otherRegisterAddress.City', registerAddressData.City);
  }, [otherRegisterCountry]);

  useEffect(() => {
    if (!otherOfficeAddressAvaliavle) return;

    const officeAddressData = watch('officeAddress');

    if (officeAddressData.Country === 'HK' || officeAddressData.Country === 'MO') {
      setValue('otherOfficeAddress.PostCode', officeAddressData.PostCode);
      setValue(
        'otherOfficeAddress.Province',
        LEICountryData[officeAddressData.Country][officeAddressData.Language === 'zh' ? 'en' : 'sc'].Province
      );
      setValue(
        'otherOfficeAddress.City',
        LEICountryData[officeAddressData.Country][officeAddressData.Language === 'zh' ? 'en' : 'sc'].City
      );
      return;
    }
    setValue('otherOfficeAddress.Province', officeAddressData.Province);
    setValue('otherOfficeAddress.City', officeAddressData.City);
  }, [otherOfficeCountry]);

  const handleOtherRegisterAvaliavle = () => {
    setOtherRegisterAvaliavle(!otherRegisterAvaliavle);
    setValue('otherRegisterAddressAvaliavle', !otherRegisterAvaliavle);
    if (!otherRegisterAvaliavle) {
      const registerAddressData = watch('registerAddress');
      setValue('otherRegisterAddress.Language', registerAddressData.Language === 'en' ? 'zh' : 'en');
      setValue('otherRegisterAddress.Country', registerAddressData.Country);
      setValue('otherRegisterAddress.PostCode', registerAddressData.PostCode);
    } else {
      resetField('otherRegisterAddress.Language');
      resetField('otherRegisterAddress.PostCode');
      resetField('otherRegisterAddress.Country');
      resetField('otherRegisterAddress.Province');
      resetField('otherRegisterAddress.City');
      resetField('otherRegisterAddress.Address');
    }
  };

  const handleSameAsRegisterAddress = () => {
    setSameAsRegisterAddress(!sameAsRegisterAddress);
    setValue('sameAddress', !sameAsRegisterAddress);
  };

  const handleOtherOfficeAddressAvaliavle = () => {
    setOtherOfficeAddressAvaliavle(!otherOfficeAddressAvaliavle);
    setValue('otherOfficeAddressAvaliavle', !otherOfficeAddressAvaliavle);
    if (!otherOfficeAddressAvaliavle) {
      const officeAddressData = watch('officeAddress');
      setValue('otherOfficeAddress.Language', officeAddressData.Language === 'en' ? 'zh' : 'en');
      setValue('otherOfficeAddress.Country', officeAddressData.Country);
      setValue('otherOfficeAddress.PostCode', officeAddressData.PostCode);
    } else {
      resetField('otherOfficeAddress.Language');
      resetField('otherOfficeAddress.PostCode');
      resetField('otherOfficeAddress.Country');
      resetField('otherOfficeAddress.Province');
      resetField('otherOfficeAddress.City');
      resetField('otherOfficeAddress.Address');
    }
  };

  const watchRegisterLang = watch('registerAddress.Language');
  const watchOfficeLang = watch('officeAddress.Language');

  useEffect(() => {
    const registerAddressData = watch('registerAddress');
    if (registerAddressData.Country === 'HK' || registerAddressData.Country === 'MO') {
      setValue('registerAddress.PostCode', registerAddressData.PostCode);
      setValue(
        'registerAddress.Province',
        LEICountryData[registerAddressData.Country][watchRegisterLang === 'en' ? 'en' : 'sc'].Province
      );
      setValue(
        'registerAddress.City',
        LEICountryData[registerAddressData.Country][watchRegisterLang === 'en' ? 'en' : 'sc'].City
      );

      if (!otherRegisterAvaliavle) return;
      setValue('otherRegisterAddress.Language', watchRegisterLang === 'en' ? 'zh' : 'en');
      setValue('otherRegisterAddress.PostCode', registerAddressData.PostCode);
      setValue(
        'otherRegisterAddress.Province',
        LEICountryData[registerAddressData.Country][watchRegisterLang === 'zh' ? 'en' : 'sc'].Province
      );
      setValue(
        'otherRegisterAddress.City',
        LEICountryData[registerAddressData.Country][watchRegisterLang === 'zh' ? 'en' : 'sc'].City
      );
      return;
    }
  }, [watchRegisterLang]);

  useEffect(() => {
    const officeAddressData = watch('officeAddress');
    if (officeAddressData.Country === 'HK' || officeAddressData.Country === 'MO') {
      setValue('officeAddress.PostCode', officeAddressData.PostCode);
      setValue(
        'officeAddress.Province',
        LEICountryData[officeAddressData.Country][watchOfficeLang === 'en' ? 'en' : 'sc'].Province
      );
      setValue(
        'officeAddress.City',
        LEICountryData[officeAddressData.Country][watchOfficeLang === 'en' ? 'en' : 'sc'].City
      );

      if (!otherOfficeAddressAvaliavle) return;
      setValue('otherOfficeAddress.Language', watchOfficeLang === 'en' ? 'zh' : 'en');
      setValue('otherOfficeAddress.PostCode', officeAddressData.PostCode);
      setValue(
        'otherOfficeAddress.Province',
        LEICountryData[officeAddressData.Country][watchOfficeLang === 'zh' ? 'en' : 'sc'].Province
      );
      setValue(
        'otherOfficeAddress.City',
        LEICountryData[officeAddressData.Country][watchOfficeLang === 'zh' ? 'en' : 'sc'].City
      );
      return;
    }
  }, [watchOfficeLang]);

  useEffect(() => {
    // errors有改變時, 將畫面移到最上方
    if (Object.keys(errors).length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errors]);

  // 監測sameAsRegisterAddress
  useEffect(() => {
    if (sameAsRegisterAddress) {
      const address = watch('registerAddress');
      setValue('officeAddress.Address', address.Address);
      setValue('officeAddress.City', address.City);
      setValue('officeAddress.Country', address.Country);
      setValue('officeAddress.Language', address.Language);
      setValue('officeAddress.PostCode', address.PostCode);
      setValue('officeAddress.Province', address.Province);
    }
  }, [sameAsRegisterAddress]);

  useEffect(() => {
    if (!data.address) return;
    setValue('registerAddress', data.address.registerAddress);
    setValue('officeAddress', data.address.officeAddress);
    setValue('sameAddress', data.address.sameAddress);
    if (data.address.otherRegisterAddressAvaliavle) {
      setOtherRegisterAvaliavle(true);
      setValue('otherRegisterAddressAvaliavle', data.address.otherRegisterAddressAvaliavle);
      setValue('otherRegisterAddress.Language', data.address.otherRegisterAddress.Language);
      setValue('otherRegisterAddress.PostCode', data.address.otherRegisterAddress.PostCode);
      setValue('otherRegisterAddress.Country', data.address.otherRegisterAddress.Country);
      setValue('otherRegisterAddress.Province', data.address.otherRegisterAddress.Province);
      setValue('otherRegisterAddress.City', data.address.otherRegisterAddress.City);
      setValue('otherRegisterAddress.Address', data.address.otherRegisterAddress.Address);
    }
    if (data.address.otherOfficeAddressAvaliavle) {
      setOtherOfficeAddressAvaliavle(true);
      setValue('otherOfficeAddressAvaliavle', data.address.otherOfficeAddressAvaliavle);
      setValue('otherOfficeAddress.Language', data.address.otherOfficeAddress.Language);
      setValue('otherOfficeAddress.PostCode', data.address.otherOfficeAddress.PostCode);
      setValue('otherOfficeAddress.Country', data.address.otherOfficeAddress.Country);
      setValue('otherOfficeAddress.Province', data.address.otherOfficeAddress.Province);
      setValue('otherOfficeAddress.City', data.address.otherOfficeAddress.City);
      setValue('otherOfficeAddress.Address', data.address.otherOfficeAddress.Address);
    }
    if (data.address.sameAddress) {
      setSameAsRegisterAddress(true);
      setValue('sameAddress', data.address.sameAddress);
    }
  }, [searchParmas]);

  return (
    <Wrapper onSubmit={onSubmit}>
      <FormTitle>{t('info.registerAddress')}</FormTitle>
      <Flex gridGap={30}>
        <Flex width={'100%'} flexDirection={'column'} gridGap={10}></Flex>
        <Flex width={'100%'} flexDirection={'column'} gridGap={10}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: '#999999',
                  '&.Mui-checked': {
                    color: theme.colors.secondary500,
                  },
                }}
                onChange={handleOtherRegisterAvaliavle}
                name="otherRegisterAddressAvaliavle"
                checked={otherRegisterAvaliavle}
              />
            }
            label={
              <Text>
                {watch('registerAddress.Language') && watch('registerAddress.Language') === 'zh'
                  ? t('LEIForm.Address.registerAvalable.label')
                  : t('LEIForm.Address.registerAvalableZH.label')}
              </Text>
            }
          />
        </Flex>
      </Flex>
      <Flex gridGap={30}>
        <Flex width={'100%'} flexDirection={'column'} gridGap={10}>
          <FormSelect
            control={control}
            data={currentAllowLanguage}
            inputLabel={t('LEIForm.Address.language.label') + ' *'}
            selectLabel={t('LEIForm.Address.language.label') + ' *'}
            name={'registerAddress.Language'}
            register={addressForm().language}
          />
          <FormSelect
            control={control}
            data={[
              convertLocations(lang, options?.Country)[0],
              convertLocations(lang, options?.Country)[1],
              convertLocations(lang, options?.Country)[2],
            ]}
            inputLabel={t('LEIForm.Address.country.label') + ' *'}
            selectLabel={t('LEIForm.Address.country.label') + ' *'}
            name={'registerAddress.Country'}
            register={addressForm().country}
          />
          {registerCountry === 'CN' && (
            <>
              <FormSelect
                control={control}
                data={convertChinaArea(lang, options?.ChinaArea)}
                inputLabel={t('LEIForm.Address.province.label') + ' *'}
                selectLabel={t('LEIForm.Address.province.label') + ' *'}
                name={'registerAddress.Province'}
                register={addressForm().province}
              />
              <FormSelect
                control={control}
                data={convertChinaCity(lang, watch('registerAddress.Province'), options?.ChinaArea)}
                inputLabel={t('LEIForm.Address.city.label') + ' *'}
                selectLabel={t('LEIForm.Address.city.label') + ' *'}
                name={'registerAddress.City'}
                register={addressForm().city}
              />
            </>
          )}
          {registerCountry !== 'CN' && (
            <>
              <Controller
                control={control}
                name={'registerAddress.Province'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.province.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    inputProps={{
                      readOnly: registerAddressLock,
                    }}
                  />
                )}
                rules={addressForm().province}
              />
              <Controller
                control={control}
                name={'registerAddress.City'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.city.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    inputProps={{
                      readOnly: registerAddressLock,
                    }}
                  />
                )}
                rules={addressForm().city}
              />
            </>
          )}
          <Controller
            control={control}
            name={'registerAddress.Address'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.Address.address.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={addressForm().address}
          />
          <Controller
            control={control}
            name={'registerAddress.PostCode'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.Address.postCode.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                inputProps={{
                  readOnly: registerAddressLock,
                }}
              />
            )}
            rules={addressForm().postCode}
          />
        </Flex>
        <Flex width={'100%'} flexDirection={'column'} gridGap={10} opacity={!otherRegisterAvaliavle ? '.5' : '1'}>
          <FormSelect
            control={control}
            data={currentAllowLanguage}
            inputLabel={t('LEIForm.Address.language.label') + ' *'}
            selectLabel={t('LEIForm.Address.language.label') + ' *'}
            name={'otherRegisterAddress.Language'}
            disabled={true}
          />
          <FormSelect
            control={control}
            data={convertLocations(lang, options?.Country)}
            inputLabel={t('LEIForm.Address.country.label') + ' *'}
            selectLabel={t('LEIForm.Address.country.label') + ' *'}
            name={'otherRegisterAddress.Country'}
            disabled={true}
          />
          {otherRegisterCountry === 'CN' && (
            <>
              <FormSelect
                control={control}
                data={convertChinaArea(lang, options?.ChinaArea)}
                inputLabel={t('LEIForm.Address.province.label') + ' *'}
                selectLabel={t('LEIForm.Address.province.label') + ' *'}
                name={'otherRegisterAddress.Province'}
                readOnly={otherRegisterAddressLock}
              />
              <FormSelect
                control={control}
                data={convertChinaCity(lang, watch('otherRegisterAddress.Province'), options?.ChinaArea)}
                inputLabel={t('LEIForm.Address.city.label') + ' *'}
                selectLabel={t('LEIForm.Address.city.label') + ' *'}
                name={'otherRegisterAddress.City'}
                readOnly={otherRegisterAddressLock}
              />
            </>
          )}
          {otherRegisterCountry !== 'CN' && (
            <>
              <Controller
                control={control}
                name={'otherRegisterAddress.Province'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.province.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    disabled={!otherRegisterAvaliavle}
                    inputProps={{
                      readOnly: otherRegisterAddressLock,
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={'otherRegisterAddress.City'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.city.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    disabled={!otherRegisterAvaliavle}
                    inputProps={{
                      readOnly: otherRegisterAddressLock,
                    }}
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name={'otherRegisterAddress.Address'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.Address.address.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                disabled={!otherRegisterAvaliavle}
              />
            )}
          />
          <Controller
            control={control}
            name={'otherRegisterAddress.PostCode'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.Address.postCode.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                disabled={true}
              />
            )}
          />
        </Flex>
      </Flex>

      <FormTitle marginTop={'25px'}>{t('info.officeAddress')}</FormTitle>
      <Flex gridGap={30}>
        <Flex width={'100%'} flexDirection={'column'} gridGap={10}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: '#999999',
                  '&.Mui-checked': {
                    color: theme.colors.secondary500,
                  },
                }}
                onChange={handleSameAsRegisterAddress}
                name="sameAsRegisterAddress"
                checked={sameAsRegisterAddress}
              />
            }
            label={<Text>{t('LEIForm.Address.sameAsRegister.label')}</Text>}
          />
        </Flex>
        <Flex width={'100%'} flexDirection={'column'} gridGap={10}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: '#999999',
                  '&.Mui-checked': {
                    color: theme.colors.secondary500,
                  },
                }}
                onChange={handleOtherOfficeAddressAvaliavle}
                name="otherOfficeAddressAvaliavle"
                checked={otherOfficeAddressAvaliavle}
              />
            }
            label={
              <Text>
                {watch('officeAddress.Language') && watch('officeAddress.Language') === 'zh'
                  ? t('LEIForm.Address.officeRegistrationAvalable.label')
                  : t('LEIForm.Address.officeRegistrationAvalableZH.label')}
              </Text>
            }
          />
        </Flex>
      </Flex>
      <Flex gridGap={30}>
        <Flex width={'100%'} flexDirection={'column'} gridGap={10} opacity={sameAsRegisterAddress ? '.5' : '1'}>
          <FormSelect
            control={control}
            data={currentAllowLanguage}
            inputLabel={t('LEIForm.Address.language.label') + ' *'}
            selectLabel={t('LEIForm.Address.language.label') + ' *'}
            name={'officeAddress.Language'}
            readOnly={sameAsRegisterAddress}
            register={addressForm().language}
          />
          <FormSelect
            control={control}
            data={convertLocations(lang, options?.Country)}
            inputLabel={t('LEIForm.Address.country.label') + ' *'}
            selectLabel={t('LEIForm.Address.country.label') + ' *'}
            name={'officeAddress.Country'}
            readOnly={sameAsRegisterAddress}
            register={addressForm().country}
          />
          {officeCountry === 'CN' && (
            <>
              <FormSelect
                control={control}
                data={convertChinaArea(lang, options?.ChinaArea)}
                inputLabel={t('LEIForm.Address.province.label') + ' *'}
                selectLabel={t('LEIForm.Address.province.label') + ' *'}
                name={'officeAddress.Province'}
                readOnly={sameAsRegisterAddress}
                register={addressForm().province}
              />
              <FormSelect
                control={control}
                data={convertChinaCity(lang, watch('officeAddress.Province'), options?.ChinaArea)}
                inputLabel={t('LEIForm.Address.city.label') + ' *'}
                selectLabel={t('LEIForm.Address.city.label') + ' *'}
                name={'officeAddress.City'}
                readOnly={sameAsRegisterAddress}
                register={addressForm().city}
              />
            </>
          )}
          {officeCountry !== 'CN' && (
            <>
              <Controller
                control={control}
                name={'officeAddress.Province'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.province.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    inputProps={{
                      readOnly: officeAddressLock,
                    }}
                  />
                )}
                rules={addressForm().province}
              />
              <Controller
                control={control}
                name={'officeAddress.City'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.city.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    inputProps={{
                      readOnly: officeAddressLock,
                    }}
                  />
                )}
                rules={addressForm().city}
              />
            </>
          )}
          <Controller
            control={control}
            name={'officeAddress.Address'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.Address.address.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                disabled={sameAsRegisterAddress}
              />
            )}
            rules={addressForm().address}
          />
          <Controller
            control={control}
            name={'officeAddress.PostCode'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.Address.postCode.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                disabled={sameAsRegisterAddress}
                inputProps={{
                  readOnly: officeAddressLock,
                }}
              />
            )}
            rules={addressForm().postCode}
          />
        </Flex>
        <Flex width={'100%'} flexDirection={'column'} gridGap={10} opacity={!otherOfficeAddressAvaliavle ? '.5' : '1'}>
          <FormSelect
            control={control}
            data={currentAllowLanguage}
            inputLabel={t('LEIForm.Address.language.label') + ' *'}
            selectLabel={t('LEIForm.Address.language.label') + ' *'}
            name={'otherOfficeAddress.Language'}
            disabled={true}
          />
          <FormSelect
            control={control}
            data={convertLocations(lang, options?.Country)}
            inputLabel={t('LEIForm.Address.country.label') + ' *'}
            selectLabel={t('LEIForm.Address.country.label') + ' *'}
            name={'otherOfficeAddress.Country'}
            disabled={true}
          />
          {otherOfficeCountry === 'CN' && (
            <>
              <FormSelect
                control={control}
                data={convertChinaArea(lang, options?.ChinaArea)}
                inputLabel={t('LEIForm.Address.province.label') + ' *'}
                selectLabel={t('LEIForm.Address.province.label') + ' *'}
                name={'otherOfficeAddress.Province'}
                readOnly={otherOfficeAddressLock}
              />
              <FormSelect
                control={control}
                data={convertChinaCity(lang, watch('otherOfficeAddress.Province'), options?.ChinaArea)}
                inputLabel={t('LEIForm.Address.city.label') + ' *'}
                selectLabel={t('LEIForm.Address.city.label') + ' *'}
                name={'otherOfficeAddress.City'}
                readOnly={otherOfficeAddressLock}
              />
            </>
          )}
          {otherOfficeCountry !== 'CN' && (
            <>
              <Controller
                control={control}
                name={'otherOfficeAddress.Province'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.province.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    disabled={!otherOfficeAddressAvaliavle}
                    inputProps={{
                      readOnly: otherOfficeAddressLock,
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={'otherOfficeAddress.City'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.city.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    disabled={!otherOfficeAddressAvaliavle}
                    inputProps={{
                      readOnly: otherOfficeAddressLock,
                    }}
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name={'otherOfficeAddress.Address'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.Address.address.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                disabled={!otherOfficeAddressAvaliavle}
              />
            )}
          />
          <Controller
            control={control}
            name={'otherOfficeAddress.PostCode'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.Address.postCode.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                disabled={true}
              />
            )}
          />
        </Flex>
      </Flex>

      <TabsButton selectedTab={2} setSelectedTab={setSelectedTab} />
    </Wrapper>
  );
};

export default Address;

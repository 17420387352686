import styled, { css, keyframes } from 'styled-components';
import { SpaceProps, WidthProps, space, width } from 'styled-system';
import { darken, lighten } from 'polished';

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export type ButtonSize = 'sm' | 'table' | 'md' | 'lg' | 'half';

export type ButtonVariant =
  | 'primary'
  | 'primary-invert'
  | 'danger'
  | 'danger-invert'
  | 'regular'
  | 'normal'
  | 'normal-invert'
  | 'square'
  | 'orange'
  | 'yellow';

export type ButtonSpecial = 'fake' | 'normal';

const buttonSpecial = css<{ special: ButtonSpecial }>`
  ${({ special = 'fake' }) => {
    return {
      fake: css`
        pointer-events: none;
      `,
      normal: css``,
    }[special];
  }}
`;

const buttonSize = css<{
  size: ButtonSize;
}>`
  ${({ size = 'md', theme: { paddings, heights, fontSizes } }) => {
    return {
      sm: css`
        height: ${heights.sm};
        padding: 0 ${paddings.xs};
        font-size: ${fontSizes.sm};
        border-radius: ${(p) => p.theme.radii.lg};
      `,
      table: css`
        min-width: 100px;
        height: ${heights.table};
        font-size: ${fontSizes.base};
        border-radius: ${(p) => p.theme.radii.base};
        padding: 0 10px;
      `,
      md: css`
        height: ${heights.base};
        padding: 0 ${paddings.lg};
        font-size: ${fontSizes.base};
        border-radius: ${(p) => p.theme.radii.base};
      `,
      lg: css`
        min-width: 170px;
        height: ${heights.lg};
        padding: ${paddings.lg} calc(${paddings.lg} * 2);
        font-size: ${fontSizes.md};
      `,
      half: css`
        flex: 1;
        height: ${heights.lg};
        font-size: ${fontSizes.base};
        border-radius: ${(p) => p.theme.radii.base};
      `
    }[size];
  }};
`;

export const buttonVariant = ({ variant }: { variant?: ButtonVariant }) => {
  switch (variant) {
    default:
      return css`
        border-color: transparent;
        background-color: transparent;
        color: ${(p) => p.theme.colors.primary100};
        text-decoration: underline;
        &:active {
          color: ${(p) => p.theme.colors.primary400};
        }
      `;
    case 'primary':
      return css`
        background-color: ${(p) => p.theme.colors.primary500};
        color: ${(p) => p.theme.colors.light};
        font-weight: bold;
        /* stylelint-disable-next-line no-descending-specificity */
        &:hover {
          background-color: ${(p) => lighten(0.03, p.theme.colors.primary500)};
        }
        &:active {
          background-color: ${(p) => darken(0.03, p.theme.colors.primary500)};
        }
      `;
    case 'primary-invert':
      return css`
        background-color: ${(p) => p.theme.colors.light};
        color: ${(p) => p.theme.colors.primary500};
        font-weight: bold;
        border-color: ${(p) => p.theme.colors.primary500};
        /* stylelint-disable-next-line no-descending-specificity */
        &:hover {
          color: ${(p) => lighten(0.03, p.theme.colors.primary500)};
        }
        &:active {
          background-color: ${(p) => p.theme.colors.gray200};
          color: ${(p) => darken(0.03, p.theme.colors.primary500)};
        }
      `;
    case 'danger':
      return css`
        background-color: ${(p) => p.theme.colors.danger};
        color: ${(p) => p.theme.colors.light};
        font-weight: bold;
        /* stylelint-disable-next-line no-descending-specificity */
        &:hover {
          background-color: ${(p) => lighten(0.03, p.theme.colors.danger)};
        }
        &:active {
          background-color: ${(p) => darken(0.03, p.theme.colors.danger)};
        }
      `;
    case 'danger-invert':
      return css`
        background-color: ${(p) => p.theme.colors.gray100};
        color: ${(p) => p.theme.colors.danger};
        font-weight: bold;
        &::before {
          border-color: ${(p) => p.theme.colors.danger};
        }
        /* stylelint-disable-next-line no-descending-specificity */
        &:hover {
          color: ${(p) => lighten(0.03, p.theme.colors.danger)};
        }
        &:active {
          background-color: ${(p) => p.theme.colors.gray200};
          color: ${(p) => darken(0.03, p.theme.colors.danger)};
        }
      `;
    case 'regular':
      return css`
        background-color: ${(p) => p.theme.colors.secondary500};
        color: ${(p) => p.theme.colors.light};
        &:hover {
          background-color: ${(p) => lighten(0.03, p.theme.colors.secondary400)};
        }
        &:active {
          background-color: ${(p) => darken(0.03, p.theme.colors.secondary400)};
        }
      `;
    case 'normal':
      return css`
        background-color: ${(p) => p.theme.colors.gray200};
        color: ${(p) => p.theme.colors.light};
        /* stylelint-disable-next-line no-descending-specificity */
        &:hover {
          background-color: ${(p) => lighten(0.03, p.theme.colors.gray200)};
        }
        &:active {
          background-color: ${(p) => darken(0.03, p.theme.colors.gray200)};
        }
      `;
    case 'normal-invert':
      return css`
        background-color: ${(p) => p.theme.colors.light};
        color: ${(p) => p.theme.colors.gray200};
        border-color: ${(p) => p.theme.colors.gray200};
        /* stylelint-disable-next-line no-descending-specificity */
        &:hover {
          background-color: ${(p) => lighten(0.03, p.theme.colors.light)};
        }
        &:active {
          background-color: ${(p) => darken(0.03, p.theme.colors.light)};
        }
      `;
    case 'square':
      return css`
        padding: 8px 15px;
        background-color: ${(p) => p.theme.colors.secondary500};
        border-radius: 0;
        color: ${(p) => p.theme.colors.light};
        &:hover {
          background-color: ${(p) => lighten(0.03, p.theme.colors.secondary500)};
        }
        &:active {
          background-color: ${(p) => darken(0.03, p.theme.colors.secondary500)};
        }
      `;
    case 'orange':
      return css`
        background-color: ${(p) => p.theme.colors.orange};
        color: ${(p) => p.theme.colors.light};
        /* stylelint-disable-next-line no-descending-specificity */
        &:hover {
          background-color: ${(p) => lighten(0.04, p.theme.colors.orange)};
        }
        &:active {
          background-color: ${(p) => darken(0.04, p.theme.colors.orange)};
        }
      `;
    case 'yellow':
      return css`
        background-color: ${(p) => p.theme.colors.yellow};
        color: ${(p) => p.theme.colors.light};
        /* stylelint-disable-next-line no-descending-specificity */
        &:hover {
          background-color: ${(p) => lighten(0.04, p.theme.colors.orange)};
        }
        &:active {
          background-color: ${(p) => darken(0.04, p.theme.colors.orange)};
        }
      `;
  }
};

export type StyledButtonProps = SpaceProps &
  WidthProps & {
    size: ButtonSize;
    hasIcon: boolean;
    special: ButtonSpecial;
    variant?: ButtonVariant;
    rounded?: boolean;
    disabled?: boolean;
  };

export const StyledButton = styled.button<StyledButtonProps>`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: ${(p) => (p.hasIcon ? 'space-between' : 'center')};
  gap: ${(p) => (p.hasIcon ? '40px' : '0px')};
  overflow: hidden;
  border: ${(p) => p.theme.borders.base};
  border-color: transparent;
  line-height: ${(p) => p.theme.lineHeight};
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  &:focus {
    outline: 0;
  }
  &:disabled,
  &[disabled] {
    opacity: 0.85;
    background-color: ${(p) => p.theme.colors.gray300};
    color: ${(p) => p.theme.colors.light};
    cursor: default;
    pointer-events: none;
  }
  ${(p) =>
    p.rounded &&
    css`
      border-radius: 999px;
    `};
  ${(p) => p.theme.transition /* sc-declaration */};
  ${buttonSize /* sc-declaration */}
  ${buttonVariant /* sc-declaration */}
  ${buttonSpecial /* sc-declaration */}
  ${space};
  ${width};
`;

import { useMemo, useEffect, useCallback, useState } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import DataTable, { TypeColumn } from '@/components/DataTable';
import { Card } from '@/layout/Card';
import { Box, Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import { Like } from '@/components/Icon';
import Loading from '@/components/UI/Loading';

import { getTemplate } from '@/api/deal.api';
import { convertLink, convertToFileSize } from '@/utils/convert';

import { theme } from '@/theme/Theme';
import { Text } from '@/components/UI/Typography/Text';
import useCurrentLang from '@/hooks/useCurrentLang';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { NeedUpgradePlanModal } from '@/components/DocLibrary/Modal';

type DataRow = {
  recommend?: boolean;
  name: string;
  type: string;
  size: string;
  logo: string;
  action: { name: string; path: string }[];
  websiteLink?: string;
  lawyerName?: string;
};

interface FilesProps {
  id?: string;
}

const ContractTemplatesList = ({ id = '0' }: FilesProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const tabLabel = t('pages.dealDetail.manageFiles.contractTemplates.table.columns', { returnObjects: true });

  const [docTemplate, setDocTemplate] = useState<DataRow[]>();
  const { userData } = useAppSelector(getAuthSelector);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  // 下載連結
  const downloadFile = async (name: string, path: string) => {
    const link = convertLink(path);
    const a = document.createElement('a');
    a.href = link;
    a.download = name;
    a.click();
  };

  const initData = useCallback(async () => {
    if (!id) return;
    try {
      const res = (await getTemplate(id)).data;
      const other: DataRow[] = res.data.other.map((data) => ({
        recommend: false,
        name:
          data.en_file && lang === 'en'
            ? data.en_file.name
            : data.sc_file && lang === 'sc'
            ? data.sc_file.name
            : data.tc_file && lang === 'tc'
            ? data.tc_file.name
            : data.en_file
            ? data.en_file.name
            : data.sc_file
            ? data.sc_file.name
            : data.tc_file
            ? data.tc_file.name
            : '',
        type:
          data.en_file && lang === 'en'
            ? data.en_file.extension
            : data.sc_file && lang === 'sc'
            ? data.sc_file.extension
            : data.tc_file && lang === 'tc'
            ? data.tc_file.extension
            : data.en_file
            ? data.en_file.extension
            : data.sc_file
            ? data.sc_file.extension
            : data.tc_file
            ? data.tc_file.extension
            : '',
        size: convertToFileSize(
          Number(
            data.en_file && lang === 'en'
              ? data.en_file.size
              : data.sc_file && lang === 'sc'
              ? data.sc_file.size
              : data.tc_file && lang === 'tc'
              ? data.tc_file.size
              : data.en_file
              ? data.en_file.size
              : data.sc_file
              ? data.sc_file.size
              : data.tc_file
              ? data.tc_file.size
              : '',
          )
        ),
        action: [
          data.en_file && {
            name: data.en_file.name,
            path: data.en_file.path,
          },
          data.sc_file && {
            name: data.sc_file.name,
            path: data.sc_file.path,
          },
          data.tc_file && {
            name: data.tc_file.name,
            path: data.tc_file.path,
          },
        ],
        logo: data.institution_logo ? data.institution_logo : '',
        websiteLink: data.website_link,
        lawyerName: data.lawyer_name,
      }));
      const recommend: DataRow[] = res.data.recommend.map((data) => ({
        recommend: true,
        name:
          data.en_file && lang === 'en'
            ? data.en_file.name
            : data.sc_file && lang === 'sc'
            ? data.sc_file.name
            : data.tc_file && lang === 'tc'
            ? data.tc_file.name
            : data.en_file
            ? data.en_file.name
            : data.sc_file
            ? data.sc_file.name
            : data.tc_file
            ? data.tc_file.name
            : '',
        type:
          data.en_file && lang === 'en'
            ? data.en_file.extension
            : data.sc_file && lang === 'sc'
            ? data.sc_file.extension
            : data.tc_file && lang === 'tc'
            ? data.tc_file.extension
            : data.en_file
            ? data.en_file.extension
            : data.sc_file
            ? data.sc_file.extension
            : data.tc_file
            ? data.tc_file.extension
            : '',
        size: convertToFileSize(
          Number(
            data.en_file && lang === 'en'
              ? data.en_file.size
              : data.sc_file && lang === 'sc'
              ? data.sc_file.size
              : data.tc_file && lang === 'tc'
              ? data.tc_file.size
              : data.en_file
              ? data.en_file.size
              : data.sc_file
              ? data.sc_file.size
              : data.tc_file
              ? data.tc_file.size
              : '',
          )
        ),
        action: [
          data.en_file && {
            name: data.en_file.name,
            path: data.en_file.path,
          },
          data.sc_file && {
            name: data.sc_file.name,
            path: data.sc_file.path,
          },
          data.tc_file && {
            name: data.tc_file.name,
            path: data.tc_file.path,
          },
        ],
        logo: data.institution_logo ? data.institution_logo : '',
        websiteLink: data.website_link,
        lawyerName: data.lawyer_name,
      }));
      setDocTemplate(other.concat(recommend));
    } catch (error) {
      console.log(error);
    }
  }, [lang, id]);

  useEffect(() => {
    if (userData.plan.level === null) setShowUpgradeModal(true);
    initData();
  }, [initData, lang]);

  const columns: ColumnDef<DataRow>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: tabLabel[0],
        cell: ({ row }) => (
          <Flex alignItems={'center'} gridGap={'10px'}>
            {row.original.recommend && <Like.LikeSVG fill={theme.colors.orange} />} {row.original.name}
          </Flex>
        ),
      },
      {
        accessorKey: 'type',
        header: tabLabel[2],
        cell: ({ row }) => <TypeColumn text={row.original.type} />,
      },
      {
        accessorKey: 'size',
        header: tabLabel[3],
      },
      {
        accessorKey: 'action',
        header: tabLabel[4],
        cell: ({ row }) => (
          <Flex gridGap={'10px'}>
            {row.original.action.map(
              (file, index) =>
                file && (
                  <Button
                    key={`link-${index}`}
                    size={'md'}
                    variant={'primary'}
                    onClick={() => {
                      downloadFile(file.name, file.path);
                    }}
                  >
                    {index === 0 ? 'EN' : index === 1 ? '簡' : '繁'}
                  </Button>
                )
            )}
          </Flex>
        ),
      },
      {
        accessorKey: 'providedBy',
        header: tabLabel[5],
        cell: ({ row }) => (
          <>
            {row.original.logo && <img height="50px" src={row.original.logo} alt="logo" />}
            {row.original.lawyerName && (
              <Text>
                {t('info.lawyerName')}: {row.original.lawyerName}
              </Text>
            )}
            {row.original.websiteLink && (
              <Text>
                {t('info.websiteLink')}:{' '}
                <a
                  target="_blank"
                  href={row.original.websiteLink}
                  rel="noreferrer"
                  style={{ color: theme.colors.primary300 }}
                >
                  {row.original.websiteLink}
                </a>
              </Text>
            )}
          </>
        ),
      },
    ],
    [t, tabLabel, lang]
  );

  return (
    <Card>
      <NeedUpgradePlanModal setShowModal={setShowUpgradeModal} showModal={showUpgradeModal} />
      {userData.plan.level !== null && (
        <>
          <Box marginBottom={30}>
            <Text fontWeight={900} marginBottom={10}>
              {t('pages.dealDetail.manageFiles.contractTemplates.title')}
            </Text>
            {/* {tips.map((tip, index) => (
          <div key={`tip-${index}`}>
            <Text>{tip}</Text>
          </div>
        ))} */}
            <Text>{t('pages.dealDetail.manageFiles.contractTemplates.content.context')}</Text>
          </Box>

          <Box marginBottom={20}>
            <Text fontWeight={900} marginBottom={10}>
              {t('pages.dealDetail.manageFiles.contractTemplates.content.Disclaimer.title')}
            </Text>
            <Text>
              {t('pages.dealDetail.manageFiles.contractTemplates.content.Disclaimer.firstPart')}
              <br />
              <br />
              {t('pages.dealDetail.manageFiles.contractTemplates.content.Disclaimer.secondPart')}
              <br />
              <br />
              {t('pages.dealDetail.manageFiles.contractTemplates.content.Disclaimer.thirdPart')}
            </Text>
          </Box>

          <Text fontWeight={900} marginBottom={20}>
            {t('pages.dealDetail.manageFiles.contractTemplates.download')}
            <br />
            {t('pages.dealDetail.manageFiles.contractTemplates.note')}
          </Text>

          <Box width={'100%'}>
            {docTemplate ? <DataTable columns={columns} data={docTemplate} canSearch /> : <Loading />}
          </Box>
        </>
      )}
    </Card>
  );
};

export default ContractTemplatesList;

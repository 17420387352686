import StyledLink from '@/components/UI/Link';
import { Flex } from '@/layout/Flex';
import { useLocation } from 'react-router-dom';

import { Content, Detail, Photo, Wrapper } from './styles';
import { addressSVG, clockSVG, mailSVG, telSVG, websiteSVG } from './svg';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';
import { convertLink } from '@/utils/convert';

interface CompanyProps {
  data: CompanyDetail;
  children?: React.ReactNode;
}

const Company = ({ data, children }: CompanyProps) => {
  const location = useLocation();

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      width: 'unset',
      maxWidth: 'unset',
      fontSize: theme.fontSizes.base,
      whiteSpace: 'nowrap',
    },
  }));

  return (
    <Wrapper>
      <Flex gridGap={30}>
        <Photo src={data.acc_logo} />
        <Content>
          {data?.acc_coname && data.acc_coname.length > 22 ? (
            <CustomTooltip title={data.acc_coname} placement="top">
              <h2>{`${data.acc_coname.slice(0, 17)}...`}</h2>
            </CustomTooltip>
          ) : (
            <h2>{data?.acc_coname}</h2>
          )}
          <p>{data.acc_desc}</p>
          <StyledLink to={`/eDirectory/${data.id}`} state={{ pathname: location.pathname, search: location.search }}>
            Read more
          </StyledLink>
          <p className="tip">{data.acc_currency}</p>
        </Content>
        <Flex flexWrap={'wrap'} flexShrink={1} justifyContent={'space-between'} alignContent={'space-between'}>
          <Detail flex={'0 0 50%'}>
            <img src={telSVG} alt="telSVG" />
            <span>{data.acc_tel}</span>
          </Detail>
          {data.acc_website && (
            <Detail flex={'0 0 50%'}>
              <img src={websiteSVG} alt="websiteSVG" />
              <span>{data.acc_website}</span>
            </Detail>
          )}
          <Detail flex={'0 0 100%'}>
            <img src={mailSVG} alt="mailSVG" />
            <span>{data.email}</span>
          </Detail>
          <Detail flex={'0 0 100%'}>
            <img src={addressSVG} alt="addressSVG" />
            <span>{data.acc_address?.split('@@_@@').join(' ')}</span>
          </Detail>
        </Flex>
      </Flex>
      {children && (
        <Flex justifyContent={'flex-end'} width={'100%'}>
          {children}
        </Flex>
      )}
    </Wrapper>
  );
};

export default Company;

import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox, FormControlLabel, Paper, FormControl } from '@mui/material';
import { successHandler, errorHandler } from '@/utils/toastHandler';
import { Flex, FormBox, LoadingPage } from '@/layout';
import { registerPreview } from '@/api/auth.api';
import { Row, Hr, LabelWrapper } from './styled';
import { FormTitle, TipWrapper } from '@/components/Container/AuthContainer/styles';
import { getAuthSelector, update, updateRegistDone } from '@/redux/auth/auth.slice';
import { useAppSelector } from '@/redux/store.hook';
import { useDispatch } from 'react-redux';
import { convertTitle, convertToCurrentLangData, convertCategory } from '@/utils/convert';
import useCurrentLang from '@/hooks/useCurrentLang';
import useResize from '@/hooks/useResize';

interface RowProp {
  name: string;
  value: string;
  key: string;
}

const PreviewApplicationInfo = ({
  backToPrevious = () => {},
  nextHandler = () => {},
  ButtonGroup,
  ReturnLink = '',
  userDetail,
}: ProfileProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();
  const lang = useCurrentLang();
  const { handleSubmit } = useForm();

  const auth = useAppSelector(getAuthSelector);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = handleSubmit(async () => {
    const { promotion } = checkBox;

    try {
      setIsLoading(true);
      const res = (await registerPreview({ TC_Promotion: promotion ? '1' : '0' })).data;
      if (res.success) {
        const patch = dispatch(update({ ...auth, userData: res.data }));
        const regist = dispatch(updateRegistDone(true));
        successHandler(t('success.upload'));
        setIsLoading(false);

        nextHandler();
      }
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error.response.data.data);
    }
  });

  const [companyInfo, setCompanyInfo] = useState<RowProp[]>([]);
  const [companyRepresentative, setCompanyRepresentative] = useState<RowProp[]>([]);

  const dataInit = () => {
    if (!userDetail) return;
    const companyInfo: RowProp[] = [
      {
        name: t('info.companyName'),
        value: userDetail.acc_coname,
        key: 'companyName',
      },
      {
        name: t('info.title'),
        value: convertTitle(userDetail.acc_title, lang),
        key: 'title',
      },
      {
        name: t('info.giveName'),
        value: userDetail.acc_firstName,
        key: 'giveName',
      },
      {
        name: t('info.familyName'),
        value: userDetail.acc_lastName,
        key: 'familyName',
      },
      {
        name: t('info.jobPosition'),
        value: userDetail.acc_jobPosition,
        key: 'jobPosition',
      },
      {
        name: t('info.email'),
        value: userDetail.email,
        key: 'email',
      },
      {
        name: t('info.region'),
        value: `${
          userDetail.acc_country_other
            ? userDetail.acc_country_other
            : convertToCurrentLangData(userDetail.acc_country, 'name', lang)
        }`,
        key: 'region',
      },
      {
        name: t('info.timezone'),
        value: userDetail.acc_timezone,
        key: 'timezone',
      },
      {
        name: t('info.countryCode'),
        value: userDetail.acc_countryCode,
        key: 'countryCode',
      },
      {
        name: t('info.mobilePhoneNumber'),
        value: userDetail.acc_mobile,
        key: 'mobilePhoneNumber',
      },
    ];

    const companyRepresentative: RowProp[] = [
      {
        name: t('info.companyLogo'),
        value: userDetail.acc_logo,
        key: 'companyLogo',
      },
      {
        name: t('info.website'),
        value: userDetail.acc_website,
        key: 'website',
      },
      {
        name: t('info.companyEmail'),
        value: userDetail.com_email,
        key: 'companyEmail',
      },
      {
        name: t('info.telephone'),
        value: userDetail.acc_tel
          ? `${userDetail.acc_country !== 'Other' ? `(${userDetail.acc_countryCode.split(' ')[0]})` : ''} ${
              userDetail.acc_tel
            }`
          : '',
        key: 'telephone',
      },
      {
        name: t('info.fax'),
        value: userDetail.acc_fax
          ? `(${userDetail.acc_fax ? userDetail.acc_countryCode.split(' ')[0] : ''}) ${userDetail.acc_fax}`
          : '',
        key: 'fax',
      },
      {
        name: t('info.companyAddress'),
        value: userDetail.acc_address ? userDetail.acc_address.split('@@_@@').join(' ') : '',
        key: 'companyAddress',
      },
      {
        name: t('info.companyDescription'),
        value: userDetail.acc_desc,
        key: 'companyDescription',
      },
      {
        name: t('info.natureOfBusiness'),
        value: `${convertCategory(userDetail.acc_category, lang)} ${
          userDetail.acc_category_other ? `(${userDetail.acc_category_other})` : ''
        }`,
        key: 'natureOfBusiness',
      },
      {
        name: t('info.lawFirmSectorSpecialty'),
        value: userDetail.acc_category.includes('Legal Services')
          ? userDetail.law_firm_sector_specialty.join(', ')
          : '-',
        key: 'lawFirmSectorSpecialty',
      },
      {
        name: t('info.lawFirmPracticeAreas'),
        value: userDetail.acc_category.includes('Legal Services') ? userDetail.practice_areas.join(', ') : '-',
        key: 'lawFirmPracticeAreas',
      },
      {
        name: t('info.languages'),
        value: `${convertToCurrentLangData(userDetail.acc_languages, 'language', lang)} ${
          userDetail.acc_languages_other ? `(${userDetail.acc_languages_other})` : ''
        }`,
        key: 'languages',
      },
      {
        name: t('info.currency'),
        value: `${convertToCurrentLangData(userDetail.acc_currency, 'currency', lang)} ${
          userDetail.acc_currency_other ? `(${userDetail.acc_currency_other})` : ''
        } `,
        key: 'currency',
      },
    ];

    setCompanyInfo(companyInfo);
    setCompanyRepresentative(companyRepresentative);
  };

  useEffect(() => {
    dataInit();
  }, [userDetail, lang]);

  const [checkBox, setCheckBox] = useState({
    promotion: false,
  });

  const { promotion } = checkBox;

  const handleChange = (value: string, current: boolean) => {
    setCheckBox({ ...checkBox, [value]: !current });
  };

  return (
    <>
      <LoadingPage isLoading={isLoading} />
      <FormBox onSubmit={onSubmit} isTablet={isTablet}>
        <FormTitle>
          <Flex justifyContent={'space-between'}>
            <span>{t('companyRepresentativeInformation')}</span>
            <TipWrapper isMobile={isMobile} onClick={() => backToPrevious(0)}>
              {t('buttons.edit')}
            </TipWrapper>
          </Flex>
        </FormTitle>
        {companyInfo.map((item, index) => (
          <Row isMobile={isMobile} key={index}>
            <div className="title">{item.name}</div>
            <div className="content">{item.value}</div>
          </Row>
        ))}
        <Hr />
        <FormTitle>
          <Flex justifyContent={'space-between'}>
            <span>{t('companyInformation')}</span>
            <TipWrapper isMobile={isMobile} onClick={() => backToPrevious(1)}>
              {t('buttons.edit')}
            </TipWrapper>
          </Flex>
        </FormTitle>
        {companyRepresentative.map((item, index) => {
          if (item.key === 'companyLogo') {
            return (
              <Row isMobile={isMobile} key={index}>
                <div className="title">{item.name}</div>
                <div className="content">
                  <img height={100} src={item.value} alt="logo" />
                </div>
              </Row>
            );
          } else if (item.key === 'website') {
            return (
              <Row isMobile={isMobile} key={index}>
                <div className="title">{item.name}</div>
                <div className="content">
                  <a target="_blank" rel="noreferrer" href={item.value}>
                    {item.value}
                  </a>
                </div>
              </Row>
            );
          } else {
            return (
              <Row isMobile={isMobile} key={index}>
                <div className="title">{item.name}</div>
                <div className="content">{item.value}</div>
              </Row>
            );
          }
        })}
        <Hr />
        <FormTitle>
          <Flex justifyContent={'space-between'}>
            <span>{t('supplementalInformation')}</span>
            <TipWrapper isMobile={isMobile} onClick={() => backToPrevious(2)}>
              {t('buttons.edit')}
            </TipWrapper>
          </Flex>
        </FormTitle>
        <Row isMobile={isMobile}>
          <div className="title">{t('info.businessRegistrationNumber')}</div>
          <div className="content">{userDetail?.acc_BR}</div>
        </Row>
        <Row isMobile={isMobile}>
          <div className="title">{t('info.BRCertificate')}</div>
          {userDetail && userDetail.br_cert && (
            <a target={'_blank'} rel="noreferrer" href={userDetail.br_cert.path}>
              {userDetail.br_cert.name}
            </a>
          )}
        </Row>
        <Row isMobile={isMobile}>
          <div className="title">{t('info.ISOCertification')}</div>
          {userDetail && userDetail.iso_cert && (
            <a target={'_blank'} rel="noreferrer" href={userDetail.iso_cert.path}>
              {userDetail.iso_cert.name}
            </a>
          )}
        </Row>
        <Row isMobile={isMobile}>
          <div className="title">{t('info.LEICode')}</div>
          <div className="content">{userDetail?.acc_LEI}</div>
        </Row>
        <Row isMobile={isMobile}>
          <div className="title">{t('info.eCert')}</div>
          {userDetail && userDetail.e_cert && (
            <a target={'_blank'} rel="noreferrer" href={userDetail.e_cert.path}>
              {userDetail.e_cert.name}
            </a>
          )}
        </Row>
        <Row isMobile={isMobile}>
          <div className="title">{t('info.creditReport')}</div>
          {userDetail && userDetail.credit_report && (
            <a target={'_blank'} rel="noreferrer" href={userDetail.credit_report.path}>
              {userDetail.credit_report.name}
            </a>
          )}
        </Row>
        <Hr />
        <FormControl sx={{ paddingTop: '30px' }} required component="fieldset">
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: '#999999',
                  '&.Mui-checked': {
                    color: '#999999',
                  },
                }}
                checked={promotion}
                onChange={() => handleChange('promotion', promotion)}
                name="promotion"
              />
            }
            label={<LabelWrapper>{t('form.terms.label.receive')}</LabelWrapper>}
          />
        </FormControl>
        {ButtonGroup}
      </FormBox>
    </>
  );
};

export default PreviewApplicationInfo;

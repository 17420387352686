import styled from 'styled-components';

const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1490px;
  margin: 0 auto;
  padding: 100px 20px 185px;
  min-height: 65vh;
`;

const Title = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-weight: 700;
  line-height: 70px;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 80px;
  text-align: center;
`;

const CardsList = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
`;

const CardWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: ${(p) => (p.isTablet ? '100%' : 'calc(50% - 50px)')};
  display: flex;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  align-items: ${(p) => (p.isMobile ? 'center' : 'flex-start')};
  text-align: ${(p) => (p.isMobile ? 'center' : 'left')};
  gap: 30px;
  .icon {
    width: 90px;
    height: 90px;
    flex-shrink: 0;
    background-color: ${(p) => p.theme.colors.primary500};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {

    .itemTitle {
      font-size: ${(p) => (p.isMobile ? p.theme.fontSizes.md : p.theme.fontSizes.lg)};
      font-weight: 700;
      margin-bottom: 20px;
    }

    .itemDesc {
      font-size: ${(p) => p.theme.fontSizes.base};
      color: ${(p) => p.theme.colors.gray400};
    }
  }
`;

export { CardsList, ContainerWrapper, Title, CardWrapper };

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCompanyService } from '@/api/type/companyCategory.api';
import { getCompanyCategory } from '@/api/type/companyProductService.api';

import { SearchBarInputsWrapper } from '@/components/Container/AuthContainer/styles';
import { H1 } from '@/components/UI/Typography/Heading';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, IconButton, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { FormSelect } from '@/components/UI/Select';
// import { category, product } from '@/data/select';
import { convertToSelectData } from '@/utils/convert';
import useCountryDetail from '@/hooks/useCountryDetail';

import { BannerWrapper, SearchBarWrapper, SearchIconWrapper, SearchButton, SearchInputWrapper } from './style';
import useResize from '@/hooks/useResize';
import { Flex } from '@/layout';

interface FormProps {
  acc_coname: string;
  acc_country: string;
  account: any;
  category: any;
  service: any;
}

const SearchBar = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { isTablet, isMobile } = useResize();
  const [open, setOpen] = useState(false);
  const { handleSubmit, control, setValue } = useForm<FormProps>();

  const AccountIdentification = t('pages.yellowPage.searchBar.options.accountIdentification', { returnObjects: true });

  const [serviceOptions, setServiceOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const serviceRes = (await getCompanyService()).data;
        const categoryRes = (await getCompanyCategory()).data;
        if (serviceRes.success) {
          setServiceOptions(serviceRes.data);
        }
        if (categoryRes.success) {
          setCategoryOptions(categoryRes.data);
        }
      } catch (error) {}
    };

    getOptions();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    const queryString = getSearchString(data);
    // openHandler();
    navigate(`/eDirectory?per_page=10&page=1${queryString}`);
  });

  const openHandler = () => {
    setOpen(!open);
  };

  // 點擊畫面中的按鈕會有自動收合的bug, 故拉出額外function呼叫sumbit
  const searchSubmit = () => {
    onSubmit();
  };

  const resetSearch = () => {
    setValue('acc_coname', '');
    setValue('acc_country', '');
    navigate(`/eDirectory?per_page=10&page=1`);
  };

  const getSearchString = (data: FormProps) => {
    const { acc_coname, acc_country, account, category, service } = data;

    let coname = '';
    let area = '';
    let identification = '';
    let companyCategory = '';
    let prodService = '';

    account.forEach((item: undefined, index: number) => {
      if (item === true) {
        identification += `${index} `;
      }
    });

    category.forEach((item: undefined, index: number) => {
      if (item === true) {
        companyCategory += `${index} `;
      }
    });

    service.forEach((item: undefined, index: number) => {
      if (item === true) {
        prodService += `${index} `;
      }
    });

    identification = identification?.replace(/ /g, ',').slice(0, -1);
    companyCategory = companyCategory?.replace(/ /g, ',').slice(0, -1);
    prodService = prodService?.replace(/ /g, ',').slice(0, -1);

    if (acc_coname) {
      coname = ' acc_coname=' + encodeURIComponent(acc_coname);
    }

    if (acc_country) {
      area = ' acc_country=' + encodeURIComponent(acc_country);
    }

    if (identification) {
      identification = ' identification=' + identification;
    }

    if (companyCategory) {
      companyCategory = ' category=' + companyCategory;
    }

    if (prodService) {
      prodService = ' prodService=' + prodService;
    }

    const query = coname + area + identification + companyCategory + prodService;

    return query.replace(/ /g, '&');
  };

  return (
    <BannerWrapper>
      <div className="mask">
        <H1 style={{ color: '#fff' }}>{t('eDirectory')}</H1>
        <SearchBarWrapper>
          <form onSubmit={onSubmit}>
            <Accordion expanded={open} defaultExpanded={false} style={{ maxWidth: 995, width: '95%', margin: 'auto' }}>
              <AccordionSummary aria-label="Expand" style={{ background: 'transparent' }}>
                <SearchBarInputsWrapper>
                  <SearchInputWrapper isTablet={isTablet} isMobile={isMobile}>
                    <div className="inputWrap">
                      <Controller
                        control={control}
                        defaultValue=""
                        name={'acc_coname'}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            placeholder={t('form.searchCompanyName.placeholder')}
                            variant="outlined"
                            value={value}
                            sx={{ width: '100%', background: '#FFF', borderRadius: '5px' }}
                            onChange={onChange}
                            size="small"
                            error={!!error}
                            helperText={error ? error.message : null}
                            autoComplete="off"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                searchSubmit();
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="inputWrap">
                      <FormSelect
                        control={control}
                        variant={'outlined'}
                        size={'small'}
                        data={useCountryDetail('name')}
                        inputLabel={t('form.searchLocation.label')}
                        selectLabel={t('form.searchLocation.label')}
                        name={'acc_country'}
                      />
                    </div>
                    <Flex gridGap={'5px'} style={{ width: isMobile ? '100%' : 'unset' }}>
                      <IconButton
                        aria-label="search"
                        style={{ padding: '0', margin: '0', width: isMobile ? '50%' : 'unset' }}
                        onClick={(e) => {
                          e.preventDefault();
                          searchSubmit();
                        }}
                      >
                        <SearchIconWrapper isMobile={isMobile}>
                          <img width={32} src="/images/yellowpage/search.svg" alt="" />
                        </SearchIconWrapper>
                      </IconButton>
                      <IconButton
                        aria-label="search"
                        style={{ padding: '0', margin: '0', width: isMobile ? '50%' : 'unset' }}
                        onClick={(e) => {
                          e.preventDefault();
                          resetSearch();
                        }}
                      >
                        <SearchIconWrapper isMobile={isMobile} className="reset">
                          <span>X</span>
                        </SearchIconWrapper>
                      </IconButton>
                    </Flex>
                  </SearchInputWrapper>

                  {/* <AdvancedButton
                  onClick={(e) => {
                    e.preventDefault();
                    openHandler();
                  }}
                  style={{ backgroundColor: `${open ? '#FF6D00' : '#3C5AB1'}`, minWidth: '200px' }}
                >
                  Advanced
                </AdvancedButton> */}
                </SearchBarInputsWrapper>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '19px',
                      color: '#212121',
                    }}
                  >
                    {t('accountIdentification')}
                  </div>

                  <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                    {AccountIdentification.length !== 0 &&
                      AccountIdentification.map((account) => (
                        <span key={account.name} style={{ display: 'inline-block', width: '33.3%' }}>
                          <Controller
                            control={control}
                            name={`account.${account.id}`}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Checkbox
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#7EB442',
                                  },
                                }}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                          <label htmlFor="">{account.name}</label>
                        </span>
                      ))}
                  </div>
                </Box>
                <hr style={{ borderTop: '1px solid #E9E9E9' }} />
                <Box>
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '19px',
                      color: '#212121',
                      marginTop: 15,
                    }}
                  >
                    {t('category')}
                  </div>

                  <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                    {categoryOptions.length !== 0 &&
                      categoryOptions.map((category: Option) => (
                        <span key={category.name} style={{ display: 'inline-block', width: '33.3%' }}>
                          <Controller
                            control={control}
                            name={`category.${category.id}`}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Checkbox
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#7EB442',
                                  },
                                }}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                          <label htmlFor="">{category.name}</label>
                        </span>
                      ))}
                  </div>
                </Box>
                <hr style={{ borderTop: '1px solid #E9E9E9' }} />
                <Box>
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '19px',
                      color: '#212121',
                      marginTop: 15,
                    }}
                  >
                    {t('productService')}
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                    {serviceOptions.length !== 0 &&
                      serviceOptions.map((service: Option) => (
                        <span key={service.name} style={{ display: 'inline-block', width: '33.3%' }}>
                          <Controller
                            control={control}
                            name={`service.${service.id}`}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Checkbox
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#7EB442',
                                  },
                                }}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                          <label htmlFor="">{service.name}</label>
                        </span>
                      ))}
                  </div>
                </Box>

                <SearchButton
                  style={{ backgroundColor: '#ADD747', cursor: 'pointer', marginTop: 45 }}
                  onClick={(e) => {
                    searchSubmit();
                  }}
                >
                  <img width={32} src="/images/yellowpage/search.svg" alt="" />
                  {t('buttons.search')}
                </SearchButton>
              </AccordionDetails>
            </Accordion>
          </form>
        </SearchBarWrapper>
      </div>
    </BannerWrapper>
  );
};

export default SearchBar;

import { useState, useEffect, useCallback } from 'react';
import PageContainer from '@/components/Container/PageContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useUserDetail from '@/hooks/useUserDetail';
import Introduction from '@/components/ETrust/ECert/Introduction';
import Information from '@/components/ETrust/ECert/Information';
import RegistrationForm from '@/components/ETrust/ECert/RegistrationForm';
import Stepline from '@/components/ETrust/Stepline';
import RegistrationStatus from '@/components/ETrust/ECert/RegistrationStatus';
import { useTranslation } from 'react-i18next';
import { LoadingPage } from '@/layout';
import { getHKCertApplys, getUserCertOrder } from '@/api/eCert.api';
import { NeedUpgradePlanModal, NotEnoughQuotaModal } from '@/components/DocLibrary/Modal';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import usePermissionCheck from '@/hooks/usePermissionCheck';
import { errorHandler } from '@/utils/toastHandler';

const ECert = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(searchParams.get('step'));
  // 此頁不會進行使用者資料的更新, 故activeStep填固定值避免更新
  const userDetail = useUserDetail({});
  const stepline = t('pages.eTrust.eCert.stepline', { returnObjects: true });
  const [status, setStatus] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const { userData } = useAppSelector(getAuthSelector);
  const [showNotEnoughQuotaModal, setShowNotEnoughQuota] = useState<boolean>(false);
  const [previousData, setPreviousData] = useState<ECertApplys>();
  const { isMainAccount } = usePermissionCheck();

  const checkStatus = useCallback(async () => {
    if (!searchParams.get('step')) return;
    setIsLoading(true);
    try {
      const res = (await getHKCertApplys()).data;
      if (res.success) {
        if (res.data.length !== 0) {
          setPreviousData(res.data[res.data.length - 1]);
          // 若長度非為0則找到最後一筆資料並確認是否狀態為"pending"
          // 若非"pending"則跳至第五步驟
          if (res.data[res.data.length - 1].status !== 'pending') {
            setCurrentStep('5');
            setActiveStep(4);
            setSearchParams({ step: '5' });
          }
          // 若為"pending"則跳至第三步驟
          else {
            setCurrentStep('3');
            setActiveStep(2);
            setSearchParams({ step: '3' });
          }

          // 需要考慮過期後的問題

          // 確定是否為正確檔案, 確認fileUrl是否為空字串
          // if (res.data[res.data.length - 1].fileUrl === '') return;
          // setStatus(Number(res.data[res.data.length - 1].status));
          // setCurrentStep('5');
          // setActiveStep(4);
          // setSearchParams({ step: '5' });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('step')) {
      setCurrentStep('1');
      navigate('/ecert?step=1');
      setActiveStep(0);
    }

    if (!currentStep || parseInt(currentStep) === 0) {
      setCurrentStep('1');
      navigate('/ecert?step=1');
      setActiveStep(0);
    } else {
      const paramsStep = parseInt(currentStep);
      setActiveStep(paramsStep - 1);
    }
    checkStatus();
  }, [checkStatus, searchParams.get('step')]);

  const checkLevel = () => {
    // if (!userData) return false;
    // if (userData.plan.level === null) {
    //   setShowUpgradeModal(true);
    //   navigate('/ecert?step=1', { replace: true });
    //   setCurrentStep('1');
    //   setActiveStep(0);
    //   return false;
    // }
    if (!isMainAccount) {
      setCurrentStep('1');
      navigate('/ecert?step=1');
      setActiveStep(0);
      errorHandler(t('error.permission'));
    }
    return isMainAccount;
  };

  useEffect(() => {}, [t]);

  return (
    <PageContainer title={t('pages.eTrust.eCert.title')}>
      <LoadingPage isLoading={isLoading} />
      <NeedUpgradePlanModal showModal={showUpgradeModal} setShowModal={setShowUpgradeModal} />
      <NotEnoughQuotaModal showModal={showNotEnoughQuotaModal} setShowModal={setShowNotEnoughQuota} />
      <div style={{ display: 'flex' }}>
        <Stepline activeStep={activeStep} stepline={stepline} type={'eCert'} />
        {activeStep === 0 && (
          <Introduction checkLevel={checkLevel} setActiveStep={setActiveStep} setCurrentStep={setCurrentStep} />
        )}
        {activeStep === 1 && (
          <Information
            checkLevel={checkLevel}
            setStatus={setStatus}
            setActiveStep={setActiveStep}
            setCurrentStep={setCurrentStep}
          />
        )}
        {activeStep === 2 && (
          <RegistrationForm
            checkLevel={checkLevel}
            setStatus={setStatus}
            setActiveStep={setActiveStep}
            setCurrentStep={setCurrentStep}
            previousData={previousData}
          />
        )}
        {activeStep === 4 && (
          <RegistrationStatus status={status} setActiveStep={setActiveStep} setCurrentStep={setCurrentStep} />
        )}
      </div>
    </PageContainer>
  );
};

export default ECert;

import { useState, useEffect } from 'react';

import { country } from '@/data/select';
import useCurrentLang from './useCurrentLang';

const useCountryDetail = (type: string) => {
  const lang = useCurrentLang();
  const [data, setData] = useState<{ value: string | number; text: string | number }[]>([]);

  const sortAry = (ary: { value: string | number; text: string | number }[]) => {
    return ary;
  };

  useEffect(() => {
    const currentLang = lang === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc';
    const countryDetail = country[currentLang];
    const valueDetail = country['en'];
    const dataAry: { value: string | number; text: string | number }[] = [];

    if (type === 'name') {
      countryDetail.forEach((item: CountryPorps, i: number) => {
        dataAry.push({
          value: valueDetail[i].name,
          text: item.name,
        });
      });

      setData(sortAry(dataAry));
      return;
    }

    if (type === 'countryCode') {
      countryDetail.forEach((item: CountryPorps, i: number) => {
        dataAry.push({
          value: `${valueDetail[i].countryCode} (${valueDetail[i].name})`,
          text: `${item.countryCode} (${item.name})`,
        });
      });
      setData(sortAry(dataAry));
      return;
    }

    if (type === 'timezone') {
      const duplicateAry: string[] = [];
      countryDetail.forEach((item: CountryPorps, i: number) => {
        // 若時區重複，則不加入
        if (duplicateAry.includes(valueDetail[i].timezone)) {
          return;
        }
        duplicateAry.push(valueDetail[i].timezone);
        dataAry.push({
          value: valueDetail[i].timezone,
          text: item.timezone,
        });
      });
      setData(sortAry(dataAry));
      return;
    }

    if (type === 'languages') {
      const duplicateAry: string[] = [];
      countryDetail.forEach((item: CountryPorps, i: number) => {
        // 若語言重複，則不加入
        if (duplicateAry.includes(valueDetail[i].language)) {
          return;
        }
        duplicateAry.push(valueDetail[i].language);
        dataAry.push({
          value: valueDetail[i].language,
          text: item.language,
        });
      });
      setData([...sortAry(dataAry), { value: 'Other', text: 'Other' }]);
      return;
    }

    if (type === 'currency') {
      const duplicateAry: string[] = [];
      countryDetail.forEach((item: CountryPorps, i: number) => {
        // 若貨幣重複，則不加入
        if (duplicateAry.includes(valueDetail[i].currency)) {
          return;
        }
        duplicateAry.push(valueDetail[i].currency);
        dataAry.push({
          value: valueDetail[i].currency,
          text: item.currency,
        });
      });
      setData([...sortAry(dataAry), { value: 'Other', text: 'Other' }]);
      return;
    }
  }, [lang]);

  return data;
};

export default useCountryDetail;

import React, { useEffect, useState, useMemo } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { Flex } from '@/layout/Flex';
import GlobalFilterInput from './GlobalFilterInput';
import SelectColumn from './SelectColumn';
import TypeColumn from './TypeColumn';
import Tag from './Tag';
import FileMenu from './FileMenu';

import { useAppDispatch } from '@/redux/store.hook';
import { setSelectedId } from '@/redux/dataTable/dataTable.slice';

import { StyledTable, Pagination } from './styles';

interface DataTableProps<T extends {}> {
  columns: ColumnDef<T>[];
  data: T[];
  reduxKey?: string;
  initSelected?: {
    [x: string]: boolean;
  };
  canSelected?: boolean;
  canSearch?: boolean;
  setPerPage?: React.Dispatch<React.SetStateAction<number>>;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  perPage?: number;
  total?: number;
}

const DataTable = <T extends {}>({
  columns,
  data,
  reduxKey,
  initSelected = {},
  canSelected = false,
  canSearch = false,
  setPerPage = () => 10,
  setPage = () => 1,
  total = undefined,
  page = 1,
  perPage = 10,
}: DataTableProps<T & { id?: string }>) => {
  // @ts-ignore
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [rowSelection, setRowSelection] = useState({ ...initSelected });
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    autoResetAll: false,
    data,
    columns,
    initialState: {
      rowSelection,
    },
    state: {
      rowSelection,
      globalFilter,
      sorting,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
  });

  const selectedRowData = useMemo(() => table.getSelectedRowModel().flatRows, [table.getSelectedRowModel()]);

  useEffect(() => {
    if (!reduxKey) return;
    const selectedRowIds = selectedRowData.map((row) => row.id);
    const selectedDataIds = selectedRowData.map((row) => row.original.id as string);
    table.setPageSize(perPage);
    dispatch(setSelectedId({ selectedDataIds, selectedRowIds, key: reduxKey }));
  }, [selectedRowData]);

  return (
    <StyledTable>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <div>
          {canSelected && (
            <>
              {t('buttons.chosen')}：{Object.keys(rowSelection).length}
            </>
          )}
        </div>
        {canSearch && (
          <GlobalFilterInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            placeholder={t('info.searchAllColumns')}
          />
        )}
      </Flex>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                        className: header.column.getCanSort() ? 'pointer' : '',
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                      )}
                      {header.column.getCanSort() && (
                        <span className="sort-icon">
                          {{
                            asc: <div className="asc" />,
                            desc: <div className="desc" />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </span>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} className={`${row.getIsSelected() ? 'selected' : ''}`}>
                {row.getVisibleCells().map((cell) => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination>
        <span className="total">
          {table.getFilteredRowModel().rows.length} {t('info.entriesInTotal')}
        </span>
        <div className="pages">
          <label htmlFor="table-pagination" className="display">
            {t('info.display')}
            <select
              id="table-pagination"
              value={perPage}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
                setPerPage(Number(e.target.value));
                setPage(1);
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </label>
          {total !== undefined ? (
            <div className="change-page">
              <button className="border rounded p-1" onClick={() => setPage(1)} disabled={page <= 1}>
                {'<<'}
              </button>
              <button
                className="border rounded p-1"
                onClick={() => {
                  setPage(page - 1);
                }}
                disabled={page <= 1}
                type="button"
              >
                {'<'}
              </button>

              <button
                className="border rounded p-1"
                onClick={() => {
                  setPage(page + 1);
                }}
                disabled={page + 1 * perPage > total}
                type="button"
              >
                {'>'}
              </button>
              <button
                className="border rounded p-1"
                onClick={() => setPage(total % perPage === 0 ? total / perPage : Math.floor(total / perPage) + 1)}
                disabled={page + 1 * perPage > total}
              >
                {'>>'}
              </button>
              <span>
                <div>{t('info.page')}</div>
                <strong>
                  {page} of {total % perPage === 0 ? total / perPage : Math.floor(total / perPage) + 1}
                </strong>
              </span>
              <span>
                | {t('info.goToPage')}:
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  min={1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 1;
                    setPage(page + 1);
                  }}
                  className="border p-1 rounded w-16"
                />
              </span>
            </div>
          ) : (
            <div className="change-page">
              <button
                className="border rounded p-1"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {'<<'}
              </button>
              <button
                className="border rounded p-1"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                type="button"
              >
                {'<'}
              </button>

              <button
                className="border rounded p-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                type="button"
              >
                {'>'}
              </button>
              <button
                className="border rounded p-1"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {'>>'}
              </button>
              <span>
                <div>{t('info.page')}</div>
                <strong>
                  {table.getState().pagination.pageIndex + 1} of {table.getPageCount() === 0 ? 1 : table.getPageCount()}
                </strong>
              </span>
              <span>
                | {t('info.goToPage')}:
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  min={1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 1;
                    table.setPageIndex(page);
                  }}
                  className="border p-1 rounded w-16"
                />
              </span>
            </div>
          )}
        </div>
      </Pagination>
      <br />
      {/* <div>
        <button className="border rounded p-2 mb-2" onClick={() => console.info('rowSelection', rowSelection)}>
          Log `rowSelection` state
        </button>
      </div>
      <div>
        <button
          className="border rounded p-2 mb-2"
          onClick={() => console.info('table.getSelectedFlatRows()', table.getSelectedRowModel().flatRows)}
        >
          Log table.getSelectedFlatRows()
        </button>
      </div> */}
    </StyledTable>
  );
};

export default DataTable;
export { SelectColumn, TypeColumn, Tag, FileMenu };

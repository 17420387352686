import PageContainer from '@/components/Container/PageContainer';
import { useTranslation } from 'react-i18next';
import { Box, Button as DefaultBtn } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IdentificationCheck from '@/components/Account/IdentificationCheck';

const IdentificationEdit = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();

  const ButtonGroup = (
    <Box style={{ marginTop: 30 }}>
      <DefaultBtn type='button' color="primary" onClick={() => navigate('/profile/edit')} style={{ fontSize: 20, color: '#0B319D', padding: '1px 40px', border: '1px solid #0B319D', marginLeft: 15 }}>
        {t('buttons.back')}
      </DefaultBtn>
    </Box>
  )

  return (
    <PageContainer title={t('identificationCheck')}>
      <div style={{ display: 'flex' }}>
        <IdentificationCheck ButtonGroup={ButtonGroup} />
      </div>
    </PageContainer>
  );
};

export default IdentificationEdit;

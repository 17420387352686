import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Paper, FormHelperText } from '@mui/material';
import { successHandler, errorHandler } from '@/utils/toastHandler';

import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { InputFileWrapper, InputFileLabel } from '@/components/UI/Input/InputFile/styles';
import { CloudUpload } from '@/components/Icon';
import { ErrorMessage, Text } from '@/components/UI/Typography/Text';
import { LoadingPage } from '@/layout';

import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import { registrationPRC } from '@/api/auth.api';

import { theme } from '@/theme/Theme';

const CreditReport = ({ nextHandler = () => {}, ButtonGroup, ReturnLink = '', userDetail }: ProfileProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { inputRegister } = useAuthValidation();

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
    setError,
  } = useForm<{
    credit_report: FileList;
    TC_DataShare: boolean;
  }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = handleSubmit(async (data) => {
    if (!data.TC_DataShare) {
      setError('TC_DataShare', { type: 'custom', message: t('error.checkBox') });
      return;
    }
    let postData = {};
    for (const [key, value] of Object.entries(data)) {
      postData = { ...postData, [key]: typeof value === 'boolean' ? (value ? '1' : '0') : value[0] };
    }
    try {
      setIsLoading(true);
      const res = (await registrationPRC(postData as RegistrationPRCRequest)).data;
      if (res.success) {
        nextHandler();
        successHandler(t('success.upload'));
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error.response.data.data);
    }
  });

  return (
    <Paper square elevation={0}>
      <LoadingPage isLoading={isLoading} />
      <form onSubmit={onSubmit} style={{ maxWidth: '930px', padding: '30px 50px' }}>
        <InputWrapper>
          <p>{t('creditReportUpload')}</p>
        </InputWrapper>
        <InputWrapper>
          <InputFileWrapper>
            <InputFileLabel>
              <Text color={theme.colors.gray400}>{t('creditReport')}</Text>
              <Text margin={'0 17px 0 20px'} color={theme.colors.gray200}>
                {userDetail && userDetail.credit_report && !watch('credit_report')?.[0]
                  ? userDetail.credit_report.name
                  : watch('credit_report')?.[0]
                  ? watch('credit_report')[0].name
                  : t('info.chooseFile')}
              </Text>
              <input
                hidden
                accept=".pdf"
                type="file"
                {...register(
                  'credit_report',
                  userDetail && userDetail.credit_report ? { required: false } : { ...inputRegister }
                )}
              />
              <ErrorMessage>{errors.credit_report?.message}</ErrorMessage>
              <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
            </InputFileLabel>
          </InputFileWrapper>
        </InputWrapper>
        <Controller
          control={control}
          name={'TC_DataShare'}
          render={({ field, fieldState: { error } }) => (
            <>
              <FormControlLabel
                sx={{ marginTop: '350px' }}
                control={<Checkbox {...field} />}
                label={t('form.terms.label.TCDataShare')}
              />
              <FormHelperText error>{error?.message}</FormHelperText>
            </>
          )}
        />
        {ButtonGroup}
      </form>
    </Paper>
  );
};

export default CreditReport;

import styled from 'styled-components';

const ContainerWrapper = styled.div`
  width: 100%;
  background: linear-gradient(90deg, #0f319d 0%, #4ebbc6 100%);
`;

const FooterWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  padding: ${(p) => (p.isMobile ? '40px 15px 10px' : p.isTablet ? '40px 0 30px' : '60px 0 30px')};
  width: 100%;
  max-width: 1820px;
  margin: 0 auto;
  display: flex;
  flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
  justify-content: ${(p) => (p.isTablet ? 'center' : 'space-between')};
  align-items: ${(p) => (p.isTablet ? 'center' : 'flex-start')};
  gap: ${(p) => (p.isTablet ? '30px' : '0px')};
`;

const Logo = styled.img`
  width: 356px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 40px;
`;

const Navbar = styled.div<{isMobile: boolean}>`
  flex: 1;
  display: flex;
  gap: 60px;
  align-items: center;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};

  @media (max-width: 1500px) {
    gap: 30px;
  }
`;

const Anchor = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.md};
  line-height: 23px;
  color: ${(p) => p.theme.colors.light};
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
`;

const MediaList = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const MediaLink = styled.a`
  display: flex;
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
`;

const CopyRight = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 400;
  line-height: 18.75px;
  color: ${(p) => p.theme.colors.light};
  margin-top: auto;
`;

const SearchIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: ${(p) => p.theme.colors.secondary500};
  color: ${(p) => p.theme.colors.light};
  border-radius: 0 5px 5px 0;
  display: flex;

  > img {
    margin: auto;
  }
`;

const SearchBar = styled.form`
  display: flex;
  width: 100%;
`;

export {
  ContainerWrapper,
  FooterWrapper,
  Logo,
  Navbar,
  Anchor,
  MediaList,
  MediaLink,
  CopyRight,
  SearchIconWrapper,
  SearchBar,
};
